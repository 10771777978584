import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  FeatureConfigVariant,
  FeatureConfigVariantSchema,
} from "@bucketco/shared/featureConfigAPI";
import { JSON5Schema } from "@bucketco/shared/schemas/json5";

import { JSONEditor } from "@/common/components/CodeEditor/JSONEditor";
import FormInput from "@/common/components/Form/FormInput";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmit from "@/common/components/Form/FormSubmit";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";

type Props = {
  variant?: FeatureConfigVariant;
  existingKeys?: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FeatureConfigVariant) => void;
};

export const FeatureConfigModal = ({
  variant,
  existingKeys,
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  const extendedSchema = useMemo(
    () =>
      FeatureConfigVariantSchema.extend({
        payload: JSON5Schema(
          'Payload must be valid JSON: "string", number, boolean, null, object, or array.',
        ),
      }).refine(({ key }) => !existingKeys || !existingKeys.includes(key), {
        message: "Key must be unique",
        path: ["key"],
      }),
    [existingKeys],
  );

  const form = useForm<FeatureConfigVariant>({
    resolver: zodResolver(extendedSchema),
    defaultValues: {
      key: variant?.key || "",
      payload: variant?.payload,
    },
  });

  const handleSubmit = form.handleSubmit((data: FeatureConfigVariant) => {
    onSubmit(data);
  });

  return (
    <FormProvider {...form}>
      <Modal
        isOpen={isOpen}
        size="4xl"
        onClose={onClose}
        onCloseComplete={form.reset}
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit}>
          <ModalHeader>{variant ? "Edit" : "Create"} config value</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <FormInput label="Key" name="key" />
            <ManagedFormControl
              description="Can be any valid JSON value like object, array, string, number, boolean and null."
              name="payload"
              render={({ field }) => (
                <JSONEditor
                  initialValue={field.value}
                  label="Payload (optional)"
                  onChange={field.onChange}
                />
              )}
            />
            <FormRootError />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} variant="ghostDimmed" onClick={onClose}>
              Cancel
            </Button>
            <FormSubmit>{variant ? "Save" : "Add"}</FormSubmit>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
