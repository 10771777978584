import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { APIResponse, ErrorResponse } from "@bucketco/shared/api";
import { UpdateFeatureTargeting } from "@bucketco/shared/featureFlagAPI";
import { FlagVersion } from "@bucketco/shared/flagAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

export function useUpdateCompanyFeatureAccessMutation(companyId: string) {
  const { appId, envId } = useCurrentEnv();
  const queryClient = useQueryClient();

  return useMutation<
    APIResponse<{ flagVersions: FlagVersion[] }>,
    AxiosError<ErrorResponse>,
    UpdateFeatureTargeting
  >({
    mutationFn: (variables) => {
      if (!appId || !companyId || !envId) {
        throw new Error("Missing required parameters");
      }

      return api
        .patch<"/apps/:appId/features/targeting">(
          `/apps/${appId}/features/targeting`,
          variables,
          {
            params: {
              envId,
            },
          },
        )
        .then((response) => response.data);
    },

    onSuccess: () => {
      if (appId && companyId && envId) {
        // Invalidate company features
        queryClient.invalidateQueries({
          queryKey: companyQueryKeys.features(appId, envId, companyId),
        });
      }
    },
  });
}
