import React from "react";
import { useWatch } from "react-hook-form";
import { RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import {
  Badge,
  Button,
  Card,
  Flex,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";

import {
  FeatureConfigVariantWithEnvironmentRules,
  UpdateFeatureConfig,
} from "@bucketco/shared/featureConfigAPI";
import { isEmpty } from "@bucketco/shared/utils/types";

import CardContainer from "@/common/components/CardContainer";
import { RuleSimpleForm } from "@/common/components/Rule/RuleSimpleForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";

type FeatureConfigProps = {
  baseName: `variants.${number}`;
  selectedUsers: Set<string>;
  selectedCompanies: Set<string>;
  selectedSegments: Set<string>;
  isOnlyConfig: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onSetDefault: () => void;
};

export const FeatureConfig = ({
  baseName,
  selectedUsers,
  selectedCompanies,
  selectedSegments,
  isOnlyConfig,
  onEdit,
  onDelete,
  onSetDefault,
}: FeatureConfigProps) => {
  const { envId } = useCurrentEnv();
  const {
    variant,
    environmentRules,
  }: FeatureConfigVariantWithEnvironmentRules = useWatch<UpdateFeatureConfig>({
    name: baseName,
  });

  if (!variant) return null;

  const isDefault = environmentRules[envId!]?.default;
  return (
    <CardContainer gap={1} pb={isDefault ? 2 : 1}>
      <Flex align="center" gap={4} justify="space-between" px={2}>
        <HStack overflow="hidden" spacing={2}>
          <HStack align="baseline" as="p" overflow="hidden" spacing={2}>
            <Text
              as="span"
              fontFamily="code"
              fontWeight="medium"
              whiteSpace="nowrap"
            >
              {variant.key}
            </Text>
            {!isEmpty(variant.payload) && (
              <Text
                as="span"
                color="dimmed"
                fontFamily="code"
                fontSize="xs"
                isTruncated
              >
                {JSON.stringify(variant.payload, null, 2)}
              </Text>
            )}
          </HStack>
          <IconButton
            aria-label="Edit config"
            color="dimmed"
            icon={<RiPencilLine size={16} />}
            size="xs"
            variant="ghost"
            onClick={onEdit}
          />
        </HStack>
        <HStack spacing={2}>
          {(!isDefault || isOnlyConfig) && (
            <IconButton
              aria-label="Delete config"
              color="dimmed"
              icon={<RiDeleteBinLine size={16} />}
              size="xs"
              variant="ghost"
              onClick={onDelete}
            />
          )}
        </HStack>
      </Flex>

      <Card p={4} w="full">
        {isDefault ? (
          <HStack justify="space-between">
            <Text color="dimmed" fontSize="md">
              This is the default value for this environment that will be served
              when no other rules match.
            </Text>
            <Badge borderRadius="full" colorScheme="brand">
              Default
            </Badge>
          </HStack>
        ) : (
          <RuleSimpleForm
            baseName={`${baseName}.environmentRules.${envId}`}
            selectedCompanies={selectedCompanies}
            selectedSegments={selectedSegments}
            selectedUsers={selectedUsers}
            toolbar={
              <Button size="xs" variant="outline" onClick={onSetDefault}>
                Set default
              </Button>
            }
          />
        )}
      </Card>
    </CardContainer>
  );
};
