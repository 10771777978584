import { Center, Flex, Text } from "@chakra-ui/react";

export function LoginSuccess() {
  return (
    <Center flexDirection="column" minHeight="100vh" padding="4" width="100%">
      <Flex align="center" direction="column" gap={6}>
        <Text
          fontSize="md"
          maxW="2xs"
          sx={{ textWrap: "balance" }}
          textAlign="center"
        >
          Login successful.
          <br /> You can close this window.
        </Text>
      </Flex>
    </Center>
  );
}

export function LoginError() {
  return (
    <Center flexDirection="column" minHeight="100vh" padding="4" width="100%">
      <Flex align="center" direction="column" gap={6}>
        <Text
          color={"red.500"}
          fontSize="md"
          maxW="2xs"
          sx={{ textWrap: "balance" }}
          textAlign="center"
        >
          Log failed.
          <br /> Please restart the process from the command line.
        </Text>
      </Flex>
    </Center>
  );
}
