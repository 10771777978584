import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useGithubConnection() {
  const { currentOrg } = useAuthContext();

  return useQuery({
    queryKey: integrationQueryKeys.githubConnection(currentOrg?.id),
    queryFn: () =>
      api
        .get<"/orgs/:orgId/github/connection">(
          `/orgs/${currentOrg?.id}/github/connection`,
        )
        .then((res) => res.data),
    enabled: Boolean(currentOrg),
  });
}
