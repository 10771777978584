import { ReactNode } from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";

import {
  operatorDisplayName,
  relevantOperators,
} from "@bucketco/shared/attributeFilter";
import { SegmentFilter } from "@bucketco/shared/filter";
import { SegmentWithCountsDTO } from "@bucketco/shared/segmentAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import { FormSegmentPicker } from "@/common/components/Form/FormSegmentPicker";
import { SegmentSupport } from "@/common/components/SegmentPicker";
import { TextWordBreak } from "@/common/components/TextWordBreak";
import { useSegmentsWithCounts } from "@/company/data/useSegments";
import { ManagedFormControl } from "../Form/ManagedFormControl";
import RuleOperator from "../Rule/RuleOperator";

type SegmentFilterItemProps = {
  filter: SegmentFilter;
  showIcon?: boolean;
  isLoading?: boolean;
  compact?: boolean;
};

export function SegmentFilterItem({
  filter,
  showIcon,
  isLoading,
  compact,
}: SegmentFilterItemProps) {
  const { data: segments } = useSegmentsWithCounts({ includeDeleted: true });

  const segment = segments?.find(({ id }) => id === filter.segmentId);
  return (
    <Flex align="center" display="inline-flex" whiteSpace="normal">
      {isLoading ? (
        <Flex align="center" as="span" color="dimmed" columnGap={2}>
          <Spinner size="xs" />
          <TextWordBreak as="span">Loading filter...</TextWordBreak>
        </Flex>
      ) : (
        <Flex align="center" as="span" columnGap={1.5} wrap="wrap">
          {!compact ? (
            <Text as="span" color="dimmed">
              {operatorDisplayName[filter.operator]}
            </Text>
          ) : compact && filter.operator === "NOT_SEGMENT" ? (
            <Text as="span" color="dimmed">
              not in
            </Text>
          ) : null}
          {segment && showIcon && (
            <SimplePieChart
              as="span"
              value={getFraction(segment.segmentCount, segment.allCount) * 100}
            />
          )}
          {segment && <Text as="span">{segment.name}</Text>}
          {segment?.deletedAt !== null && (
            <Text color="dimmed">(archived)</Text>
          )}
        </Flex>
      )}
    </Flex>
  );
}

type SegmentFilterFieldsProps = {
  segmentFilterFn?: (segment: SegmentWithCountsDTO) => SegmentSupport;
  segmentUnsupportedReason?: ReactNode;
};

export function SegmentFilterFields({
  segmentFilterFn,
  segmentUnsupportedReason,
}: SegmentFilterFieldsProps) {
  const operators = relevantOperators(["segment"]);

  return (
    <>
      <ManagedFormControl
        name="operator"
        render={({ field }) => (
          <RuleOperator {...field} operators={operators} />
        )}
      />
      <FormSegmentPicker
        bg="appBackground"
        filterFn={segmentFilterFn}
        menuDescription="Pick a segment:"
        name="segmentId"
        showPieChart={true}
        unsupportedReason={segmentUnsupportedReason}
        w={"100%"}
      />
    </>
  );
}
