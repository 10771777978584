import { FormProvider, UseFormReturn } from "react-hook-form";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  useUpdateEffect,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  CreateFeedbackArgsSchema,
  FeedbackDTO,
} from "@bucketco/shared/feedbackAPI";

import { CompanyAutocompleteSelect } from "@/common/components/Autocomplete/CompanyAutocompleteSelect";
import { CompanyFeatureAutocompleteSelect } from "@/common/components/Autocomplete/CompanyFeatureAutocompleteSelect";
import { UserAutocompleteSelect } from "@/common/components/Autocomplete/UserAutocompleteSelect";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmit from "@/common/components/Form/FormSubmit";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FormSatisfactionInput } from "@/feedback/components/FeedbackSatisfaction";
import { feedbackQueryKeys } from "@/feedback/data/feedbackQueryKeys";

type Props = {
  handleSubmit: () => void;
  form: UseFormReturn<FeedbackFormType>;
  submitLabel?: "Add" | "Update";
  showCompanyPicker?: boolean;
  showFeaturePicker?: boolean;
  includedCompanies?: { id: string; name: string }[];
};

function FeedbackForm({
  submitLabel = "Add",
  handleSubmit,
  form,
  showCompanyPicker = true,
  showFeaturePicker = true,
}: Props) {
  const navigate = useNavigate();

  const companyId = form.watch("companyId");

  return (
    <Modal
      size="md"
      isOpen
      onClose={() => {
        form.reset();
        closeModal(navigate);
      }}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <FormProvider {...form}>
            <ModalHeader>
              <HStack spacing={3}>
                <Heading size="lg">{submitLabel} feedback</Heading>
              </HStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDirection="column" gap={6}>
                {showCompanyPicker && (
                  <ManagedFormControl
                    isDisabled={form.formState.isSubmitting}
                    label="Company"
                    name="companyId"
                    render={({ field }) => {
                      return <CompanyAutocompleteSelect {...field} valueAsId />;
                    }}
                  />
                )}

                {showFeaturePicker && (
                  <ManagedFormControl
                    isDisabled={form.formState.isSubmitting || !companyId}
                    label="Feature"
                    name="featureId"
                    render={({ field }) => {
                      return (
                        <CompanyFeatureAutocompleteSelect
                          {...field}
                          companyId={companyId}
                          valueAsId
                        />
                      );
                    }}
                  />
                )}

                <ManagedFormControl
                  isDisabled={form.formState.isSubmitting || !companyId}
                  label="User"
                  name="userId"
                  render={({ field }) => (
                    <UserAutocompleteSelect
                      {...field}
                      query={{ companyId }}
                      canClear
                      valueAsId
                    />
                  )}
                />

                <ManagedFormControl
                  isDisabled={form.formState.isSubmitting}
                  label="Score"
                  name="score"
                  render={({ field }) => (
                    <FormSatisfactionInput
                      {...field}
                      value={String(field.value)}
                      onChange={(value) => {
                        field.onChange(value ? Number(value) : null);
                      }}
                    />
                  )}
                />

                <ManagedFormControl
                  isDisabled={form.formState.isSubmitting}
                  label="Comment (optional)"
                  name="comment"
                  render={({ field }) => <Textarea size="sm" {...field} />}
                />

                {submitLabel === "Add" && (
                  <ManagedFormControl
                    isDisabled={form.formState.isSubmitting}
                    name="sendNotification"
                    render={({ field }) => (
                      <Checkbox {...field}>Send to Slack</Checkbox>
                    )}
                  />
                )}

                <FormRootError />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <ModalCancelButton />
                <FormSubmit>{submitLabel}</FormSubmit>
              </ButtonGroup>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </form>
    </Modal>
  );
}

const feedbackFormSchema = CreateFeedbackArgsSchema.merge(
  z.object({
    featureId: z.string().min(1, "A feature is required"),
  }),
).refine(({ score, comment }) => Boolean(score || comment), {
  path: ["comment"],
  message: "Either a score or a comment must be provided",
});

type FeedbackFormType = z.input<typeof feedbackFormSchema>;

function EditFeedbackForm({ feedback }: { feedback: FeedbackDTO }) {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [queryFeatureId] = useSearchParam("featureId");
  const { featureId: paramsFeatureId, companyId, feedbackId } = useParams();

  const { envId, appId } = useCurrentEnv();

  const featureId = paramsFeatureId || queryFeatureId;

  const { form, handleSubmit } = useApiForm<FeedbackFormType, FeedbackDTO>(
    ({
      companyId,
      userId,
      featureId,
      score,
      comment,
      sendNotification,
    }: FeedbackFormType) =>
      api
        .patch<"/apps/:appId/feedback/:feedbackId">(
          `/apps/${appId}/feedback/${feedbackId}`,
          {
            companyId,
            userId: userId || null,
            featureId,
            score: score || null,
            comment: comment || null,
            sendNotification,
          },
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    feedbackFormSchema,
    {
      onSuccess: async () => {
        segmentAnalytics.track("Feedback Updated");
        await queryClient.invalidateQueries({
          queryKey: feedbackQueryKeys.list(appId, envId),
        });
        closeModal(navigate);
      },
    },
    {
      mode: "onChange",
      defaultValues: {
        companyId: feedback.companyId ?? undefined,
        userId: feedback.userId ?? null,
        featureId: feedback.featureId ?? undefined,
        score: feedback.score ?? null,
        comment: feedback.comment ?? "",
        sendNotification: false,
      },
    },
  );

  const score = form.watch("score");
  const comment = form.watch("comment");

  useUpdateEffect(() => {
    form.trigger("score");
    form.trigger("comment");
  }, [form, score, comment]);

  return (
    <FeedbackForm
      form={form}
      handleSubmit={handleSubmit}
      showCompanyPicker={!companyId}
      showFeaturePicker={!featureId}
      submitLabel="Update"
    />
  );
}

export function EditFeedback() {
  const { feedbackId } = useParams();

  const { envId, appId } = useCurrentEnv();

  const feedbackQuery = useQuery({
    queryKey: feedbackQueryKeys.single(appId, envId, feedbackId),

    queryFn: () =>
      api
        .get<`/apps/:appId/feedback/:feedbackId`>(
          `/apps/${appId}/feedback/${feedbackId}`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    enabled: !!envId && !!envId && !!feedbackId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  if (feedbackQuery.isSuccess) {
    return <EditFeedbackForm feedback={feedbackQuery.data} />;
  }

  if (feedbackQuery.isLoading) {
    return null;
  }

  if (feedbackQuery.isError) {
    // TODO
  }
}

export function NewFeedback() {
  const navigate = useNavigate();

  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();

  const { featureId, companyId } = useParams();
  const [queryFeatureId] = useSearchParam("featureId");

  const { envId, appId } = useCurrentEnv();

  const { form, handleSubmit } = useApiForm<FeedbackFormType, FeedbackDTO>(
    ({
      companyId,
      userId,
      featureId,
      score,
      comment,
      sendNotification,
    }: FeedbackFormType) =>
      api
        .post<"/apps/:appId/feedback">(
          `/apps/${appId}/feedback`,
          {
            featureId,
            userId: userId ?? null,
            companyId,
            score: score ?? null,
            comment: comment ?? null,
            sendNotification: sendNotification ?? false,
          },
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    feedbackFormSchema,
    {
      onSuccess: async () => {
        segmentAnalytics.track("Feedback Created");
        await queryClient.invalidateQueries({
          queryKey: feedbackQueryKeys.list(appId, envId),
        });
        closeModal(navigate);
        toast({
          title: "Feedback sent!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: () => {
        errorToast({
          description: "Couldn't send feedback",
        });
      },
    },
    {
      mode: "onChange",
      defaultValues: {
        companyId: companyId ?? undefined,
        userId: null,
        featureId: featureId ?? queryFeatureId ?? undefined,
        score: null,
        comment: "",
        sendNotification: false,
      },
    },
  );

  const score = form.watch("score");
  const comment = form.watch("comment");

  useUpdateEffect(() => {
    form.trigger("score");
    form.trigger("comment");
  }, [form, score, comment]);

  return (
    <FeedbackForm
      form={form}
      handleSubmit={handleSubmit}
      showCompanyPicker={!companyId}
      showFeaturePicker={!featureId}
      submitLabel="Add"
    />
  );
}

function closeModal(navigate: NavigateFunction) {
  // Clear the featureId search param when closing
  const search = new URLSearchParams(window.location.search);
  search.delete("featureId");

  navigate({
    pathname: "..",
    search: search.toString(),
  });
}
