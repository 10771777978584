/**
 * Calculates the Levenshtein distance between two strings.
 * @param str1 First string to compare
 * @param str2 Second string to compare.
 * @returns The Levenshtein distance (lower means more similar).
 */
export function levenshteinDistance(str1: string, str2: string): number {
  const s1 = str1.toLowerCase();
  const s2 = str2.toLowerCase();

  const dp: number[][] = Array(s1.length + 1)
    .fill(null)
    .map(() => Array(s2.length + 1).fill(0));

  for (let i = 0; i <= s1.length; i++) {
    dp[i][0] = i;
  }
  for (let j = 0; j <= s2.length; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= s1.length; i++) {
    for (let j = 1; j <= s2.length; j++) {
      const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1, // deletion
        dp[i][j - 1] + 1, // insertion
        dp[i - 1][j - 1] + cost, // substitution
      );
    }
  }

  return dp[s1.length][s2.length];
}

/**
 * Calculates a similarity score between two strings based on Levenshtein distance.
 * @param str1 First string to compare.
 * @param str2 Second string to compare.
 * @returns A similarity score between 0 and 1 (1 means identical)
 */
export function calculateSimilarityScore(str1: string, str2: string): number {
  if (!str1 && !str2) return 1; // Both empty strings are identical
  if (!str1 || !str2) return 0; // One empty string has no similarity

  const distance = levenshteinDistance(str1, str2);
  const maxLength = Math.max(str1.length, str2.length);

  // Normalize the score between 0 and 1
  // 1 means identical, 0 means completely different
  return 1 - distance / maxLength;
}

/**
 * Checks if a string contains a substring and calculates a relevance score.
 * @param text The text to search in.
 * @param query The query to search for.
 * @returns A relevance score, higher means more relevant.
 */
export function calculateRelevanceScore(text: string, query: string): number {
  if (!query) return 0.5; // Neutral score for empty query

  const textLower = text.toLowerCase();
  const queryLower = query.toLowerCase();

  if (textLower.includes(queryLower)) {
    const precisionBonus =
      1 - (textLower.length - queryLower.length) / textLower.length;
    return 0.7 + precisionBonus * 0.3;
  }

  const textWords = textLower.split(/\s+/);
  const queryWords = queryLower.split(/\s+/);

  let maxWordScore = 0;

  for (const queryWord of queryWords) {
    if (!queryWord) continue;

    for (const textWord of textWords) {
      if (!textWord) continue;

      const wordSimilarity = calculateSimilarityScore(textWord, queryWord);
      maxWordScore = Math.max(maxWordScore, wordSimilarity);
    }
  }

  return maxWordScore;
}

type StringSegment = {
  piece: string;
  match: boolean;
};

/**
 * Finds the best possible segmentation of text with parts that match `target`.
 * Uses dynamic programming to maximize the length of matched segments.
 *
 * @param text The source string to be segmented.
 * @param target The target string to match against.
 *
 * @returns Array of segments with information about whether each piece matches.
 */
export function fuzzyMatch(
  text: string,
  target: string,
  caseSensitive = false,
): StringSegment[] {
  if (!text?.length) {
    return [];
  }

  const cleanTarget = target?.trim();
  if (!cleanTarget?.length) {
    return [{ piece: text, match: false }];
  }

  const actualText = caseSensitive ? text : text.toLowerCase();
  const actualTarget = caseSensitive ? cleanTarget : cleanTarget.toLowerCase();

  // split the target into parts by whitespace
  const targetParts = actualTarget.split(/\s+/);

  // find the naive segmentation of the actual text
  const segments: StringSegment[] = [];
  let index = 0;

  for (const part of targetParts) {
    const matchIndex = actualText.indexOf(part, index);
    if (matchIndex === -1) {
      break;
    }

    if (matchIndex > index) {
      segments.push({ piece: text.slice(index, matchIndex), match: false });
    }

    segments.push({
      piece: text.slice(matchIndex, matchIndex + part.length),
      match: true,
    });
    index = matchIndex + part.length;
  }

  if (index < text.length) {
    segments.push({ piece: text.slice(index), match: false });
  }

  return segments;
}
