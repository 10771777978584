import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TbPercentage } from "react-icons/tb";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";

import {
  AttributeField,
  FIRST_SEEN_FIELD_KEY,
  LAST_SEEN_FIELD_KEY,
} from "@bucketco/shared/attributeFilter";
import {
  MAX_ROLLOUT_THRESHOLD,
  UIFilterType,
  wrapWithFilterGroup,
} from "@bucketco/shared/filter";
import { FlagRule } from "@bucketco/shared/flagAPI";

import { DeleteIconButton } from "@/common/components/CommonIconButtons";
import {
  FilterGroup,
  SupportedFlagFilterTypes,
} from "@/common/components/filters/FilterGroup";
import FormNumberInput from "@/common/components/Form/FormNumberInput";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { SegmentStatelessRestrictionsWarning } from "@/common/components/Rule/RuleRestrictions";

export function adjustFlagAttributeFields(
  filterType: UIFilterType,
  fields: AttributeField[],
) {
  if (filterType === "userAttribute") {
    fields = [
      {
        key: "id",
        label: "User ID",
        type: "text",
        icon: "id",
        system: true,
      },
      ...fields.filter((f) => f.key !== "id"),
    ];
  } else if (filterType === "companyAttribute") {
    fields = fields.filter(
      (f) => f.key !== LAST_SEEN_FIELD_KEY && f.key !== FIRST_SEEN_FIELD_KEY,
    );
  }

  return fields;
}

type RuleAdvancedFormProps = {
  baseName: string;
  remove?: () => void;
};

export function RuleAdvancedForm({
  baseName = "customRules",
  remove,
}: RuleAdvancedFormProps) {
  const { setValue } = useFormContext();
  const rollout = useWatch({
    name: `${baseName}.partialRolloutThreshold`,
  });
  const [showRollout, setShowRollout] = useState(
    rollout < MAX_ROLLOUT_THRESHOLD,
  );
  return (
    <Box as="fieldset">
      <Flex>
        <ManagedFormControl
          _inputGroup={{
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          name={`${baseName}.filter`}
          render={({ field: { value, onChange } }) => (
            <FilterGroup
              adjustAttributeFields={adjustFlagAttributeFields}
              context="flag"
              segmentFilterFn={(s) =>
                s.stateless ? "supported" : "unsupported"
              }
              segmentUnsupportedReason={<SegmentStatelessRestrictionsWarning />}
              showItemsIcons={true}
              types={SupportedFlagFilterTypes}
              value={wrapWithFilterGroup(value)}
              flatten
              onChange={onChange}
            />
          )}
        />
        <IconButton
          aria-label="Gradual rollout"
          color={!showRollout ? "dimmed" : undefined}
          colorScheme={showRollout ? "gray" : undefined}
          icon={<TbPercentage size={18} />}
          mr={0.5}
          mt={0.5}
          size="xs"
          variant={showRollout ? "subtle" : "ghost"}
          onClick={() => {
            setShowRollout(!showRollout);
            if (!showRollout) {
              setValue(
                `${baseName}.partialRolloutThreshold`,
                MAX_ROLLOUT_THRESHOLD,
                {
                  shouldDirty: true,
                },
              );
            }
          }}
        />
        <DeleteIconButton
          color="dimmed"
          label="Remove"
          mt={0.5}
          onClick={() => remove?.()}
        />
      </Flex>

      {showRollout && (
        <Flex
          alignItems="center"
          borderTopColor="appBorder"
          borderTopWidth={1}
          gap={4}
          mt={3}
          pt={3}
        >
          <FormNumberInput
            _control={{ w: 24 }}
            _label={{ fontWeight: "medium" }}
            fontSize="sm"
            max={100}
            min={0}
            name={`${baseName}.partialRolloutThreshold`}
            scaleFactor={100 / MAX_ROLLOUT_THRESHOLD} // 0-100% = 0-100000
            unit="%"
          />
          <Text color="dimmed" fontSize="sm">
            Roll-out to a part of companies matched by the filters
          </Text>
        </Flex>
      )}
    </Box>
  );
}

type RuleAdvancedDisplayProps = {
  targetingRule: FlagRule;
};

export const RuleAdvancedDisplay = ({
  targetingRule,
}: RuleAdvancedDisplayProps) => {
  const { filter, partialRolloutThreshold } = targetingRule;
  return (
    <Box>
      <FilterGroup
        adjustAttributeFields={adjustFlagAttributeFields}
        context="flag"
        types={SupportedFlagFilterTypes}
        value={wrapWithFilterGroup(filter)}
        isReadOnly
      />
      {typeof partialRolloutThreshold === "number" &&
        partialRolloutThreshold < MAX_ROLLOUT_THRESHOLD && (
          <Flex
            alignItems="center"
            borderTopColor="appBorder"
            borderTopWidth={1}
            gap={4}
            mt={3}
            pt={3}
          >
            <Text fontSize="sm" fontWeight="medium">
              {Math.round(
                (partialRolloutThreshold / MAX_ROLLOUT_THRESHOLD) * 100,
              )}
              %
            </Text>
            <Text color="dimmed" fontSize="sm">
              Roll-out to a part of companies matched by the filters
            </Text>
          </Flex>
        )}
    </Box>
  );
};
