import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Select,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useOrgsData } from "@/app/data/useOrgsData";
import CenteredLayout from "@/common/components/CenteredLayout";
import { API_URL } from "@/common/utils/env";

export default function ChooseOrgForIntegration() {
  const [selectedOrgId, setSelectedOrgId] = useState<string>("");
  const { data: orgsResult, isLoading } = useOrgsData();

  const orgs = useMemo(() => orgsResult?.orgs ?? [], [orgsResult]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedOrgId) {
      pickOrg(selectedOrgId);
    }
  };

  const pickOrg = (orgId: string) => {
    // Redirect to GitHub user endpoint with orgId
    const urlParams = new URLSearchParams(window.location.search);

    const newUrlParams = new URLSearchParams({
      installation_id: urlParams.get("installation_id") ?? "",
      code: urlParams.get("code") ?? "",
    });

    const url = `${API_URL}/orgs/${orgId}/github/connect?${newUrlParams.toString()}`;

    // useNavigate doesn't work here because it doesn't support absolute URLs
    window.location.href = url;
  };

  useEffect(() => {
    if (orgs.length === 1) {
      pickOrg(orgs[0].id);
    }
  }, [orgs]);

  if (isLoading || orgs.length === 1) {
    return (
      <CenteredLayout>
        <Spinner size="xl" />
      </CenteredLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>Choose Organization</title>
      </Helmet>
      <Flex direction="column" gap={4} w="sm">
        <Flex direction="row" gap={4}>
          <Heading fontSize="2xl">Connect with GitHub</Heading>
        </Flex>
        <Text color="dimmed">
          Choose which organization you want to connect with GitHub
        </Text>
        <Divider />
        <form onSubmit={handleSubmit}>
          <VStack align="flex-start" spacing={6}>
            <FormControl isRequired>
              <FormLabel>Organization</FormLabel>
              <Select
                placeholder="Select organization"
                value={selectedOrgId}
                onChange={(e) => setSelectedOrgId(e.target.value)}
              >
                {orgs &&
                  orgs.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <HStack spacing={4}>
              <Button
                isDisabled={!selectedOrgId}
                size="md"
                type="submit"
                variant="primary"
              >
                Continue
              </Button>
            </HStack>
          </VStack>
        </form>
      </Flex>
    </>
  );
}
