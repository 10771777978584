import { forwardRef, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";
import { UserName, UsersQuery } from "@bucketco/shared/userAPI";

import AddInBulkButton from "@/common/components/Autocomplete/AddInBulk";
import AutocompleteSelect from "@/common/components/Autocomplete/AutocompleteSelect";
import { UserDisplay } from "@/common/components/UserDisplay";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useUser, useUsersSearch } from "@/common/data/useUsers";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useDebounce } from "@/common/hooks/useDebounce";
import {
  AutocompleteImplementationProps,
  AutocompleteValueIdProps,
} from "@/common/utils/autocomplete";

export type UserAutocompleteSelectProps = {
  onAddInBulk?: (ids: string[]) => void;
} & AutocompleteImplementationProps<UserName> &
  AutocompleteValueIdProps<UserName> & {
    query?: Omit<UsersQuery, "envId">;
  };

export const UserAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  UserAutocompleteSelectProps
>(function UserAutocompleteSelect(
  {
    valueAsId,
    value: valueProp,
    query,
    placeholder = "Select a user",
    canClear,
    onChange,
    onAddInBulk,
    ...autocompleteProps
  }: UserAutocompleteSelectProps,
  ref,
) {
  const { appId, envId } = useCurrentEnv();
  const valueId = useMemo(
    () => (valueAsId ? valueProp : valueProp?.id),
    [valueAsId, valueProp],
  );
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, SEARCH_DEBOUNCE_MS);

  const { data: valueEnhancement, isLoading: isLoadingEnhancement } = useUser(
    valueId ?? undefined,
  );

  const {
    data: userData = [],
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useUsersSearch(debouncedSearchValue, query);

  const internalValue = useMemo(() => {
    return valueProp && valueProp === valueEnhancement?.id
      ? valueEnhancement
      : undefined;
  }, [valueProp, valueEnhancement]);

  const suggestions = useMemo(() => {
    return internalValue
      ? [internalValue, ...userData.filter((u) => u.id !== internalValue.id)]
      : userData;
  }, [userData, internalValue]);

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      footer={
        onAddInBulk && (
          <AddInBulkButton
            description="Enter user IDs separated by commas, spaces or line breaks"
            textareaPlaceholder="user1 user2 user3"
            title="Add users in bulk"
            onAddInBulk={onAddInBulk}
          />
        )
      }
      hasMore={hasNextPage}
      isLoading={isLoading || isLoadingEnhancement}
      isLoadingMore={isFetchingNextPage}
      isLoadingSuggestions={isFetching}
      itemKeyProperty="id"
      itemRenderFn={(u, search) => (
        <UserDisplay py={0.5} search={search} user={u} showEmail showId />
      )}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={suggestions}
      value={internalValue}
      onChange={(newValue: UserName | null) => {
        setSearchValue("");
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }
        // If a value is selected, pre-seed the single user query for immediate display
        queryClient.setQueryData(
          commonQueryKeys.user(appId, envId, newValue.id),
          newValue,
        );
        // If valueAsId is true, return only the id of the selected user
        if (valueAsId) {
          onChange?.(newValue?.id ?? null);
        } else {
          onChange?.(newValue ?? null);
        }
      }}
      onClear={() => {
        setSearchValue("");
      }}
      onLoadMore={fetchNextPage}
      onSearchInput={setSearchValue}
      {...autocompleteProps}
    />
  );
});
