import { type CSSProperties, type FC, useEffect } from "react";
import type { XYCoord } from "react-dnd";
import { useDragLayer, useDrop } from "react-dnd";
import { Checkbox, Flex, Portal } from "@chakra-ui/react";

import { DragType, DragTypeItemMap } from "@/common/types/dragDropTypes";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";

const renderers: {
  [Property in keyof DragTypeItemMap]: (
    props: DragTypeItemMap[Property],
  ) => JSX.Element;
} = {
  [DragType.FeatureDisplay]: (props) => {
    return (
      <FeatureDisplay
        backgroundColor="appBackground"
        borderColor="appBorder"
        borderWidth={1}
        feature={props}
        px={4}
        py={1.5}
        rounded="md"
        shadow="md"
      />
    );
  },
  [DragType.MenuReorderOption]: (props) => {
    return (
      <Flex gap="0.5rem">
        <Checkbox
          colorScheme="gray"
          isChecked={props.isChecked}
          pointerEvents="none"
          size="sm"
        />
        <span style={{ flex: 1 }}>{props.children}</span>
      </Flex>
    );
  },
};

const layerStyles: CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px) rotate(-3deg)`;
  return {
    transform,
    opacity: 0.7,
    display: "inline-block",
  };
}

export const DragPreviewLayer: FC = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  useImmediateDrop(itemType);

  if (!isDragging || !itemType) {
    return null;
  }

  return (
    <Portal>
      <div style={layerStyles}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          {renderers[itemType as DragType]?.(item)}
        </div>
      </div>
    </Portal>
  );
};

/**
 * Hack for avoiding the delay between releaseing the mouse while
 * dragging and the animation of the preview element back to the
 * original position.
 *
 * Set up a drop handler on the body with a no-op. This means all
 * drops are handled immediately by a drop handler and no aniimation
 * delay occurs.
 *
 * See https://github.com/react-dnd/react-dnd/issues/3115#issuecomment-1220682935
 */
function useImmediateDrop(itemType: string | symbol | null) {
  const [, connectDropTarget] = useDrop({
    accept: itemType ?? "N/A",
  });

  useEffect(() => {
    connectDropTarget(document.body);
    return () => {
      connectDropTarget(null);
    };
  }, [itemType, connectDropTarget]);
}
