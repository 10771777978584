import {
  RiCheckLine,
  RiErrorWarningLine,
  RiTriangleLine,
} from "react-icons/ri";
import {
  Badge,
  Button,
  FormLabel,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { LinearConnection } from "@bucketco/shared/linearConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { compactFormattedDate } from "@/common/utils/datetime";
import { InitiateIntegrationButton } from "@/integration/components/InitiateIntegrationButton";
import { useLinearConnection } from "@/integration/data/useLinearConnection";
import { useLinearDeleteMutation } from "@/integration/data/useLinearDeleteMutation";

function Connected({
  linearConnection,
}: {
  linearConnection: LinearConnection;
}) {
  const { mutate: deleteConnection, isPending: mutationIsLoading } =
    useLinearDeleteMutation();

  return (
    <VStack align="flex-start" spacing={3}>
      <HStack>
        <Badge colorScheme="green" size="md" variant="subtle">
          <HStack spacing={1}>
            <RiCheckLine size={16} />
            <Text fontSize="sm">Connected</Text>
          </HStack>
        </Badge>
        <Text color="dimmed" fontSize="sm">
          to
          <Text as="span" fontWeight="bold" ml={1} mr={1}>
            {linearConnection.organization}
          </Text>
          since {compactFormattedDate(linearConnection.createdAt)}
        </Text>
      </HStack>
      <Button
        isLoading={mutationIsLoading}
        size="sm"
        variant="outline"
        onClick={() => deleteConnection()}
      >
        Disconnect
      </Button>
    </VStack>
  );
}

function NeedsReauthorization() {
  return (
    <VStack align="flex-start" spacing={3}>
      <Badge colorScheme="purple" size="md" variant="subtle">
        <HStack spacing={1}>
          <RiTriangleLine size={16} />
          <Text fontSize="sm">Requires reauthorization</Text>
        </HStack>
      </Badge>
      <Text color="dimmed" fontSize="sm" maxWidth="lg">
        The connection to Linear either was not finalized or has expired. Please
        reauthorize the app to continue using it.
      </Text>
      <InitiateIntegrationButton integration="linear" />
    </VStack>
  );
}

function NotConnected() {
  return (
    <VStack align="flex-start" spacing={3}>
      <Badge colorScheme="orange" size="md" variant="subtle">
        <HStack spacing={1}>
          <RiErrorWarningLine size={16} />
          <Text fontSize="sm">Not connected</Text>
        </HStack>
      </Badge>
      <Text color="dimmed" fontSize="sm" maxWidth="lg">
        Take advantage of Linear&apos;s powerful issue tracking and
        collaboration features in Bucket.
      </Text>
      <InitiateIntegrationButton integration="linear" />
    </VStack>
  );
}

export default function LinearIntegrationForm() {
  const { currentOrg } = useAuthContext();
  if (!currentOrg) {
    throw new Error("org missing");
  }

  const { data: linearConnection, isLoading } = useLinearConnection();

  return (
    <VStack align="flex-start">
      <FormLabel pb={1}>Linear integration</FormLabel>
      {isLoading ? (
        <Spinner size="sm" />
      ) : linearConnection && !linearConnection.needsReauthorization ? (
        <Connected linearConnection={linearConnection} />
      ) : linearConnection && linearConnection.needsReauthorization ? (
        <NeedsReauthorization />
      ) : (
        <NotConnected />
      )}
    </VStack>
  );
}
