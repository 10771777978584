import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

export const CheckboxTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: "gray",
  },
  baseStyle: ({ colorScheme: c }) => ({
    control: {
      _checked:
        c === "gray"
          ? {
              bg: "gray.800",
              borderColor: "gray.800",
              _dark: { bg: "white", borderColor: "white" },
            }
          : {
              bg: `${c}.500`,
              _dark: { bg: `${c}.500` },
            },
    },
  }),
});
