import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

export const SwitchTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: "gray",
  },
  baseStyle: ({ colorScheme: c }) => ({
    track: {
      _checked:
        c === "gray"
          ? {
              bg: "gray.800",
              _dark: { bg: "white" },
            }
          : {
              bg: `${c}.500`,
              _dark: { bg: `${c}.500` },
            },
    },
    thumb: {
      _checked:
        c === "gray"
          ? {
              _dark: { bg: "gray.800" },
            }
          : { _dark: {} },
    },
  }),
});
