import { useFormContext } from "react-hook-form";
import { Button, ButtonProps } from "@chakra-ui/react";

type FormSubmitProps = Omit<ButtonProps, "type"> & {
  allowPristine?: boolean;
};

export default function FormSubmitLegacy({
  allowPristine,
  children = "Save",
  size = "sm",
  ...rest
}: FormSubmitProps) {
  const {
    formState: { isSubmitting, isDirty, isValid },
  } = useFormContext();

  return (
    <Button
      isDisabled={rest.isDisabled || !isValid || (!allowPristine && !isDirty)}
      isLoading={rest.isLoading || isSubmitting}
      size={size}
      type="submit"
      variant="solid"
      {...rest}
    >
      {children}
    </Button>
  );
}
