import { z } from "zod";

import { redirectUrlSchema } from "./schemas/redirectUrlSchema";
import { APIEmptyResponse, APIResponse } from "./api";

export interface LinearConnection {
  id: string;
  organization: string;
  needsReauthorization: boolean;
  expiresAt?: string;
  createdAt: string;
  updatedAt: string;
}

export const LinearTrackingEntitySchema = z.object({
  type: z.enum(["issue", "project"]),
  id: z.string().nonempty(),
  identifier: z.string().optional(),
  title: z.string().nonempty(),
  priority: z.number().min(0).max(4),
  url: z.string().url().optional(),
});
export type LinearTrackingEntity = z.infer<typeof LinearTrackingEntitySchema>;

export const ConnectToLinearQuerySchema = z
  .object({
    redirectUri: redirectUrlSchema,
  })
  .strict();

export type ConnectToLinearQueryType = z.infer<
  typeof ConnectToLinearQuerySchema
>;

export const ConnectToLinearResponseQuerySchema = z
  .object({
    code: z.string().nonempty(),
    state: z.string().nonempty(),
  })
  .strict();

export type ConnectToLinearResponseQueryType = z.infer<
  typeof ConnectToLinearResponseQuerySchema
>;

export const LinearEntitiesQuerySchema = z
  .object({
    searchTerm: z.string().optional(),
    maxResults: z.coerce.number().min(1).max(50).default(10).optional(),
  })
  .strict();

export type LinearEntitiesQuery = z.infer<typeof LinearEntitiesQuerySchema>;

export interface LinearConnectionAPI {
  "/orgs/:orgId/linear/connection": {
    GET: {
      response: APIResponse<LinearConnection | null>;
      params: { orgId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { orgId: string };
    };
  };
  "/orgs/:orgId/linear/connection/search-entities": {
    GET: {
      response: APIResponse<LinearTrackingEntity[]>;
      params: { orgId: string };
      query: LinearEntitiesQuery;
    };
  };
  "/orgs/:orgId/linear/connect": {
    GET: {
      params: { orgId: string };
      query: ConnectToLinearQueryType;
    };
  };
  "/callback/linear": {
    GET: {
      response: void;
      query: ConnectToLinearResponseQueryType;
    };
  };
}
