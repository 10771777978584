import { Divider, Flex } from "@chakra-ui/react";

import { useAuthContext } from "@/auth/contexts/authContext";
import { InitiateIntegrationButton } from "@/integration/components/InitiateIntegrationButton";

type LinearConnectionGuardProps = {
  children?: React.ReactNode;
};

export default function LinearConnectionGuard({
  children,
}: LinearConnectionGuardProps) {
  const { currentOrg } = useAuthContext();

  return (
    <Flex direction="column" gap={4}>
      {!currentOrg?.integrations.linear && (
        <>
          <InitiateIntegrationButton integration="linear" />
          <Divider />
        </>
      )}
      {children}
    </Flex>
  );
}
