import { ReactNode, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Link,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import capitalize from "lodash/capitalize";

import { FeedbackPushDestination } from "@bucketco/shared/feedbackAPI";

import { useFeedbackPushMutation } from "@/feedback/data/useFeedbackPushMutation";

type PushFeedbackDialogProps = {
  feedbackId: string;
  destination: FeedbackPushDestination;
  children: (props: { openDialog: () => void }) => ReactNode;
};

export default function PushFeedbackDialog({
  feedbackId,
  children,
  destination,
}: PushFeedbackDialogProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    mutate: pushMutate,
    isPending: isPushLoading,
    data: pushData,
  } = useFeedbackPushMutation(feedbackId, (data) => {
    toast({
      title: `Feedback pushed to ${capitalize(destination)}`,
      description: (
        <Text>
          Feedback pushed to {capitalize(destination)} successfully.{" "}
          {data.destinationUrl && (
            <Link href={data.destinationUrl} target="_blank">
              View it in {capitalize(destination)}
            </Link>
          )}
        </Text>
      ),
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    onClose();
  });

  const cancelRef = useRef(null);

  return (
    <>
      {children({ openDialog: onOpen })}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Push feedback</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to push this feedback to{" "}
              {capitalize(destination)}?
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  isDisabled={isPushLoading}
                  variant="ghostDimmed"
                  onClick={onClose}
                >
                  {pushData ? "Close" : "Cancel"}
                </Button>
                <Button
                  colorScheme="blue"
                  isDisabled={!!pushData}
                  isLoading={isPushLoading}
                  onClick={() => pushMutate()}
                >
                  Push
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
