import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
  useStyleConfig,
} from "@chakra-ui/react";

import { SegmentWithCountsDTO } from "@bucketco/shared/segmentAPI";
import { GlobalSettingsUrl, SegmentUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { useAuthContext } from "@/auth/contexts/authContext";
import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import { HeaderLayoutHeading } from "@/common/components/HeaderLayout";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import MenuDescription from "@/common/components/MenuDescription";
import { NavLink } from "@/common/components/NavLink";
import { useSegmentsWithCounts } from "@/company/data/useSegments";

export function SegmentNavigator({
  currentSegment,
}: {
  currentSegment: SegmentWithCountsDTO;
}) {
  const { currentEnv } = useAuthContext();

  const { data: segments = [], isLoading: isLoadingSegments } =
    useSegmentsWithCounts();

  const segmentsMenu = segments
    .sort((a, b) =>
      a.isAllSegment ? -1 : b.isAllSegment ? 1 : a.name.localeCompare(b.name),
    )
    .map((segment) => {
      return {
        id: segment.id,
        to: SegmentUrl(currentEnv!, segment.id),
        label: segment.name,
        count: segment.segmentCount,
        allCount: segment.allCount,
      };
    });

  const currentItemColor = useColorModeValue("brand.500", "brand.300");

  return (
    <Menu>
      <MenuButton
        aria-label="Switch segment"
        as={Button}
        display="flex"
        pl={2}
        pr={1}
        rightIcon={
          <Box fontSize="lg" ml={-0.5}>
            <RiArrowDownSLine />
          </Box>
        }
        size="sm"
        variant="outlineOnHover"
      >
        <HStack spacing={1.5}>
          <SimplePieChart
            size="16px"
            value={
              getFraction(
                currentSegment.segmentCount,
                currentSegment.allCount,
              ) * 100
            }
          />
          <HeaderLayoutHeading fontSize="md">
            {currentSegment.name}
          </HeaderLayoutHeading>
        </HStack>
      </MenuButton>
      <MenuList>
        <HStack fontSize="sm">
          <MenuDescription maxW="xs" pr={0}>
            Switch segment
          </MenuDescription>
          <InfoIconTooltip
            docsURL="https://docs.bucket.co/introduction/concepts/segment"
            text="Segments are filtered lists of companies. Column configutation are saved per segment."
          />
        </HStack>
        <MenuDivider my={0} />
        {isLoadingSegments ? (
          <Box pb={1} pt={2} px={3}>
            <Spinner size="sm" />
          </Box>
        ) : (
          segmentsMenu.map((i) => (
            <MenuItem
              key={i.id}
              as={NavLink}
              icon={
                <SimplePieChart
                  size="16px"
                  value={getFraction(i.count, i.allCount) * 100}
                />
              }
              matchSearchParams={["segment"]}
              sx={{
                "&[aria-current='page']": {
                  color: currentItemColor,
                },
              }}
              to={i.to}
            >
              <HStack>
                <Text flexGrow={1}>{i.label}</Text>
                <Text color="dimmed">{i.count}</Text>
              </HStack>
            </MenuItem>
          ))
        )}
        <MenuDivider my={0} />

        <MenuItem
          as={Link}
          display="block"
          m="2"
          sx={useStyleConfig("Button", {
            variant: "outline",
            size: "xs",
          })}
          to={GlobalSettingsUrl(currentEnv!, "app-segments")}
          width="max-content"
        >
          Manage segments
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
