import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { AccessPlanRestrictedButton } from "@/app/components/AccessPlanRestricted";
import FormSubmit from "@/common/components/Form/FormSubmit";
import FormTextArea from "@/common/components/Form/FormTextArea";
import { parseIDList } from "@/common/utils/parseIDList";

const MAX_ID_LENGTH = 100;

const BulkInputSchema = z.object({
  ids: z.string().refine((value) => {
    return (
      parseIDList(value).filter((id) => id.length > MAX_ID_LENGTH).length === 0
    );
  }, `Single IDs must be less than ${MAX_ID_LENGTH} characters. Did you correctly separate IDs with commas, space or line breaks?`),
});

type BulkInputForm = z.input<typeof BulkInputSchema>;

function AddInBulkModal({
  isOpen,
  title,
  description,
  textareaPlaceholder,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  title: string;
  description: string;
  textareaPlaceholder: string;
  onClose: () => void;
  onSubmit: (idList: string[]) => void;
}) {
  const form = useForm<BulkInputForm>({
    resolver: zodResolver(BulkInputSchema),
  });

  const handleSubmit = form.handleSubmit(({ ids }) => {
    onSubmit(parseIDList(ids));
  });

  return (
    <Modal
      isOpen={isOpen}
      trapFocus={false}
      onClose={onClose}
      onCloseComplete={form.reset}
    >
      <ModalOverlay>
        <FormProvider {...form}>
          <ModalContent
            as="form"
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormTextArea
                description={description}
                name="ids"
                placeholder={textareaPlaceholder}
                size="sm"
                autoFocus
              />
            </ModalBody>
            <ModalFooter>
              <Button mr={3} variant="ghostDimmed" onClick={onClose}>
                Cancel
              </Button>
              <FormSubmit>Add</FormSubmit>
            </ModalFooter>
          </ModalContent>
        </FormProvider>
      </ModalOverlay>
    </Modal>
  );
}

export default function AddInBulkButton({
  onAddInBulk,
  description,
  textareaPlaceholder,
  title,
}: {
  onAddInBulk: (ids: string[]) => void;
  description: string;
  textareaPlaceholder: string;
  title: string;
}) {
  const bulkAddModal = useDisclosure();

  return (
    <>
      <AddInBulkModal
        {...bulkAddModal}
        description={description}
        textareaPlaceholder={textareaPlaceholder}
        title={title}
        onSubmit={(values) => {
          bulkAddModal.onClose();
          onAddInBulk(values);
        }}
      />
      <AccessPlanRestrictedButton
        featureKey="add-in-bulk"
        size="xs"
        variant="outline"
        onClick={bulkAddModal.onOpen}
      >
        Add in bulk
      </AccessPlanRestrictedButton>
    </>
  );
}
