import { RiExternalLinkLine } from "react-icons/ri";
import { Button, HStack, Link, Text } from "@chakra-ui/react";

import GithubLogo from "@/common/assets/github-logo.svg?react";
import LinearLogo from "@/common/assets/linear-logo.svg?react";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import useGithubConnectionUrl from "@/integration/hooks/useGithubConnectionUrl";
import useLinearConnectionUrl from "@/integration/hooks/useLinearConnectionUrl";
import useSlackConnectionUrl from "@/integration/hooks/useSlackConnectionUrl";

const Integrations = {
  slack: {
    logo: <SlackHashLogo height="16" width="16" />,
    connectionUrlFunction: useSlackConnectionUrl,
    docsUrl: "https://docs.bucket.co/integrations/slack",
    title: "Connect to Slack",
  },
  linear: {
    logo: <LinearLogo color="#5E6AD2" opacity="1" width="16" />,
    connectionUrlFunction: useLinearConnectionUrl,
    docsUrl: "https://docs.bucket.co/integrations/linear",
    title: "Connect to Linear",
  },
  github: {
    logo: <GithubLogo color="#5E6AD2" opacity="1" width="16" />,
    connectionUrlFunction: useGithubConnectionUrl,
    docsUrl: "https://docs.bucket.co/integrations/github",
    title: "Connect to Github",
  },
};

type IntegrationType = keyof typeof Integrations;

type InitiateIntegrationButtonProps = {
  integration: IntegrationType;
};

export function InitiateIntegrationButton({
  integration,
}: InitiateIntegrationButtonProps) {
  const { logo, connectionUrlFunction, docsUrl, title } =
    Integrations[integration];
  const connectionUrl = connectionUrlFunction();

  return (
    <HStack spacing={3}>
      <Button
        as="a"
        href={connectionUrl}
        leftIcon={logo}
        size="sm"
        variant="outline"
      >
        {title}
      </Button>
      <Link color="dimmed" href={docsUrl} target="_blank" textDecoration="none">
        <HStack>
          <Text fontSize="sm">Open docs</Text>
          <RiExternalLinkLine size={14} />
        </HStack>
      </Link>
    </HStack>
  );
}
