import { useAuthContext } from "@/auth/contexts/authContext";
import { API_URL } from "@/common/utils/env";

export default function useGithubConnectionUrl(): string {
  const { currentOrg, currentApp } = useAuthContext();

  if (!currentOrg || !currentApp) {
    return "";
  }

  const redirectUri = window.location.href.replace(window.location.origin, "");

  const searchParams = {
    redirectUri,
  };

  return `${API_URL}/orgs/${currentOrg.id}/github/connect?${new URLSearchParams(
    searchParams,
  ).toString()}`;
}
