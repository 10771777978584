import JSON5 from "json5";
import { z } from "zod";

type Json = string | boolean | number | null | { [key: string]: Json } | Json[];

export const JSON5Schema = (message = "Invalid JSON") =>
  z.string().transform((str, ctx): Json => {
    try {
      return str.length ? JSON5.parse(str) : null;
    } catch {
      ctx.addIssue({ code: "custom", message });
      return z.NEVER;
    }
  });
