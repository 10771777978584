import { ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Divider,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import startCase from "lodash/startCase";

import { ErrorResponse } from "@bucketco/shared/api";

import FormInput from "@/common/components/Form/FormInput";
import FormRadioGroup from "@/common/components/Form/FormRadioGroup";
import FormSubmit from "@/common/components/Form/FormSubmit";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import useOutsideClickPopover from "@/common/hooks/useOutsideClickPopover";
import { UpsertForm, UpsertFormSchema } from "@/common/types/upsertForm";

type Props<TData = unknown, TVariables = unknown> = {
  entity: string;
  mutation: UseMutationResult<TData, AxiosError<ErrorResponse>, TVariables>;
  children?: ((action: UpsertForm["action"]) => ReactNode) | ReactNode;
  canUpdate?: boolean;
  canCreate?: boolean;
  onReset?: () => void;
};

export function UpsertPopover<TData = unknown, TVariables = unknown>({
  entity: entityProp,
  mutation,
  children,
  canUpdate = true,
  canCreate = true,
  onReset,
}: Props<TData, TVariables>) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { contentRef, triggerRef } = useOutsideClickPopover({
    isOpen,
    onToggle,
  });
  const entity = entityProp.toLowerCase();

  const form = useForm<UpsertForm>({
    resolver: zodResolver(UpsertFormSchema),
    defaultValues: canUpdate
      ? {
          action: "update",
        }
      : {
          action: "create",
          name: "",
        },
  });

  const handleSubmit = useFormMutationSubmitHandler(form, mutation);

  const action = form.watch("action");

  return (
    <HStack spacing={3}>
      <Button color="dimmed" variant="ghost" onClick={onReset}>
        Reset
      </Button>
      <Popover
        autoFocus={false}
        closeOnEsc={true}
        isOpen={isOpen}
        placement="bottom-start"
        trigger="click"
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button ref={triggerRef} variant="solid" onClick={onToggle}>
            Save {entity}
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={contentRef} minW="xs">
          <PopoverArrow />
          <PopoverBody display="flex" flexDir="column" gap={3} p={5}>
            <form onSubmit={handleSubmit}>
              <FormProvider {...form}>
                <FormRadioGroup
                  defaultValue="update"
                  name="action"
                  options={[
                    {
                      label: `Update ${entity}`,
                      value: "update",
                      isDisabled: !canUpdate,
                    },
                    {
                      label: `Create a new ${entity}`,
                      value: "create",
                      isDisabled: !canCreate,
                    },
                  ]}
                  size="sm"
                />
                {action === "create" && (
                  <FormInput
                    autoComplete="off"
                    defaultValue=""
                    isDisabled={!canCreate}
                    mt={2}
                    name="name"
                    placeholder={`${startCase(entity)} name`}
                    size="sm"
                  />
                )}
                <FormSubmit
                  isDisabled={
                    (action === "update" && !canUpdate) ||
                    (action === "create" && !canCreate)
                  }
                  isLoading={mutation.isPending}
                  mt={4}
                >
                  {action === "update"
                    ? `Update ${entity}`
                    : `Create ${entity}`}
                </FormSubmit>
              </FormProvider>
            </form>
            {children && (
              <>
                <Divider />
                {typeof children === "function" ? children(action) : children}
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
}
