import { forwardRef, useMemo, useState } from "react";

import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";
import { LinearTrackingEntity } from "@bucketco/shared/linearConnectionAPI";

import AutocompleteSelect from "@/common/components/Autocomplete/AutocompleteSelect";
import { useDebounce } from "@/common/hooks/useDebounce";
import {
  AutocompleteImplementationProps,
  AutocompleteValueProps,
} from "@/common/utils/autocomplete";
import { LinearTrackingEntityDisplay } from "@/integration/components/linear/LinearTrackingEntityDisplay";
import { useLinearTrackingEntitySearch } from "@/integration/data/useLinearTrackingEntitySearch";

export type LinearTrackingEntityAutocompleteSelectProps =
  AutocompleteImplementationProps<LinearTrackingEntity> &
    AutocompleteValueProps<LinearTrackingEntity>;

export const LinearTrackingEntityAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  LinearTrackingEntityAutocompleteSelectProps
>(function LinearTrackingEntityAutocompleteSelect(
  {
    value,
    placeholder = "Select a linear issue/project",
    canClear,
    onChange,
    ...autocompleteProps
  }: LinearTrackingEntityAutocompleteSelectProps,
  ref,
) {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, SEARCH_DEBOUNCE_MS);

  const {
    data = [],
    isLoading,
    isFetching,
  } = useLinearTrackingEntitySearch(debouncedSearchValue);

  const suggestions = useMemo(() => {
    return value ? [value, ...data.filter((e) => e.id !== value.id)] : data;
  }, [data, value]);

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      isLoading={isLoading}
      isLoadingSuggestions={isFetching}
      itemKeyProperty="id"
      itemRenderFn={(e, search) => (
        <LinearTrackingEntityDisplay entity={e} py={0.5} search={search} />
      )}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={suggestions}
      value={value ?? undefined}
      onChange={(newValue: LinearTrackingEntity | null) => {
        setSearchValue("");
        if (!newValue) {
          if (canClear) {
            onChange?.(null);
          }
          return;
        }

        onChange?.(newValue ?? null);
      }}
      onClear={() => {
        setSearchValue("");
      }}
      onSearchInput={setSearchValue}
      {...autocompleteProps}
    />
  );
});
