import { forwardRef, useMemo } from "react";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import AutocompleteSelect, {
  getStringFilterFunction,
  getStringRenderFunction,
} from "@/common/components/Autocomplete/AutocompleteSelect";
import {
  AutocompleteImplementationProps,
  AutocompleteValueIdProps,
} from "@/common/utils/autocomplete";
import useSlackChannels from "@/integration/hooks/useSlackChannels";

export type SlackChannelAutocompleteSelectProps =
  AutocompleteImplementationProps<SlackChannel> &
    AutocompleteValueIdProps<SlackChannel> & {
      showDefaultChannel?: boolean;
    };

export const SlackChannelAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  SlackChannelAutocompleteSelectProps
>(function SlackChannelAutocompleteSelect(
  {
    valueAsId,
    value: valueProp,
    size,
    placeholder = "No channel selected",
    showDefaultChannel = true,
    canClear,
    onChange,
    ...autocompleteProps
  },
  ref,
) {
  const {
    data: channels = [],
    isLoading,
    isFetching,
    isError,
  } = useSlackChannels();
  const { currentOrg, currentEnv, currentApp } = useAuthContext();

  const defaultSlackChannel =
    currentEnv?.slackChannel ||
    currentApp?.environments.find((env) => env.isProduction)?.slackChannel;

  const valueId = useMemo(
    () => (valueAsId ? valueProp : valueProp?.id),
    [valueAsId, valueProp],
  );

  const internalValue = useMemo(
    () => channels.find((c) => c.id === valueId),
    [channels, valueId],
  );

  const actualPlaceholder = isError
    ? "Error loading channels"
    : (channels.length === 0 && "No channels found") ||
      (showDefaultChannel &&
        defaultSlackChannel?.name &&
        `${defaultSlackChannel.name} (default)`) ||
      placeholder;

  return (
    <AutocompleteSelect
      ref={ref}
      aria-label="Select a Slack channel"
      canClear={canClear}
      isDisabled={isError || !currentOrg?.integrations.slack}
      isLoading={isLoading}
      isLoadingSuggestions={isFetching}
      itemFilterFn={getStringFilterFunction("name")}
      itemKeyProperty="id"
      itemRenderFn={getStringRenderFunction("name")}
      leftIcon={<SlackHashLogo height="14" />}
      loadingText="Loading..."
      maxSuggestions={50}
      placeholder={actualPlaceholder}
      size={size}
      suggestions={channels}
      value={internalValue}
      onChange={(newValue: SlackChannel | null) => {
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }
        if (valueAsId) {
          onChange?.(newValue?.id ?? null);
        } else {
          onChange?.(newValue ?? null);
        }
      }}
      onClear={() => {
        if (canClear) onChange?.(null);
      }}
      {...autocompleteProps}
    />
  );
});

SlackChannelAutocompleteSelect.displayName = "SlackChannelAutocompleteSelect";
