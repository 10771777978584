import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { FeedbackPushResultDTO } from "@bucketco/shared/feedbackAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { translateError } from "@/common/utils/errorHandling";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { feedbackQueryKeys } from "@/feedback/data/feedbackQueryKeys";

export function useFeedbackPushMutation(
  feedbackId?: string,
  onPushed?: (data: FeedbackPushResultDTO) => void,
) {
  const { appId, envId } = useCurrentEnv();
  const toast = useToast();
  const errorToast = useErrorToast();

  return useMutation({
    mutationKey: feedbackQueryKeys.push(appId, envId, "linear", feedbackId),
    mutationFn: () =>
      api
        .post<"/apps/:appId/feedback/:feedbackId/push/linear">(
          `/apps/${appId}/feedback/${feedbackId}/push/linear`,
          null,
          {
            params: { envId: envId! },
          },
        )
        .then((res) => res.data),

    onSuccess: (data) => {
      segmentAnalytics.track("Feedback Pushed to Linear");

      if (!onPushed) {
        toast({
          title: "Feedback pushed to Linear",
          description: "Feedback pushed to Linear successfully",
        });
      } else {
        onPushed(data);
      }
    },
    onError: (error) => {
      const { title, description } = translateError(
        error,
        `Failed to push feedback to Linear`,
      );

      errorToast({
        title,
        description,
        duration: 5000,
      });
    },
  });
}
