import { RiSlackFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  HStack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackLogo from "@/common/assets/slack-hash-logo.svg?react";
import useSlackChannel from "@/integration/hooks/useSlackChannel";

type SlackStatusBadgeProps = ButtonProps & {
  active: boolean;
  settingsLink: string;
  slackChannel: SlackChannel | null;
  label?: string;
  tooltip?: React.ReactNode;
};

export default function SlackStatusBadge({
  label,
  active,
  settingsLink,
  slackChannel,
  tooltip,
  ...rest
}: SlackStatusBadgeProps) {
  const { currentOrg, currentEnv } = useAuthContext();

  const slackChannelName = useSlackChannel(slackChannel)?.name;

  const inactiveColor = useColorModeValue("gray.400", "gray.600");
  const activeColor = useColorModeValue("brand.500", "brand.400");

  if (!currentOrg || !currentEnv) {
    return null;
  }

  return (
    <Tooltip label={tooltip} openDelay={300} hasArrow>
      <Button
        as={Link}
        fontWeight="medium"
        size="sm"
        to={
          currentOrg.integrations.slack
            ? settingsLink
            : GlobalSettingsUrl(currentEnv, "org-integrations")
        }
        variant="ghost"
        {...rest}
      >
        <Box mr={2}>
          {currentOrg.integrations.slack ? (
            <SlackLogo width="15px" />
          ) : (
            <Box color={inactiveColor}>
              <RiSlackFill size="19px" />
            </Box>
          )}
        </Box>
        <HStack color={!active ? inactiveColor : undefined}>
          <Text>{slackChannelName || "Not configured"}</Text>
          {label && (
            <>
              <Divider alignSelf="stretch" h="auto" orientation="vertical" />
              <Text color={active ? activeColor : undefined}>
                {label} {active ? "on" : "off"}
              </Text>
            </>
          )}
        </HStack>
      </Button>
    </Tooltip>
  );
}
