import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import {
  PostToSlackNowArgsType,
  SlackChannel,
} from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { translateError } from "@/common/utils/errorHandling";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

type Notification = Omit<PostToSlackNowArgsType, "channelId">;

export default function useSlackPostNotificationMutation(
  slackChannel: SlackChannel | null | undefined,
  notification: Notification,
) {
  const { currentOrg } = useAuthContext();
  const toast = useToast();
  const errorToast = useErrorToast();

  return useMutation({
    mutationFn: () =>
      api.post<"/orgs/:orgId/slack/post-now">(
        `/orgs/${currentOrg?.id}/slack/post-now`,
        { ...notification, channelId: slackChannel!.id },
        {
          timeout: 30000,
        },
      ),

    retry: 0,
    mutationKey: integrationQueryKeys.slackPostNotification(
      currentOrg?.id,
      slackChannel,
      notification,
    ),

    onSuccess: () => {
      segmentAnalytics.track("Posted To Slack Now", {
        reportType: notification.reportType,
      });

      toast({
        title: "Posted to Slack!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },

    onError: (error: any) => {
      const { title, description } = translateError(
        error,
        `Failed to post to Slack`,
      );

      errorToast({
        title,
        description,
        duration: 5000,
      });
    },
  });
}
