import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { translateError } from "@/common/utils/errorHandling";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useGithubDeleteMutation() {
  const { currentOrg } = useAuthContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorToast = useErrorToast();

  return useMutation({
    mutationFn: () =>
      api.delete<"/orgs/:orgId/github/connection">(
        `/orgs/${currentOrg?.id}/github/connection`,
      ),
    retry: 1,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationQueryKeys.githubConnection(currentOrg?.id),
      });
      queryClient.invalidateQueries({
        queryKey: commonQueryKeys.bootstrap,
      });

      toast({
        title: "Github integration removed",
        description: "Github integration removed successfully",
        status: "success",
      });
    },
    onError: (error) => {
      const { title, description } = translateError(
        error,
        `Failed to remove Github integration`,
      );

      errorToast({
        title,
        description,
        duration: 5000,
      });
    },
  });
}
