import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import snakeCase from "lodash/snakeCase";
import upperFirst from "lodash/upperFirst";
import { z } from "zod";

export const KeyFormats = [
  "custom",
  "pascalCase",
  "camelCase",
  "snakeCaseUpper",
  "snakeCaseLower",
  "kebabCaseUpper",
  "kebabCaseLower",
] as const;

export const KeyFormatSchema = z.enum(KeyFormats);
export type KeyFormat = z.infer<typeof KeyFormatSchema>;

type KeyFormatPattern = {
  label: string;
  description: string;
  transform: (key: string) => string;
  validate: (key: string) => boolean;
};

export const KeyFormatPatterns: Record<KeyFormat, KeyFormatPattern> = {
  custom: {
    label: "Unenforced",
    description: "No restrictions on feature key naming convention.",
    transform: (key) => key?.trim(),
    validate: (key) => /^[\p{L}\p{N}\p{P}\p{S}\p{Z}]+$/u.test(key),
  },
  pascalCase: {
    label: "PascalCase",
    description:
      "Keys do not contain spaces or special characters; component words are capitalized.",
    transform: (key) => upperFirst(camelCase(key)),
    validate: (key) => /^[\p{Lu}][\p{L}\p{N}]*$/u.test(key),
  },
  camelCase: {
    label: "camelCase",
    description:
      "Keys do not contain spaces or special characters; start with a lowercase letter; and each following word is capitalized.",
    transform: (key) => camelCase(key),
    validate: (key) => /^[\p{Ll}][\p{L}\p{N}]*$/u.test(key),
  },
  snakeCaseUpper: {
    label: "SNAKE_CASE_UPPER",
    description:
      "Keys do not contain spaces or special characters; components words are separated by underscores; and each word is in upper case.",
    transform: (key) => snakeCase(key).toUpperCase(),
    validate: (key) =>
      /^[\p{Lu}][\p{Lu}\p{N}]*(?:_[\p{Lu}\p{N}]+)*$/u.test(key),
  },
  snakeCaseLower: {
    label: "snake_case_lower",
    description:
      "Keys do not contain spaces or special characters; components words are separated by underscores; and each word is in lower case.",
    transform: (key) => snakeCase(key).toLowerCase(),
    validate: (key) =>
      /^[\p{Ll}][\p{Ll}\p{N}]*(?:_[\p{Ll}\p{N}]+)*$/u.test(key),
  },
  kebabCaseUpper: {
    label: "KEBAB-CASE-UPPER",
    description:
      "Keys do not contain spaces or special characters; components words are separated by hyphens; and each word is in upper case.",
    transform: (key) => kebabCase(key).toUpperCase(),
    validate: (key) =>
      /^[\p{Lu}][\p{Lu}\p{N}]*(?:-[\p{Lu}\p{N}]+)*$/u.test(key),
  },
  kebabCaseLower: {
    label: "kebab-case-lower",
    description:
      "Keys do not contain spaces or special characters; components words are separated by hyphens; and each word is in lower case.",
    transform: (key) => kebabCase(key).toLowerCase(),
    validate: (key) =>
      /^[\p{Ll}][\p{Ll}\p{N}]*(?:-[\p{Ll}\p{N}]+)*$/u.test(key),
  },
};
