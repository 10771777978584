import { forwardRef, ReactNode, useCallback, useMemo } from "react";
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
  useFormControl,
} from "@chakra-ui/react";

import { SegmentWithCountsDTO } from "@bucketco/shared/segmentAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { MenuArrow } from "@/common/components/MenuArrow";
import MenuDescription from "@/common/components/MenuDescription";
import { useSegmentsWithCounts } from "@/company/data/useSegments";

export type SegmentSupport = "supported" | "unsupported" | "hidden";

type SegmentGroups = {
  supported: SegmentWithCountsDTO[];
  unsupported: SegmentWithCountsDTO[];
};

type SegmentWithPieChartProps = {
  segment: SegmentWithCountsDTO;
  color?: string;
  showPieChart?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function SegmentWithPieChart({
  segment,
  color,
  showPieChart,
  Icon,
}: SegmentWithPieChartProps) {
  return (
    <HStack color={color}>
      {Icon && <Icon height={16} width={16} />}
      {showPieChart && (
        <SimplePieChart
          color={color}
          value={getFraction(segment.segmentCount, segment.allCount) * 100}
        />
      )}
      <Text color={color}>{segment.name}</Text>
      {segment.deletedAt !== null && <Text color="dimmed">(archived)</Text>}
    </HStack>
  );
}

export type SegmentPickerProps = Omit<ButtonProps, "value" | "onChange"> & {
  placement?: MenuProps["placement"];
  size?: ButtonProps["size"];
  placeholder?: string;
  menuDescription?: string | React.ReactNode;
  multiselectLimit?: number;
  includeAll?: boolean;
  canClear?: boolean;
  selectedColor?: string;
  showPieChart?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  filterFn?: (segment: SegmentWithCountsDTO) => SegmentSupport;
  unsupportedReason?: ReactNode;
  showArrow?: boolean;
  onClear?: () => void;
} & (
    | {
        multiselect?: false;
        value?: string | null;
        onChange?: (value: string | null) => void;
      }
    | {
        multiselect: true;
        value?: string[] | null;
        onChange?: (value: string[] | null) => void;
      }
  );

function partitionSegmentsBySupport(
  segments: SegmentWithCountsDTO[],
  filter?: (segment: SegmentWithCountsDTO) => SegmentSupport,
): SegmentGroups {
  if (!filter) {
    return {
      supported: segments,
      unsupported: [],
    };
  }

  return segments.reduce<SegmentGroups>(
    (acc, segment) => {
      const status = filter(segment);
      if (status === "supported") {
        acc.supported.push(segment);
      } else if (status === "unsupported") {
        acc.unsupported.push(segment);
      }
      // 'hidden' segments are filtered out
      return acc;
    },
    { supported: [], unsupported: [] },
  );
}

export const SegmentPicker = forwardRef(
  (
    {
      id,
      value,
      size = "sm",
      placeholder = "No segment selected",
      menuDescription = "Select segment:",
      multiselect = false,
      multiselectLimit = 5,
      includeAll = true,
      canClear = false,
      selectedColor,
      showPieChart = false,
      Icon,
      filterFn,
      unsupportedReason,
      showArrow = false,
      onChange,
      onClear,
      ...rest
    }: SegmentPickerProps,
    ref,
  ) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);

    const { data: segments = [], isLoading: isLoadingSegments } =
      useSegmentsWithCounts({
        includeDeleted: true,
      });

    const valueArr = useMemo(
      () => (Array.isArray(value) ? value : value ? [value] : []),
      [value],
    );

    const selectedSegments = useMemo(
      () => segments.filter((s) => valueArr.includes(s.id)),
      [segments, valueArr],
    );

    const filteredSegments = useMemo(
      () =>
        segments
          .filter((s) => {
            // Remove all-segment if it's not allowed
            if (s.isAllSegment && !includeAll) {
              return false;
            }

            // We still show deleted segments if they are returned from backend
            // or if they are selected
            return s.deletedAt === null || valueArr.includes(s.id);
          })
          .sort((a, b) => {
            // First, prioritize 'isAllSegment'
            if (a.isAllSegment) return -1;
            if (b.isAllSegment) return 1;

            // Then, prioritize 'system' segments
            if (a.system && !b.system) return -1;
            if (!a.system && b.system) return 1;

            // Finally, compare by name
            return a.name.localeCompare(b.name);
          }),
      [segments, includeAll, valueArr],
    );

    const { supported: supportedSegments, unsupported: unsupportedSegments } =
      useMemo(
        () => partitionSegmentsBySupport(filteredSegments, filterFn),
        [filteredSegments, filterFn],
      );

    const clearButtonCallback = useCallback(() => {
      onChange?.(null);
      onClear?.();
    }, [onClear, onChange]);

    return (
      <Menu autoSelect={false} closeOnSelect={!multiselect} id={id}>
        <ButtonGroup isAttached>
          <MenuButton
            {...formControlProps}
            ref={ref}
            as={Button}
            color={valueArr.length === 0 ? "dimmed" : undefined}
            data-testid="rule-field"
            isDisabled={formControlProps.disabled}
            isLoading={isLoadingSegments}
            loadingText="Loading..."
            placeholder={placeholder}
            rightIcon={
              <Box fontSize="xl" mr={-2}>
                <RiArrowDownSLine />
              </Box>
            }
            size={size}
            variant="input"
            {...rest}
          >
            {selectedSegments.length > 1 && multiselect ? (
              // Multiple segments selected
              <HStack color={selectedColor}>
                {Icon && <Icon height={16} width={16} />}
                <Text>{valueArr.length} segments</Text>
              </HStack>
            ) : selectedSegments.length >= 1 ? (
              // Single segment selected or/and no multiselect
              <SegmentWithPieChart
                segment={selectedSegments[0]}
                showPieChart={showPieChart}
              />
            ) : (
              // No segment selected
              <Text color="dimmed">{placeholder}</Text>
            )}
          </MenuButton>
          {selectedSegments.length > 0 && canClear && (
            <IconButton
              aria-label="Clear selection"
              icon={<RiCloseLine />}
              isDisabled={rest.isDisabled}
              marginInlineStart={"-1px"}
              size={size}
              variant="outline"
              onClick={clearButtonCallback}
            />
          )}
        </ButtonGroup>
        <MenuList
          data-testid="rule-field-attributes-list"
          maxH="sm"
          maxW={280}
          overflow="auto"
          // workaround for to avoid horizontal scrollbar when positioned to the right of the screen
          rootProps={{ style: { transform: "scale(0)" } }}
        >
          {showArrow && <MenuArrow />}
          <MenuDescription>{menuDescription}</MenuDescription>
          <MenuDivider my={0} />
          <MenuOptionGroup
            type={multiselect ? "checkbox" : "radio"}
            value={multiselect ? valueArr : valueArr[0] ?? ""}
            onChange={onChange as any}
          >
            {supportedSegments.map((segment) => (
              <MenuItemOption
                key={segment.id}
                isDisabled={
                  multiselect &&
                  !!valueArr &&
                  valueArr.length >= multiselectLimit &&
                  !valueArr?.includes(segment.id)
                }
                value={segment.id}
              >
                <SegmentWithPieChart
                  segment={segment}
                  showPieChart={showPieChart}
                />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          {unsupportedSegments.length > 0 && (
            <>
              <MenuDivider />
              <MenuGroup>
                <HStack
                  color="dimmed"
                  fontSize="sm"
                  fontWeight="medium"
                  mx={4}
                  my={2}
                >
                  <Text>Unsupported</Text>
                  {unsupportedReason && (
                    <InfoIconTooltip text={unsupportedReason} />
                  )}
                </HStack>
                {unsupportedSegments.map((segment) => (
                  <MenuItemOption
                    key={segment.id}
                    value={segment.id}
                    isDisabled
                  >
                    <SegmentWithPieChart
                      segment={segment}
                      showPieChart={showPieChart}
                    />
                  </MenuItemOption>
                ))}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
    );
  },
);
SegmentPicker.displayName = "SegmentPicker";
