import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useGithubSaveRepoMutation() {
  const { currentOrg } = useAuthContext();
  const orgId = currentOrg?.id;
  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (repo: { repository: string }) =>
      api.post<"/orgs/:orgId/github/connection">(
        `/orgs/${orgId}/github/connection`,
        repo,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationQueryKeys.githubConnection(orgId),
      });
      toast({
        title: "Repository saved!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: (error: any) => {
      errorToast({
        title: "Failed to save repository",
        description: error.message,
        duration: 5000,
      });
    },
  });
}
