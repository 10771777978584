import React, { useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { RiAddLine } from "react-icons/ri";
import { useParams } from "react-router";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import uniq from "lodash/uniq";

import { useFeature } from "@bucketco/react-sdk";
import {
  featureConfigToUpdate,
  FeatureConfigVariant,
  UpdateFeatureConfig,
  UpdateFeatureConfigSchema,
} from "@bucketco/shared/featureConfigAPI";

import RemoteConfigIcon from "@/common/assets/remote-config-icon.svg?react";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import EmptyState from "@/common/components/EmptyState";
import { EnvironmentPicker } from "@/common/components/EnvironmentPicker";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import FormTextArea from "@/common/components/Form/FormTextArea";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { useEnvironmentsData } from "@/environment/data/useEnvironmentsData";
import { FeatureConfigModal } from "@/feature/components/FeatureConfigModal";
import { useFeatureConfigData } from "@/feature/data/useFeatureConfigData";
import { useFeatureConfigUpdateMutation } from "@/feature/data/useFeatureConfigUpdateMutation";

import { FeatureConfig } from "./FeatureConfig";

export const FeatureConfigs = () => {
  const { envId: envIdParam, featureId } = useParams();
  const [envId, setEnvId] = useSearchParam("environment", {
    fallback: envIdParam!,
  });

  const errorToast = useErrorToast();
  const configDialog = useDisclosure();
  const unsavedChangesDialog = useDisclosure();
  const confirmChangesDialog = useDisclosure();
  const [selectedVariant, setSelectedVariant] = useState<{
    variant: FeatureConfigVariant;
    index: number;
  } | null>(null);
  const [pendingEnvId, setPendingEnvId] = useState<string | null>(null);
  const { requestFeedback } = useFeature("dynamic-configs");

  const {
    data: configData,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useFeatureConfigData(featureId);
  const {
    data: environments = [],
    isLoading: isEnvironmentsLoading,
    isError: isEnvironmentError,
  } = useEnvironmentsData();

  const [expectedVersionIds, defaultRuleIndexes] = useMemo(() => {
    return [
      configData ? Object.values(configData).map((config) => config.id) : [],
      configData
        ? uniq(
            Object.values(configData)
              .flatMap(({ variants }) =>
                variants.findIndex(
                  ({ rule: { default: isDefault } }) => isDefault,
                ),
              )
              .filter((index) => index > -1),
          )
        : [],
    ];
  }, [configData]);

  const createConfigVersionsMutation = useFeatureConfigUpdateMutation(
    featureId!,
    expectedVersionIds.length ? (expectedVersionIds as [string]) : undefined,
  );

  const currentConfig = useMemo(
    () =>
      configData
        ? featureConfigToUpdate(configData)
        : {
            changeDescription: "",
            variants: [],
          },
    [configData],
  );

  const form = useForm<UpdateFeatureConfig>({
    resolver: zodResolver(UpdateFeatureConfigSchema),
    values: currentConfig,
  });

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: "variants",
  });

  const variants = useWatch({
    control: form.control,
    name: "variants",
  });

  const { selectedUsers, selectedCompanies, selectedSegments } = useMemo(() => {
    const rules = variants
      .map((v) => v.environmentRules[envId!])
      .filter(Boolean);
    return {
      selectedUsers: new Set(rules.flatMap((r) => r.userIds)),
      selectedCompanies: new Set(rules.flatMap((r) => r.companyIds)),
      selectedSegments: new Set(rules.flatMap((r) => r.segmentIds)),
    };
  }, [envId, variants]);

  const handleConfigSubmit = (variant: FeatureConfigVariant) => {
    if (selectedVariant) {
      // Update existing variant
      update(selectedVariant.index, {
        ...variants[selectedVariant.index],
        variant,
      });
    } else {
      // Add new variant
      append({
        environmentRules: Object.fromEntries(
          environments.map(({ id }) => [
            id,
            {
              segmentIds: [],
              companyIds: [],
              userIds: [],
              default: fields.length === 0, // Set first variant as default
            },
          ]),
        ),
        variant,
      });
    }
    configDialog.onClose();
    setSelectedVariant(null);
  };

  const handleDelete = (index: number) => {
    if (fields.length > 1 && defaultRuleIndexes.includes(index)) {
      errorToast({
        description:
          "This config value is set as default in one or more environments. Please set a different value as default before deleting this one.",
        title: "Cannot delete value",
        duration: 5000,
      });
      return;
    }

    remove(index);
  };

  const handleSetDefault = (index: number) => {
    variants.forEach((variant, i) => {
      update(i, {
        ...variant,
        environmentRules: {
          ...variant.environmentRules,
          [envId!]: {
            ...variant.environmentRules[envId!],
            default: i === index,
          },
        },
      });
    });
  };

  const handleEnvironmentChange = (newEnvId: string | null) => {
    if (form.formState.isDirty) {
      setPendingEnvId(newEnvId);
      unsavedChangesDialog.onOpen();
    } else {
      form.reset(currentConfig);
      setEnvId(newEnvId);
    }
  };

  const handleSaveAndSwitchEnvironment = async () => {
    await onSaveClick();
    unsavedChangesDialog.onClose();
  };

  const handleDiscardAndSwitchEnvironment = () => {
    form.reset(currentConfig);
    setEnvId(pendingEnvId);
    setPendingEnvId(null);
    unsavedChangesDialog.onClose();
  };

  const onSaveClick = form.handleSubmit(() => {
    confirmChangesDialog.onOpen();
  });

  const onConfirmClick = useFormMutationSubmitHandler(
    form,
    createConfigVersionsMutation,
    (data) => {
      if (pendingEnvId) {
        setEnvId(pendingEnvId);
        setPendingEnvId(null);
      }
      form.reset(featureConfigToUpdate(data));
      confirmChangesDialog.onClose();
    },
    {
      prepareVariables: (values) => ({
        ...values,
        variants: values.variants.map((config) => ({
          ...config,
          variant: {
            ...config.variant,
            // Payload should be null if empty string
            payload:
              typeof config.variant.payload === "string"
                ? config.variant.payload.trim() || null
                : config.variant.payload,
          },
          environmentRules: {
            // Send only the rules for the current environment
            [envId!]: {
              ...config.environmentRules[envId!],
            },
          },
        })),
      }),
      successToast: "Feature remote config updated successfully",
      errorToast: "Failed to update feature remote config",
    },
  );

  return (
    <FormProvider {...form}>
      {isConfigLoading || isEnvironmentsLoading ? (
        <Center flexGrow={1}>
          <LoadingSpinner />
        </Center>
      ) : isConfigError || isEnvironmentError ? (
        <Alert status="error">
          <AlertIcon />
          Failed to load remote configurations, please reload the page.
        </Alert>
      ) : !fields.length && !form.formState.isDirty ? (
        <Center flexGrow={1}>
          <EmptyState
            action={
              <Button
                onClick={() => {
                  setSelectedVariant(null);
                  configDialog.onOpen();
                }}
              >
                Create config value
              </Button>
            }
            description={
              <Text>
                Serve arbitrary configuration to customers and easily change it
                without re-deploying.
              </Text>
            }
            flexGrow={1}
            icon={<RemoteConfigIcon height="48px" width="48px" />}
            maxW="md"
            title="Start using remote config"
          />
        </Center>
      ) : (
        <Flex as="form" direction="column" onSubmit={onSaveClick}>
          <Flex align="center" justify="space-between" mb={4}>
            <HStack>
              <Text fontWeight="medium">Config values</Text>
              <InfoIconTooltip
                _icon={{ verticalAlign: "top", height: "100%" }}
                text="Config values are evaluated in order of priority: individual users first, then companies, and finally segments."
              />
            </HStack>
            <HStack>
              <Button
                colorScheme="brand"
                variant="ghost"
                onClick={(e) => {
                  e.preventDefault();
                  requestFeedback({
                    title: "How do you like remote configs?",
                    position: {
                      type: "POPOVER",
                      anchor: e.currentTarget,
                    },
                    openWithCommentVisible: true,
                  });
                }}
              >
                Give feedback
              </Button>
              <EnvironmentPicker
                placement="bottom-end"
                value={envId}
                usePortal
                onChange={handleEnvironmentChange}
              />
            </HStack>
          </Flex>
          <Flex align="flex-start" direction="column" gap={2}>
            {fields.map((field, index) => (
              <FeatureConfig
                key={field.id}
                baseName={`variants.${index}`}
                isOnlyConfig={fields.length === 1}
                selectedCompanies={selectedCompanies}
                selectedSegments={selectedSegments}
                selectedUsers={selectedUsers}
                onDelete={() => handleDelete(index)}
                onEdit={() => {
                  const variant = form.getValues(`variants.${index}.variant`);
                  setSelectedVariant({ variant, index });
                  configDialog.onOpen();
                }}
                onSetDefault={() => handleSetDefault(index)}
              />
            ))}

            <Button
              color="dimmed"
              leftIcon={<RiAddLine />}
              variant="ghost"
              onClick={() => {
                setSelectedVariant(null);
                configDialog.onOpen();
              }}
            >
              Add config value
            </Button>

            <FormRootError />

            <ButtonGroup>
              <FormSubmitLegacy
                isLoading={createConfigVersionsMutation.isPending}
              />
              <FormReset />
            </ButtonGroup>
          </Flex>
        </Flex>
      )}

      <FeatureConfigModal
        {...configDialog}
        key={selectedVariant?.index}
        existingKeys={fields
          .map((field) => field.variant.key)
          .filter((key) => key !== selectedVariant?.variant.key)}
        variant={selectedVariant?.variant}
        onClose={() => {
          configDialog.onClose();
          setSelectedVariant(null);
        }}
        onSubmit={handleConfigSubmit}
      />

      <ConfirmationDialog
        {...unsavedChangesDialog}
        cancelLabel="Discard"
        closeLabel="Cancel"
        confirmLabel="Save"
        description={
          <Text>
            You have unsaved changes. Would you like to save or discard them
            before switching environment?
          </Text>
        }
        title="Unsaved Changes"
        showCloseAsButton
        onCancel={handleDiscardAndSwitchEnvironment}
        onConfirm={handleSaveAndSwitchEnvironment}
      />

      <ConfirmationDialog
        {...confirmChangesDialog}
        _contentProps={{ maxW: "2xl" }}
        description={
          <VStack spacing={4} w="full">
            <FormTextArea
              name="changeDescription"
              placeholder="Add a description of the changes..."
            />
            <FormRootError />
          </VStack>
        }
        isLoading={createConfigVersionsMutation.isPending}
        title={<Text whiteSpace="nowrap">Confirm remote config changes</Text>}
        onCancel={() => {
          form.resetField("changeDescription");
          confirmChangesDialog.onClose();
        }}
        onConfirm={onConfirmClick}
      />
    </FormProvider>
  );
};
