import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export default function useSlackChannels() {
  const { currentOrg } = useAuthContext();

  return useQuery({
    queryKey: integrationQueryKeys.slackChannels(currentOrg?.id),

    queryFn: async () => {
      const res = await api.get<`/orgs/:orgId/slack/channels`>(
        `/orgs/${currentOrg?.id}/slack/channels`,
      );
      return res.data;
    },

    retry: 2,

    select: (channels) =>
      (channels || []).map(({ id, name }) => ({
        id,
        name: `#${name}`,
      })),
  });
}
