import { Colors, extendTheme } from "@chakra-ui/react";
import { GlobalStyles, StyleFunctionProps } from "@chakra-ui/theme-tools";

import components from "@/theme/components";
import { focusShadow, focusStyle } from "@/theme/helpers";

const styles: GlobalStyles = {
  global: (_props: StyleFunctionProps) => ({
    body: {
      // TODO: temp hack for keyboard focus visibility (see shadows below)
      "*:focus-visible": focusStyle,
      fontWeight: "430",
      bg: "appSidebarBackground",
      fontSize: "md",
      overflowY: "scroll",
    },
    img: {
      maxWidth: "inherit",
    },

    "#hubspot-messages-iframe-container": {
      // Avoid applying color-scheme: dark; on Hubspot iframe,
      // which would cause its background to to opaque white in Safari
      colorScheme: "light",

      // Hide Hubspot when closed
      opacity: 0,
      pointerEvents: "none",
    },

    "#hubspot-messages-iframe-container:has(> .hs-shadow-container.active)": {
      // Show Hubspot when opened
      opacity: 1,
      pointerEvents: "auto",
    },
  }),
};

const fonts = {
  heading: "'Geist Variable', system-ui, Open Sans, sans-serif",
  body: "'Geist Variable', system-ui, Open Sans, sans-serif",
  code: "'Geist Mono Variable', Menlo, Monaco, Consolas, Droid Sans Mono, Courier New, monospace",
};

const fontWeights = {
  normal: 430,
};

const colors: Colors = {
  gray: {
    25: "hsl(232, 10%, 97%)",
    50: "hsl(232, 10%, 96%)",
    75: "hsl(232, 10%, 94%)",
    100: "hsl(232, 10%, 92%)",
    150: "hsl(232, 10%, 89%)",
    200: "hsl(232, 10%, 86%)",
    250: "hsl(232, 10%, 83%)",
    300: "hsl(232, 10%, 80%)",
    350: "hsl(232, 10%, 76%)",
    400: "hsl(232, 10%, 69%)",
    450: "hsl(232, 10%, 60%)",
    500: "hsl(232, 10%, 52%)",
    550: "hsl(232, 10%, 44%)",
    600: "hsl(232, 10%, 35%)",
    650: "hsl(232, 10%, 27%)",
    700: "hsl(232, 10%, 18%)",
    750: "hsl(232, 10%, 15%)",
    800: "#1e1f24",
    825: "hsl(232, 10%, 12%)",
    850: "hsl(232, 10%, 11%)",
    900: "hsl(232, 10%, 9%)",
    950: "hsl(232, 10%, 7%)",
  },
  brand: {
    50: "#F1F1FE",
    75: "#E7E7FE",
    100: "#DAD7FE",
    200: "#C1BDFD",
    300: "#A39DFC",
    400: "#847CFB",
    500: "#655BFA",
    600: "#5149C8",
    700: "#413BA0",
    800: "#302B78",
    900: "#221F56",
  },
  blue: {
    900: "#1e3a8a",
    800: "#1e40af",
    700: "#1d4ed8",
    600: "#2563eb",
    500: "#3b82f6",
    400: "#60a5fa",
    300: "#93c5fd",
    200: "#bfdbfe",
    100: "#dbeafe",
    50: "#eff6ff",
  },
  yellow: {
    50: "#fefce8",
    100: "#fef9c3",
    200: "#fef08a",
    300: "#fde047",
    400: "#facc15",
    500: "#eab308",
    600: "#ca8a04",
    700: "#a16207",
    800: "#854d0e",
    900: "#713f12",
  },
  orange: {
    50: "#FFF3DC",
  },
};

// make large font sizes slightly smaller than default
const fontSizes = {
  xs: "0.75rem", // 12px
  sm: "0.8125rem", // 13px
  md: "0.875rem", // 14px
  lg: "1rem",
  xl: "1.125rem",
  "2xl": "1.25rem",
  "3xl": "1.6rem",
  "4xl": "2rem",
};

// TODO: struggling with getting mouse vs. keyboard focus outlines to work
// for now, just disable them entirely
const shadows = {
  outline: null,
  focus: focusShadow,
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.01)",
};

/**
 * @see [https://chakra-ui.com/docs/styled-system/features/semantic-tokens](Semantic tokens)
 */
const semanticTokens = {
  colors: {
    dimmed: {
      default: "gray.500",
      _dark: "gray.500",
    },
    dimmedDisabled: {
      default: "gray.300",
      _dark: "gray.600",
    },
    appBackground: {
      default: "white",
      _dark: "gray.900",
    },
    appSidebarBackground: {
      default: "gray.50",
      _dark: "gray.950",
    },
    appBackdrop: {
      default: "gray.25",
      _dark: "gray.900",
    },
    appBorder: {
      default: "gray.100",
      _dark: "gray.750",
    },
    popoverBackground: {
      default: "white",
      _dark: "gray.850",
    },
    popoverBorder: {
      default: "gray.100",
      _dark: "gray.750",
    },
    inputPlaceholder: {
      default: "gray.400",
      _dark: "gray.600",
    },
  },
  sizes: {
    compactForm: {
      default: "md",
    },
    mediumForm: {
      default: "xl",
    },
    wideForm: {
      default: "3xl",
    },
  },
};

const breakpoints = {
  base: "0em", // 0px
  sm: "30em", // ~480px
  md: "48em", // ~768px
  lg: "62em", // ~992px
  xl: "80em", // ~1280px
  "2xl": "96em", // ~1536px
  "3xl": "110em", // ~1760px
};

const radii = {
  none: "0",
  sm: "0.1875rem", // "0.125rem",
  base: "0.3125rem", // "0.25rem"
  md: "0.4375rem", // "0.375rem",
  lg: "0.5625rem", //"0.5rem",
  xl: "0.8125rem", // "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  full: "9999px",
};

const space = {
  18: "4.5rem",
  22: "5.5rem",
};

const sizes = {
  18: "4.5rem",
  22: "5.5rem",
};

export const theme = extendTheme({
  styles,
  fonts,
  colors,
  fontSizes,
  fontWeights,
  shadows,
  components,
  semanticTokens,
  breakpoints,
  radii,
  space,
  sizes,
});
