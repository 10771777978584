import { forwardRef, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { CompaniesSearchBody, CompanyName } from "@bucketco/shared/companyAPI";
import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";

import AddInBulkButton from "@/common/components/Autocomplete/AddInBulk";
import AutocompleteSelect from "@/common/components/Autocomplete/AutocompleteSelect";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useDebounce } from "@/common/hooks/useDebounce";
import {
  AutocompleteImplementationProps,
  AutocompleteValueIdProps,
} from "@/common/utils/autocomplete";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";
import { useCompaniesSearch } from "@/company/data/useCompaniesSearch";
import { useCompanyData } from "@/company/data/useCompanyData";

export type CompanyAutocompleteSelectProps = {
  onAddInBulk?: (ids: string[]) => void;
} & AutocompleteImplementationProps<CompanyName> &
  AutocompleteValueIdProps<CompanyName> & {
    query?: Omit<CompaniesSearchBody, "envId">;
  };

export const CompanyAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  CompanyAutocompleteSelectProps
>(function CompanyAutocompleteSelect(
  {
    valueAsId,
    value: valueProp,
    query,
    placeholder = "Acme Inc.",
    canClear,
    onChange,
    onAddInBulk,
    ...autocompleteProps
  }: CompanyAutocompleteSelectProps,
  ref,
) {
  const { appId, envId } = useCurrentEnv();
  const valueId = useMemo(
    () => (valueAsId ? valueProp : valueProp?.id),
    [valueAsId, valueProp],
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const queryClient = useQueryClient();
  const debouncedSearchValue = useDebounce(searchValue, SEARCH_DEBOUNCE_MS);
  const { data: valueEnhancement, isLoading: isLoadingEnhancement } =
    useCompanyData(valueId ?? undefined);

  const {
    data: companyData = [],
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useCompaniesSearch(debouncedSearchValue, query);

  const internalValue = useMemo(() => {
    return valueId === valueEnhancement?.id ? valueEnhancement : undefined;
  }, [valueId, valueEnhancement]);

  const companySuggestions = useMemo(() => {
    if (internalValue) {
      return [
        internalValue,
        ...companyData.filter((c) => c.id !== internalValue?.id),
      ];
    }
    return companyData;
  }, [companyData, internalValue]);

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      footer={
        onAddInBulk && (
          <AddInBulkButton
            description="Enter company IDs separated by commas, space or line breaks"
            textareaPlaceholder="company1 company2 company3"
            title="Add companies in bulk"
            onAddInBulk={onAddInBulk}
          />
        )
      }
      hasMore={hasNextPage}
      isLoading={isLoading || isLoadingEnhancement}
      isLoadingMore={isFetchingNextPage}
      isLoadingSuggestions={isFetching}
      itemKeyProperty="id"
      itemRenderFn={(c, search) => (
        <CompanyDisplay company={c} py={0.5} search={search} showId />
      )}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={companySuggestions}
      value={internalValue}
      onChange={(newValue: CompanyName | null) => {
        setSearchValue("");
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }
        // If a value is selected, pre-seed the single company query for immediate display
        queryClient.setQueryData<CompanyName>(
          companyQueryKeys.single(appId, envId, newValue.id),
          (oldValue) => ({
            ...oldValue,
            ...newValue,
          }),
        );
        // If valueAsId is true, return only the id of the selected company
        if (valueAsId) {
          onChange?.(newValue?.id ?? null);
        } else {
          onChange?.(newValue ?? null);
        }
      }}
      onClear={() => {
        if (canClear) onChange?.(null);
      }}
      onLoadMore={fetchNextPage}
      onSearchInput={setSearchValue}
      {...autocompleteProps}
    />
  );
});

CompanyAutocompleteSelect.displayName = "CompanyAutocompleteSelect";
