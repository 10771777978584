import { RiCheckLine, RiErrorWarningLine } from "react-icons/ri";
import {
  Badge,
  Button,
  FormLabel,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { SlackConnection } from "@bucketco/shared/slackConnectionAPI";

import { compactFormattedDate } from "@/common/utils/datetime";
import { InitiateIntegrationButton } from "@/integration/components/InitiateIntegrationButton";
import { useSlackConnection } from "@/integration/data/useSlackConnection";
import { useSlackDeleteMutation } from "@/integration/data/useSlackDeleteMutation";

function Connected({ slackConnection }: { slackConnection: SlackConnection }) {
  const { mutate, isPending: mutationIsLoading } = useSlackDeleteMutation();

  return (
    <VStack align="flex-start" spacing={3}>
      <HStack>
        <Badge colorScheme="green" size="md" variant="subtle">
          <HStack spacing={1}>
            <RiCheckLine size={16} />
            <Text fontSize="sm">Connected</Text>
          </HStack>
        </Badge>
        <Text color="dimmed" fontSize="sm">
          to {slackConnection.team} since{" "}
          {compactFormattedDate(slackConnection.createdAt)}
        </Text>
      </HStack>
      <Button
        isLoading={mutationIsLoading}
        size="sm"
        variant="outline"
        onClick={() => mutate()}
      >
        Disconnect
      </Button>
    </VStack>
  );
}

function NotConnected() {
  return (
    <VStack align="flex-start" spacing={3}>
      <Badge colorScheme="orange" size="md" variant="subtle">
        <HStack spacing={1}>
          <RiErrorWarningLine size={16} />
          <Text fontSize="sm">Not connected</Text>
        </HStack>
      </Badge>
      <Text color="dimmed" fontSize="sm" maxWidth="lg">
        Get notifications whenever a feature&apos;s access/stage changes and
        when end-users submits feature feedback. Subscribe to weekly feature
        view reports.
      </Text>
      <InitiateIntegrationButton integration="slack" />
    </VStack>
  );
}

export default function SlackIntegrationForm() {
  const { data: slackConnection, isLoading } = useSlackConnection();

  return (
    <VStack align="flex-start">
      <FormLabel pb={1}>Slack integration</FormLabel>
      {isLoading ? (
        <Spinner size="sm" />
      ) : slackConnection ? (
        <Connected slackConnection={slackConnection} />
      ) : (
        <NotConnected />
      )}
    </VStack>
  );
}
