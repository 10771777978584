import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { CompaniesSearchBody } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

export function useCompaniesData(
  body?: Omit<CompaniesSearchBody, "envId">,
  enabled = true,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.list(appId, envId, body),
    queryFn: () =>
      api
        .post<`/apps/:appId/companies/search`>(
          `/apps/${appId}/companies/search`,
          body,
          {
            params: {
              envId: envId!,
            },
          },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    placeholderData: keepPreviousData,
  });
}
