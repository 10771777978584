import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { RiCheckLine, RiErrorWarningFill, RiTimeLine } from "react-icons/ri";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import repeat from "lodash/repeat";
import startCase from "lodash/startCase";
import { z } from "zod";

import {
  CsvExportIntervalType,
  DataExportCadenceSchema,
  DataExportCadenceType,
  DataExportConfiguration,
  DataExportHistoryItem,
  DataExportTypeType,
  PatchDataExportConfigurationSchema,
} from "@bucketco/shared/exportAPI";
import { S3ProviderSchema } from "@bucketco/shared/s3ConnectionAPI";
import { DataExportUrl } from "@bucketco/shared/urls";

import {
  AccessPlanRestricted,
  AccessPlanRestrictedComponentProps,
} from "@/app/components/AccessPlanRestricted";
import { appQueryKeys } from "@/app/data/appQueryKeys";
import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/Form/FormInput";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSelect from "@/common/components/Form/FormSelect";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import FormSwitch from "@/common/components/Form/FormSwitch";
import SimpleSelect from "@/common/components/SimpleSelect";
import TimestampCell from "@/common/components/TimestampCell";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import dayjs from "@/common/utils/dayjs";
import { API_URL } from "@/common/utils/env";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import useDataExportConfigurationData from "../data/useDataExportConfigurationData";
import useDataExportHistoryData from "../data/useDataExportHistoryData";

const exportTime = dayjs.duration(1, "hour");

const cadenceOptions = DataExportCadenceSchema._def.values.map((cadence) => ({
  value: cadence,
  label: startCase(cadence),
}));
const s3ProviderOptions = S3ProviderSchema._def.values.map((provider) => ({
  value: provider,
  label: startCase(provider),
}));

function deconstructS3Url(url: string) {
  try {
    const ob = new URL(url);
    const split = ob.hostname.split(".");

    let path = ob.pathname;
    if (path.startsWith("/")) {
      path = path.slice(1);
    }
    if (path.endsWith("/")) {
      path = path.slice(0, -1);
    }

    if (split.length === 5 && split[3] == "amazonaws" && split[4] == "com") {
      return {
        bucket: split[0],
        region: split[2],
        key: path,
      };
    }
  } catch {
    // noop
  }

  return undefined;
}

function reconstructS3Url(
  bucket: string,
  region: string,
  key: string | undefined,
) {
  return `https://${encodeURIComponent(bucket)}.s3.${encodeURIComponent(
    region,
  )}.amazonaws.com/${encodeURIComponent(key || "")}`;
}

const formSchema = z
  .object({
    enabled: z.boolean(),
    new: z.boolean(),
    awsUri: z
      .string()
      .url()
      .or(z.literal("").transform(() => undefined))
      .or(z.undefined()),
    data: PatchDataExportConfigurationSchema,
  })
  .superRefine((data, ctx) => {
    if (data.new && !data.data.s3Connection?.accessKeyId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Access key and secret access key are required for new configurations",
        path: ["data", "s3Connection", "accessKeyId"],
      });
    }
    if (
      data.data.s3Connection?.provider === "AWS" &&
      (!data.awsUri?.length || !deconstructS3Url(data.awsUri))
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Missing or invalid S3 URL",
        path: ["awsUri"],
      });
    }
  });

const LastExportStatusDescription = ({
  state,
  lastRunDate,
  nextRunDate,
  message,
}: {
  state: "none" | "success" | "failed";
  lastRunDate?: string;
  nextRunDate: string;
  message: string;
}) => {
  let color = "dimmed";
  if (state === "success") {
    color = "green";
  } else if (state === "failed") {
    color = "red";
  }

  return (
    <Text color={color} fontSize="md">
      {message}
      {lastRunDate && (
        <>
          {" "}
          <TimestampCell
            capitalize={false}
            color={color}
            fontSize="md"
            highlight={false}
            leftAlign={true}
            value={lastRunDate}
          />
        </>
      )}
      <Text as="span" color="dimmed" fontSize="md">
        , next run{" "}
      </Text>
      <TimestampCell
        capitalize={false}
        color="dimmed"
        fontSize="md"
        highlight={false}
        leftAlign={true}
        value={nextRunDate}
      />
    </Text>
  );
};

const LastExportStatus = ({
  type,
  cadence,
}: {
  type: DataExportTypeType;
  cadence: DataExportCadenceType;
}) => {
  const toast = useToast();
  const {
    data: dataExportHistory,
    isError: error,
    isLoading: loading,
  } = useDataExportHistoryData(type);

  useEffect(() => {
    if (error) {
      toast({
        title: "Failed to load data export history",
        description: "Please try again later",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const lastExport = useMemo(() => dataExportHistory?.[0], [dataExportHistory]);

  const nextRunAt = useMemo(() => {
    const unit = cadence === "weekly" ? "week" : "day";

    return dayjs(lastExport?.createdAt)
      .add(1, unit)
      .startOf(unit)
      .add(exportTime)
      .toISOString();
  }, [lastExport, cadence]);

  if (loading) {
    return undefined;
  }

  return (
    <>
      <HStack>
        <LastExportIcon lastExport={lastExport} />
        {!lastExport && (
          <LastExportStatusDescription
            message="Export never ran before"
            nextRunDate={nextRunAt}
            state="none"
          />
        )}
        {lastExport?.success && (
          <LastExportStatusDescription
            lastRunDate={lastExport.createdAt}
            message="Last export ran"
            nextRunDate={nextRunAt}
            state="success"
          />
        )}
        {lastExport && !lastExport.success && (
          <LastExportStatusDescription
            lastRunDate={lastExport.createdAt}
            message="Last export failed"
            nextRunDate={nextRunAt}
            state="failed"
          />
        )}
      </HStack>
      {lastExport && !lastExport.success && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{lastExport.message}</AlertDescription>
        </Alert>
      )}
    </>
  );
};

const LastExportIcon = ({
  lastExport,
}: {
  lastExport: DataExportHistoryItem | undefined;
}) => {
  if (!lastExport) {
    return <RiTimeLine color="gray" />;
  } else if (lastExport.success) {
    return <RiCheckLine color="green" />;
  } else {
    return <RiErrorWarningFill color="red" />;
  }
};

const maskedPlaceholder = repeat("•", 20);

function AppAutomaticDataExportSettings({
  hasAccess,
  type,
}: AccessPlanRestrictedComponentProps & {
  type: DataExportTypeType;
}) {
  const { appId, envId } = useCurrentEnv();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    data: dataExportConfiguration,
    isLoading: loadingConfig,
    isError: errorLoadingConfig,
  } = useDataExportConfigurationData(type);

  const getDefaults = function (config?: DataExportConfiguration | null) {
    const res = {
      enabled: !!config,
      new: !config,
      awsUri: config
        ? reconstructS3Url(
            config.s3Connection.credentials.bucket,
            config.s3Connection.credentials.region,
            config.s3Connection.credentials.key,
          )
        : "",
      data: {
        cadence: config?.cadence ?? "weekly",
        s3Connection: {
          bucket: config?.s3Connection?.credentials.bucket || "",
          region: config?.s3Connection?.credentials.region || "",
          key: config?.s3Connection?.credentials.key || "",
          endpoint: config?.s3Connection?.credentials.endpoint || "",
          provider: config?.s3Connection?.credentials.provider || "AWS",
          accessKeyId: "",
          secretAccessKey: "",
        },
      },
    };

    return res;
  };

  const { form, handleSubmit } = useApiForm(
    async (formValues) => {
      if (!formValues.enabled) {
        return api
          .delete<"/apps/:appId/export/:type">(
            `/apps/${appId}/export/${type}`,
            { params: { envId: envId! } },
          )
          .then(() => null);
      }

      return api
        .patch<"/apps/:appId/export/:type">(
          `/apps/${appId}/export/${type}`,
          formValues.data,
          { params: { envId: envId! } },
        )
        .then((res) => res.data);
    },
    formSchema,
    {
      onSuccess: (config) => {
        segmentAnalytics.track("Automatic Data Export Settings Updated", {
          enabled: !!config,
          type: type,
          cadence: config?.cadence,
          provider: config?.s3Connection.credentials.provider,
        });

        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({
          queryKey: appQueryKeys.dataExportConfiguration(appId, envId, type),
        });
        form.reset(getDefaults(config));
      },
    },
    {
      defaultValues: getDefaults(dataExportConfiguration),
      mode: "onChange",
      reValidateMode: "onChange",
    },
  );

  useEffect(() => {
    if (errorLoadingConfig) {
      toast({
        title: "Failed to load data export settings",
        description: "Please try again later",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [errorLoadingConfig, toast]);

  useEffect(() => {
    if (dataExportConfiguration) {
      form.reset(getDefaults(dataExportConfiguration));
    }
  }, [form, dataExportConfiguration]);

  const enabled = form.watch("enabled");
  const cadence = form.watch("data.cadence");
  const provider = form.watch("data.s3Connection.provider");
  const awsUri = form.watch("awsUri");

  useEffect(() => {
    if (awsUri && provider === "AWS") {
      const deconstructed = deconstructS3Url(awsUri);

      if (deconstructed) {
        form.setValue("data.s3Connection", {
          ...form.getValues().data.s3Connection,
          ...deconstructed,
        });
      }
    }
  }, [awsUri, provider, form]);

  const loading = loadingConfig;

  const exists = dataExportConfiguration;

  return (
    <Flex direction={"column"} gap="6" maxWidth="compactForm">
      <Flex direction={"column"} gap="4">
        <Heading as="h3" fontSize={"lg"}>
          Automatic export
        </Heading>

        <Text color="dimmed">
          Bucket can automatically upload the current CSV data file to S3-like
          systems.
        </Text>

        <Text color="dimmed">
          <Link
            href="https://docs.bucket.co/product-handbook/data-export#scheduled-export"
            target="_blank"
          >
            Learn more
          </Link>
        </Text>
        {loading ? (
          <Center height="100%">
            <Spinner size="sm" />
          </Center>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormProvider {...form}>
              <VStack align="flex-start" maxW="compactForm" spacing={4}>
                <FormSwitch
                  isDisabled={!hasAccess}
                  label="Enable automatic export"
                  name="enabled"
                />
                {enabled && <LastExportStatus cadence={cadence} type={type} />}

                <FormSelect
                  description={`Exports are typically scheduled to run ${
                    cadence == "daily" ? "daily" : "on mondays"
                  } at ${exportTime.format("HH:mm")} UTC.`}
                  isDisabled={!enabled}
                  label="Periodic schedule"
                  name={"data.cadence"}
                  options={cadenceOptions}
                />
                <FormSelect
                  description="Bucket works with any S3-compatible system like AWS S3, Google Cloud Storage, or Cloudflare R2."
                  isDisabled={!enabled}
                  label="S3 provider"
                  name={"data.s3Connection.provider"}
                  options={s3ProviderOptions}
                />
                {provider === "AWS" && (
                  <>
                    <FormInput
                      description="The URL of the bucket to upload to. Must already exist and must have list/write access."
                      isDisabled={!enabled}
                      label="S3 bucket"
                      name="awsUri"
                      placeholder="https://bucket.s3.region.amazonaws.com/key"
                    />
                    <Text color="dimmed">
                      <Link
                        href="https://docs.bucket.co/integrations/aws-s3"
                        target="_blank"
                      >
                        AWS S3 setup guide
                      </Link>
                    </Text>
                  </>
                )}
                {provider === "custom" && (
                  <FormInput
                    description="The endpoint of your S3-compatible system."
                    isDisabled={!enabled}
                    label="Endpoint"
                    name="data.s3Connection.endpoint"
                    placeholder="https://<ACCOUNT_ID>.eu.r2.cloudflarestorage.com"
                  />
                )}
                {provider !== "AWS" && (
                  <>
                    <FormInput
                      description="The name of the bucket to upload to. Must already exist and must have list/write access. Can only contain numbers, lower case letters, underscore, dash and dot characters."
                      isDisabled={!enabled}
                      label="S3 bucket"
                      name="data.s3Connection.bucket"
                      placeholder="bucket-name"
                    />
                    <FormInput
                      description="The prefix key to upload to. Can only contain numbers, letters, underscore, dash, slash and dot characters."
                      isDisabled={!enabled}
                      label="Prefix key (optional)"
                      name="data.s3Connection.key"
                      placeholder="data/exports"
                    />
                    <FormInput
                      description="The region of your S3-compatible system."
                      isDisabled={!enabled}
                      label="Region"
                      name="data.s3Connection.region"
                      placeholder="us-east-1"
                    />
                  </>
                )}
                <FormInput
                  autoComplete="off"
                  description="The access key of your S3-compatible system."
                  isDisabled={!enabled}
                  label="Access key"
                  name="data.s3Connection.accessKeyId"
                  placeholder={exists ? maskedPlaceholder : "access key"}
                />
                <FormInput
                  autoComplete="off"
                  description="The secret access key of your S3-compatible system."
                  isDisabled={!enabled}
                  label="Secret access key"
                  name="data.s3Connection.secretAccessKey"
                  placeholder={exists ? maskedPlaceholder : "secret access key"}
                />

                <FormRootError />
                <ButtonGroup>
                  <FormSubmitLegacy />
                  <FormReset />
                </ButtonGroup>
              </VStack>
            </FormProvider>
          </form>
        )}
      </Flex>
    </Flex>
  );
}

function ManualExport({
  hasAccess,
  track,
}: AccessPlanRestrictedComponentProps) {
  const { currentEnv, currentApp } = useAuthContext();
  const [interval, setInterval] = useState<CsvExportIntervalType>("current");

  return (
    <Flex direction={"column"} gap="4">
      <Heading as="h3" fontSize={"lg"}>
        Manual export
      </Heading>

      <Text color="dimmed">
        CSV data, which combines your companies with Bucket tracking activity,
        STARS-step, satisfaction scores, feedback counts etc.
      </Text>

      <Text color="dimmed">
        <Link
          href="https://docs.bucket.co/product-handbook/data-export"
          target="_blank"
        >
          Learn more
        </Link>
      </Text>

      <FormControl>
        <FormLabel>Historical data</FormLabel>
        <SimpleSelect
          isDisabled={!hasAccess}
          name="interval"
          options={[
            { label: "Just today's snapshot", value: "current" },
            { label: "30 days, daily granularity", value: "30d" },
            { label: "3 months, weekly granularity", value: "3m" },
            { label: "6 months, weekly granularity", value: "6m" },
          ]}
          value={interval}
          w="full"
          onChange={(value) => setInterval(value)}
        />
      </FormControl>

      <ButtonGroup spacing={2} variant="outline">
        <Button
          isDisabled={!hasAccess}
          onClick={async () => {
            track();
            location.assign(
              DataExportUrl(API_URL, currentApp!.id, currentEnv!.id, interval),
            );
          }}
        >
          Download export
        </Button>
        {/* <Button>Download user data</Button> */}
        {/* <Button>Download feedback data</Button> */}
      </ButtonGroup>
    </Flex>
  );
}

export default function AppDataExport() {
  useEffect(() => {
    segmentAnalytics.page("App Data Export");
  }, []);

  return (
    <Flex direction={"column"} gap="6" maxWidth="compactForm">
      <AccessPlanRestricted
        Component={ManualExport}
        featureKey="export-manual"
      />
      <Divider />
      <AccessPlanRestricted
        Component={(params) => (
          <AppAutomaticDataExportSettings {...params} type="companyFeatures" />
        )}
        featureKey="export-automatic"
      />
    </Flex>
  );
}
