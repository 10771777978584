import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useLinearConnection() {
  const { currentOrg } = useAuthContext();

  return useQuery({
    queryKey: integrationQueryKeys.linearConnection(currentOrg?.id),
    queryFn: () =>
      api
        .get<"/orgs/:orgId/linear/connection">(
          `/orgs/${currentOrg?.id}/linear/connection`,
        )
        .then((res) => res.data),
    enabled: Boolean(currentOrg),
  });
}
