import { useEffect } from "react";
import { Divider, VStack } from "@chakra-ui/react";

import { useFeature } from "@bucketco/react-sdk";

import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import GithubIntegrationForm from "@/integration/components/github/GithubIntegrationForm";
import LinearIntegrationForm from "@/integration/components/linear/LinearIntegrationForm";

import SlackIntegrationForm from "./slack/SlackIntegrationForm";

export default function Integrations() {
  const { isEnabled: linearEnabled } = useFeature("linear-integration");
  const { isEnabled: githubEnabled } = useFeature("github-integration");
  useEffect(() => {
    segmentAnalytics.page("Org Integration Settings");
  }, []);

  return (
    <VStack align="flex-start">
      <SlackIntegrationForm />
      {linearEnabled && (
        <>
          <Divider />
          <LinearIntegrationForm />
        </>
      )}
      {githubEnabled && (
        <>
          <Divider />
          <GithubIntegrationForm />
        </>
      )}
    </VStack>
  );
}
