import { RiArrowUpCircleLine } from "react-icons/ri";
import {
  Button,
  ButtonProps,
  Card,
  Grid,
  GridItem,
  HStack,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { FeatureKey, Features, useFeature } from "@bucketco/react-sdk";
import { SalesEmail } from "@bucketco/shared/urls";
import { KeysMatching } from "@bucketco/shared/utils/types";

import { useAuthContext } from "@/auth/contexts/authContext";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";

export interface AccessPlanRestrictedComponentProps {
  hasAccess: boolean;
  track: () => void;
}

type AccessPlanRestrictedFeatureConfigPayload = {
  upgradeMessage?: string;
  upgradeLink?: string;
};

type AccessPlanRestrictedFeatures = KeysMatching<
  Features,
  { config: { payload: AccessPlanRestrictedFeatureConfigPayload } }
>;

type AccessPlanRestrictedProps = {
  featureKey: AccessPlanRestrictedFeatures;
  Component: React.ComponentType<AccessPlanRestrictedComponentProps>;
};

export function AccessPlanRestricted({
  featureKey,
  Component,
}: AccessPlanRestrictedProps) {
  const { currentOrg } = useAuthContext();
  const textColor = useColorModeValue("brand.500", "brand.400");
  const {
    isEnabled,
    track,
    config: { key: minimumPlan, payload: planDetails },
  } = useFeature(featureKey);

  if (isEnabled) {
    return <Component hasAccess={true} track={track} />;
  }

  if (!minimumPlan) {
    return null;
  }

  const params = new URLSearchParams({
    subject: `Payment plan upgrade: ${currentOrg?.name}`,
    body: `Hi Bucket\n\nCan I get a quote on switching "${currentOrg?.name}" to the "${minimumPlan}"-plan?`,
  });

  return (
    <VStack gap={2}>
      <Grid
        alignItems={"center"}
        as={Card}
        columnGap={3}
        maxWidth={"md"}
        px={4}
        py={3}
        rowGap={1}
        templateColumns="min-content 1fr"
        variant="filled"
        width="100%"
      >
        <GridItem color={textColor}>
          <RiArrowUpCircleLine size={22} />
        </GridItem>

        <GridItem color={textColor}>
          <Text fontWeight={"semibold"}>
            Upgrade to{" "}
            <Link fontWeight={"bold"} href="https://bucket.co/pricing">
              {minimumPlan}
            </Link>{" "}
            to unlock this feature
          </Text>
        </GridItem>

        <GridItem colStart={2}>
          {planDetails?.upgradeMessage ||
            `Get access to this feature and more.`}{" "}
          <Link
            href={
              planDetails?.upgradeLink ||
              `mailto:${SalesEmail()}?${params.toString()}`
            }
          >
            Get in touch
          </Link>
        </GridItem>
      </Grid>
      <Component hasAccess={false} track={track} />
    </VStack>
  );
}

type AccessPlanRestrictedButtonProps = ButtonProps & {
  featureKey: FeatureKey;
};

export function AccessPlanRestrictedButton({
  featureKey,
  ...buttonProps
}: AccessPlanRestrictedButtonProps) {
  const {
    isEnabled,
    track,
    config: { key: minimumPlan },
  } = useFeature(featureKey);

  const color = useColorModeValue("brand.500", "brand.400");

  if (isEnabled) {
    return (
      <Button
        {...buttonProps}
        onClick={(e) => {
          track();
          buttonProps.onClick?.(e);
        }}
      />
    );
  }

  return (
    <>
      <HStack>
        <Button {...buttonProps} isDisabled={true} />
        {minimumPlan && (
          <InfoIconTooltip
            _icon={{ color }}
            icon={<RiArrowUpCircleLine size={16} />}
            text={`This feature is available on the ${minimumPlan} plan`}
          />
        )}
      </HStack>
    </>
  );
}
