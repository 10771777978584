import { Button, ButtonProps, Text } from "@chakra-ui/react";
import omit from "lodash/omit";

import {
  PostToSlackNowArgsType,
  SlackChannel,
} from "@bucketco/shared/slackConnectionAPI";

import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import useSlackPostNotificationMutation from "@/integration/data/useSlackPostNotificationMutation";
import useSlackChannel from "@/integration/hooks/useSlackChannel";

type PostToSlackNowProps = ButtonProps & {
  slackChannel: SlackChannel | null;
  notification: Omit<PostToSlackNowArgsType, "channelId">;
};

export default function SlackPostNotification({
  slackChannel,
  notification,
  ...props
}: PostToSlackNowProps) {
  const effectiveSlackChannel = useSlackChannel(slackChannel);
  const { mutate, isPending } = useSlackPostNotificationMutation(
    effectiveSlackChannel,
    notification,
  );

  if (!effectiveSlackChannel) {
    return (
      <Text color="gray.500" fontSize="sm">
        Not configured
      </Text>
    );
  }

  return (
    <Button
      isLoading={isPending}
      leftIcon={<SlackHashLogo height="14" />}
      size="2xs"
      variant="outline"
      onClick={() => mutate()}
      {...omit(props, [
        "reportType",
        "channelId",
        "featureId",
        "appId",
        "envId",
      ])}
    >
      <Text isTruncated>
        {isPending
          ? "Sending..."
          : `Send to ${effectiveSlackChannel?.name || "channel"}`}
      </Text>
    </Button>
  );
}
