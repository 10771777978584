import { useMemo } from "react";
import {
  keepPreviousData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import pick from "lodash/pick";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  CompaniesSearchBody,
  CompanyList,
  CompanyName,
} from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { companyQueryKeys } from "./companyQueryKeys";

export function useCompaniesSearch(
  search?: string,
  query: Omit<CompaniesSearchBody, "envId" | "idNameFilter"> = {},
  options?: Omit<
    UseInfiniteQueryOptions<
      CompanyList,
      AxiosError<ErrorResponse>,
      CompanyName[],
      CompanyList,
      QueryKey,
      number
    >,
    "queryKey" | "queryFn"
  >,
) {
  const { appId, envId } = useCurrentEnv();

  const body = useMemo<Omit<CompaniesSearchBody, "envId">>(() => {
    return {
      pageSize: 50,
      idNameFilter: search,
      ...query,
    };
  }, [query, search]);

  return useInfiniteQuery({
    queryKey: companyQueryKeys.list(appId, envId, body),
    queryFn: ({ pageParam }) =>
      api
        .post<"/apps/:appId/companies/search">(
          `/apps/${appId}/companies/search`,
          {
            ...body,
            pageIndex: pageParam,
          },
          {
            params: { envId: envId! },
          },
        )
        .then((res) => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageIndex + 1;
      const totalPages = Math.ceil(lastPage.totalCount / lastPage.pageSize);
      return nextPage < totalPages ? nextPage : undefined;
    },
    select: ({ pages }) =>
      pages.flatMap(({ data }) =>
        data.map((item) => pick(item, "id", "name", "avatarUrl")),
      ) ?? [],
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    ...options,
    enabled: !!appId && !!envId && options?.enabled !== false,
  });
}
