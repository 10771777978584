import { Divider, Flex } from "@chakra-ui/react";

import { useAuthContext } from "@/auth/contexts/authContext";
import { OnlyProductionEnvironment } from "@/environment/components/EnvironmentCallouts";
import { InitiateIntegrationButton } from "@/integration/components/InitiateIntegrationButton";

type Props = {
  productionOnly?: boolean;
  children?: React.ReactNode;
};

export default function SlackConnectionGuard({
  productionOnly = false,
  children,
}: Props) {
  const { currentEnv, currentOrg } = useAuthContext();

  if (productionOnly && !currentEnv?.isProduction) {
    <OnlyProductionEnvironment entity="slack reports" />;
  }

  return (
    <Flex direction="column" gap={4}>
      {!currentOrg?.integrations.slack && (
        <>
          <InitiateIntegrationButton integration="slack" />
          <Divider />
        </>
      )}
      {children}
    </Flex>
  );
}
