import { useEffect } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";

import {
  BucketProvider as BaseBucketProvider,
  useClient,
} from "@bucketco/react-sdk";

import { useAuthContext } from "@/auth/contexts/authContext";
import { getAllowTracking } from "@/common/utils/analytics/blockNonMembertracking";
import { API_URL, BUCKET_PUBLISHABLE_KEY } from "@/common/utils/env";

export function BucketProvider({ children }: { children: React.ReactNode }) {
  const { user, currentOrg } = useAuthContext();

  const userCtx = {
    id: user?.id,
    email: user?.email,
    name: user?.name,
  };

  const companyCtx = {
    id: currentOrg?.id,
    access_level: currentOrg?.accessLevel,
    created_at: currentOrg?.createdAt,
  };

  // skip rendering the Bucket component if the tracking key is not set
  // or we're not logged in/bootstrapping
  if (!BUCKET_PUBLISHABLE_KEY || !user?.id) {
    return children;
  }

  return (
    <BaseBucketProvider
      apiBaseUrl={`${API_URL}/ingest-proxy`}
      company={companyCtx}
      enableTracking={getAllowTracking()}
      loadingComponent={
        <Center h="100vh">
          <Spinner size="sm" />
        </Center>
      }
      publishableKey={BUCKET_PUBLISHABLE_KEY}
      staleWhileRevalidate={false}
      toolbar={user?.isAdmin}
      user={userCtx}
    >
      <DatadogIntegration />
      {children}
    </BaseBucketProvider>
  );
}

function DatadogIntegration() {
  const client = useClient();
  useEffect(() => {
    return client?.on("check", (check) => {
      datadogRum.addFeatureFlagEvaluation(check.key, check.value);
    });
  }, [client]);
  return null;
}
