import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RiCheckFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Heading,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { InviteDTO } from "@bucketco/shared/inviteAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { translateError } from "@/common/utils/errorHandling";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useInviteData } from "../data/useInviteData";
import { useOrgsData } from "../data/useOrgsData";

type AcceptInviteProps = {
  invite: InviteDTO;
  accept: () => Promise<boolean>;
};

function AcceptInvite({ invite, accept }: AcceptInviteProps) {
  const [loading, setLoading] = useState(false);
  return (
    <VStack spacing={6}>
      <Text textAlign="center">
        You&apos;ve been invited to join {invite.org.name} in Bucket!
      </Text>
      <HStack spacing={4}>
        <Button
          isLoading={loading}
          leftIcon={<RiCheckFill />}
          variant="solid"
          onClick={() => {
            setLoading(true);
            accept().then(setLoading);
          }}
        >
          Join {invite.org.name}
        </Button>
      </HStack>
    </VStack>
  );
}

export default function Invite() {
  const { user, setActiveOrg, currentOrg, signOut } = useAuthContext();
  const { orgId, inviteKey } = useParams();
  const navigate = useNavigate();

  const toast = useToast();
  const errorToast = useErrorToast();

  const {
    data: inviteData,
    isError: inviteError,
    isLoading: inviteLoading,
  } = useInviteData(orgId, inviteKey);
  const {
    data: orgsData,
    isError: orgsError,
    isLoading: orgsLoading,
  } = useOrgsData();

  const isError = inviteError || orgsError;
  const isLoading = inviteLoading || orgsLoading;

  useEffect(() => {
    if (isError) {
      errorToast({
        title: `Failed to accept invite`,
        description: "Invitation code is invalid or expired",
        duration: 5000,
      });

      if (currentOrg) {
        navigate("/");
      } else {
        void signOut();
      }
    }
  }, [isError, errorToast, navigate, signOut, currentOrg]);

  useEffect(() => {
    segmentAnalytics.track("Org Invite Opened");
  }, []);

  const invite = inviteData?.data;
  const loaded = !isLoading && !isError && orgId && invite && orgsData;

  if (loaded && orgsData.orgs.find(({ id }) => id === orgId)) {
    return (
      <>
        <Helmet>
          <title>Already a Member</title>
        </Helmet>
        <VStack spacing={6}>
          <Heading fontSize="2xl">
            You&apos;re already part of {invite.org.name}!
          </Heading>
          <Button
            variant="outline"
            onClick={() =>
              currentOrg?.id !== orgId
                ? setActiveOrg(orgId, { refresh: true })
                : navigate("/")
            }
          >
            Continue
          </Button>
        </VStack>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Accept Organization Invite</title>
      </Helmet>
      {loaded ? (
        <VStack spacing={6}>
          <Heading fontSize="2xl">Hi {user!.name},</Heading>
          <AcceptInvite
            accept={async () => {
              try {
                await api.post<"/invite/:inviteKey/accept">(
                  `/invite/${inviteKey}/accept`,
                );

                toast({
                  title: `You're now part of ${invite.org.name}!`,
                  status: "success",
                  duration: 1500,
                  onCloseComplete: () => setActiveOrg(orgId),
                });

                segmentAnalytics.track("Org Invite Accepted");
                return true;
              } catch (error) {
                const { title, description } = translateError(
                  error,
                  `Failed to accept invite from ${invite.org.name}`,
                );

                errorToast({
                  title,
                  description,
                  duration: 5000,
                });

                return false;
              }
            }}
            invite={invite}
          />
        </VStack>
      ) : (
        <Spinner size="sm" />
      )}
    </>
  );
}
