import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { SegmentDTO, SegmentWithCountsDTO } from "@bucketco/shared/segmentAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { segmentQueryKeys } from "@/company/data/segmentQueryKeys";

const cacheTime = 5 * 60 * 1000; // 5 minutes

type OptionsType<TData> = Omit<
  UseQueryOptions<SegmentWithCountsDTO[], AxiosError<ErrorResponse>, TData>,
  "queryKey" | "queryFn"
>;

export function useSegmentsWithCounts<TData = SegmentWithCountsDTO[]>(
  query?: { includeDeleted?: boolean },
  options: OptionsType<TData> = {},
) {
  const { appId, envId } = useCurrentEnv();

  const { enabled, select, ...otherOptions } = options;

  const combinedSelect = (segments: SegmentWithCountsDTO[]) => {
    const prefiltered = segments.filter(
      (segment) => !segment.deletedAt || query?.includeDeleted,
    );

    return select ? select(prefiltered) : (prefiltered as unknown as TData);
  };

  return useQuery<SegmentWithCountsDTO[], AxiosError<ErrorResponse>, TData>({
    queryKey: segmentQueryKeys.listWithCounts(appId, envId),
    queryFn: () =>
      api
        .get<"/apps/:appId/segments-with-counts">(
          `/apps/${appId}/segments-with-counts`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    select: combinedSelect,
    ...otherOptions,
    enabled: !!appId && !!envId && Boolean(enabled ?? true),
    staleTime: cacheTime,
    placeholderData: keepPreviousData,
  });
}

export function useSegment(segmentId?: string | null) {
  return useSegmentsWithCounts(undefined, {
    select(segments) {
      return segments.find((segment) => segment.id === segmentId);
    },
    enabled: !!segmentId,
  });
}

export function useSegments<TData = SegmentDTO[]>() {
  const { appId } = useCurrentEnv();

  return useQuery<SegmentDTO[], AxiosError<ErrorResponse>, TData>({
    queryKey: segmentQueryKeys.list(appId),
    queryFn: () =>
      api
        .get<"/apps/:appId/segments">(`/apps/${appId}/segments`)
        .then((res) => res.data),
    enabled: !!appId,
    staleTime: cacheTime,
    placeholderData: keepPreviousData,
  });
}
