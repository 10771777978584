import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { ColumnState } from "./types/columns";
import { APIEmptyResponse, APIResponse } from "./api";
import {
  CompaniesColumn,
  CompaniesColumnSchema,
  CompaniesSortBy,
  CompaniesSortBySchema,
} from "./companyAPI";
import { EnvironmentSelectionQueryType } from "./environmentAPI";
import { FilterGroupSchema, UIFilter, UIFilterSchema } from "./filter";

export interface SegmentDTO {
  id: string;
  name: string;
  createdAt: string;
  companyFilter: UIFilter;
  system: boolean;
  isAllSegment: boolean;
  columns: ColumnState<CompaniesColumn>[];
  columnSortKey?: CompaniesSortBy;
  columnSortDesc?: boolean;
  deletedAt: string | null;
}

export interface SegmentWithCountsDTO extends SegmentDTO {
  segmentCount: number;
  allCount: number;
  stateless: boolean;
}

export const CreateSegmentArgsSchema = z
  .object({
    name: nameSchema,
    companyFilter: UIFilterSchema,
    columns: z
      .array(
        z.object({
          id: CompaniesColumnSchema,
          shown: z.boolean(),
        }),
      )
      .optional(),
    columnSortKey: CompaniesSortBySchema.optional(),
    columnSortDesc: z.boolean().optional(),
  })
  .strict();

export const PatchSegmentArgsSchema = CreateSegmentArgsSchema.partial();

export type PatchSegmentArgsType = z.infer<typeof PatchSegmentArgsSchema>;

export const PatchAllSegmentArgsSchema = CreateSegmentArgsSchema.pick({
  columns: true,
  columnSortKey: true,
  columnSortDesc: true,
});

export type CreateSegmentArgsType = z.infer<typeof CreateSegmentArgsSchema>;

export const CreateSegmentFormSchema = z.object({
  name: nameSchema,
  companyFilter: FilterGroupSchema,
  columns: z
    .array(
      z.object({
        id: CompaniesColumnSchema,
        shown: z.boolean(),
      }),
    )
    .optional(),
});

export type CreateSegmentFormType = z.infer<typeof CreateSegmentFormSchema>;

export const UpdateSegmentFormSchema = CreateSegmentFormSchema.partial();

export const CreateOrUpdateSegmentFormSchema = z.discriminatedUnion("action", [
  CreateSegmentFormSchema.extend({
    action: z.literal("create"),
  }),
  UpdateSegmentFormSchema.extend({
    action: z.literal("update"),
  }),
]);

export type CreateOrUpdateSegmentFormType = z.infer<
  typeof CreateOrUpdateSegmentFormSchema
>;

export interface SegmentAPI {
  "/apps/:appId/segments": {
    GET: {
      response: APIResponse<SegmentDTO[]>;
      params: { appId: string };
    };
    POST: {
      body: CreateSegmentArgsType;
      response: APIResponse<SegmentDTO>;
      params: { appId: string };
    };
  };
  "/apps/:appId/segments/:segmentId": {
    PATCH: {
      body: PatchSegmentArgsType;
      response: APIResponse<SegmentDTO>;
      params: { appId: string; segmentId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; segmentId: string };
    };
  };
  "/apps/:appId/segments-with-counts": {
    GET: {
      response: APIResponse<SegmentWithCountsDTO[]>;
      params: { appId: string; envId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
}
