import { useEffect, useMemo, useState } from "react";
import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  Image,
} from "@chakra-ui/react";

import { useFeature } from "@bucketco/react-sdk";
import { skipImageProcessing } from "@bucketco/shared/imageAPI";

import { API_URL } from "@/common/utils/env";

const sizeMap = {
  "2xs": 5,
  xs: 6,
  sm: 8,
  md: 10,
  lg: 12,
  xl: 14,
  "2xl": 16,
  full: 20,
};

export type AvatarProps = Omit<ChakraAvatarProps, "size"> & {
  size?: keyof typeof sizeMap;
};

// Custom Avatar component to avoid Chakra Avatar's loading logic and bad re-rendering
export const Avatar = ({ name, src, size, ...rest }: AvatarProps) => {
  const [showImage, setShowImage] = useState(!!src);

  useEffect(() => {
    setShowImage(!!src);
  }, [src]);

  const { isEnabled } = useFeature("bytescale-image-processing");

  const processedSrc = useMemo(() => {
    if (!src || !isEnabled) return undefined;
    return !skipImageProcessing.some((domain) => src.startsWith(domain))
      ? `${API_URL}${src}`
      : src;
  }, [src, isEnabled]);

  return !!processedSrc && showImage ? (
    <Image
      alt={name}
      borderRadius="full"
      boxSize={sizeMap[size ?? "2xs"]}
      objectFit="contain"
      src={processedSrc}
      onError={() => setShowImage(false)}
      {...rest}
    />
  ) : (
    <ChakraAvatar name={name} size={size ?? "2xs"} {...rest} />
  );
};
