import {
  FeedbackPushDestination,
  FeedbackQuery,
  FeedbackTimelineQueryType,
} from "@bucketco/shared/feedbackAPI";

const qk = {
  list: (
    appId?: string,
    envId?: string,
    params?: Omit<FeedbackQuery, "envId">,
  ) =>
    [
      "apps",
      appId,
      "environment",
      envId,
      "feedbacks",
      ...(params ? [params] : []),
    ] as const,

  single: (appId?: string, envId?: string, feedbackId?: string) => [
    ...qk.list(appId, envId),
    feedbackId,
  ],

  timeline: (
    appId: string | undefined,
    envId: string | undefined,
    params: Omit<FeedbackTimelineQueryType, "envId">,
  ) =>
    [
      ...qk.list(appId, envId),
      "timeline",
      ...(params ? [params] : []),
    ] as const,

  push: (
    appId?: string,
    envId?: string,
    destination?: FeedbackPushDestination,
    feedbackId?: string,
  ) => [...qk.single(appId, envId, feedbackId), "push", destination],
};

export { qk as feedbackQueryKeys };
