import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { RiExternalLinkLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { ButtonGroup, Flex, HStack, Link, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { LinearTrackingEntitySchema } from "@bucketco/shared/linearConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";
import LinearConnectionGuard from "@/integration/components/linear/LinearConnectionGuard";
import { LinearTrackingEntityAutocompleteSelect } from "@/integration/components/linear/LinearTrackingEntityAutocompleteSelect";

export function SettingsLinear() {
  useEffect(() => {
    segmentAnalytics.page("Feature Linear Integration");
  }, []);

  return (
    <LinearConnectionGuard>
      <LinearForm />
    </LinearConnectionGuard>
  );
}

const FormSchema = z.object({
  trackingEntity: LinearTrackingEntitySchema.nullable(),
});

type FormType = z.infer<typeof FormSchema>;

export function LinearForm() {
  const { currentOrg } = useAuthContext();
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);
  const form = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: feature?.integrations.linear || {
      trackingEntity: null,
    },
  });

  const updateMutation = useFeatureUpdateMutation(featureId!);
  const submitHandler = useFormMutationSubmitHandler(
    form,
    updateMutation,
    (result) => {
      form.reset({
        trackingEntity: result.integrations.linear?.trackingEntity ?? null,
      });
    },
    {
      prepareVariables: ({ trackingEntity }) => ({
        id: featureId!,
        integrations: {
          slack: {},
          linear: trackingEntity ? { trackingEntity } : null,
        },
      }),
    },
  );

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        direction={"column"}
        gap={4}
        maxW="compactForm"
        onSubmit={submitHandler}
      >
        <FormLinearIntegration />
        <FormRootError />
        {currentOrg?.integrations.linear && (
          <ButtonGroup>
            <FormSubmitLegacy />
            <FormReset />
          </ButtonGroup>
        )}
      </Flex>
    </FormProvider>
  );
}

export default function FormLinearIntegration() {
  const { watch } = useFormContext<FormType>();
  const trackingEntity = watch("trackingEntity");
  const { currentOrg } = useAuthContext();

  return (
    <Flex direction="column" gap={2} maxW="compactForm" w="full">
      <ManagedFormControl
        isDisabled={!currentOrg?.integrations.linear}
        label="Linear issue/project associated with this feature"
        name="trackingEntity"
        render={({ field }) => (
          <LinearTrackingEntityAutocompleteSelect {...field} canClear />
        )}
        w={"xl"}
      />
      {!!trackingEntity?.url && (
        <Link
          color="dimmed"
          href={trackingEntity.url}
          target="_blank"
          textDecoration="none"
          width={"fit-content"}
        >
          <HStack>
            <Text fontSize="sm">Open {trackingEntity.type} in Linear</Text>
            <RiExternalLinkLine size={16} />
          </HStack>
        </Link>
      )}
    </Flex>
  );
}
