import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { z } from "zod";

import { booleanish } from "./schemas/booleanish";
import { nameSchema } from "./schemas/nameSchema";
import { Paginated } from "./types/Paginated";
import { APIEmptyResponse, APIResponse } from "./api";
import { AttributeFilter, AttributeFilterRuleSchema } from "./attributeFilter";
import {
  EnvironmentDTO,
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";
import { FeatureConfigVersion } from "./featureConfigAPI";
import { FeatureViewDTO } from "./featureViewAPI";
import { FlagRule } from "./flagAPI";
import { LinearTrackingEntitySchema } from "./linearConnectionAPI";
import { SegmentDTO } from "./segmentAPI";
import { SlackChannelSchema } from "./slackConnectionAPI";
import { StageNameDTO } from "./stageAPI";

dayjs.extend(utc);

export const FeatureSourceSchema = z.enum(["event", "attribute"]);
export type FeatureSourceType = z.infer<typeof FeatureSourceSchema>;

export const SlackIntegrationSchema = z
  .object({
    slackChannel: SlackChannelSchema.nullish(),
    feedbackNotification: z.boolean().default(false),
    firstDataNotification: z.boolean().default(false),
    targetingRulesChangeNotification: z.boolean().default(true),
  })
  .strict();

export const LinearIntegrationSchema = z
  .object({
    trackingEntity: LinearTrackingEntitySchema,
  })
  .strict();

export type SlackIntegrationType = z.infer<typeof SlackIntegrationSchema>;
export type LinearIntegrationType = z.infer<typeof LinearIntegrationSchema>;

export const EventSelectorSchema = z.object({
  name: nameSchema.max(255),
  filter: z.array(AttributeFilterRuleSchema),
  system: z.boolean().optional(),
});

export type EventSelectorType = z.infer<typeof EventSelectorSchema>;

export const FeatureFeatureViewRelationsSchema = z.array(z.string().length(14));

export const FeatureFeedbackCampaignSchema = z.union([
  z.object({
    enabled: z.literal(false),
    question: z.string().max(255).optional(),
  }),
  z.object({
    enabled: z.literal(true),
    question: z
      .string()
      .min(1, { message: "Prompt question must be set" })
      .max(255),
  }),
]);

export const CreateFeatureArgsSchema = z
  .object({
    source: FeatureSourceSchema,
    name: nameSchema,
    description: z
      .string()
      .max(8192)
      .nullish()
      .transform((v) => (v === "" ? null : v)),
    key: z.string(),
    secret: z.boolean().optional(),
    stageId: z.string().length(14).optional(),
    parentFeatureId: z.string().nullish(),
    integrations: z
      .object({
        slack: SlackIntegrationSchema.partial(),
        linear: LinearIntegrationSchema.nullish(),
      })
      .strict()
      .optional(),
    featureViews: FeatureFeatureViewRelationsSchema.optional(),
    feedbackCampaign: FeatureFeedbackCampaignSchema.optional(),
    customEventSelectors: z.array(EventSelectorSchema).optional(),
    usingItAttributeFilter: z.array(AttributeFilterRuleSchema).optional(),
  })
  .strict();

const AdoptionEvaluationStrategySchema = z.enum([
  "legacy",
  "eventCount",
  "frequency",
]);

export type AdoptionEvaluationStrategy = z.infer<
  typeof AdoptionEvaluationStrategySchema
>;

export const EventPatchFeatureArgsSchema = z.object({
  adoptionEvaluationStrategy: AdoptionEvaluationStrategySchema.optional(),
  adoptionStrategyEventCountMinEventCount: z.number().min(1).optional(),
  adoptionStrategyFrequencyMinDaysCount: z.number().min(1).optional(),
  adoptionWindowSizeInDays: z.number().min(1).optional(),
});

export type CreateFeatureArgsType = z.infer<typeof CreateFeatureArgsSchema>;

export const PatchFeatureArgsSchema = CreateFeatureArgsSchema.omit({
  key: true,
})
  .merge(EventPatchFeatureArgsSchema)
  .partial();
export type PatchFeatureArgsType = z.infer<typeof PatchFeatureArgsSchema>;

export const FunnelStepList = [
  "company",
  "segment",
  "tried",
  "adopted",
  "retained",
] as const;

export type FunnelStep = (typeof FunnelStepList)[number];

export const FunnelStateList = [
  "never",
  "tried",
  "retained",
  "churned",
] as const;

export type FunnelState = (typeof FunnelStateList)[number];

export const FunnelStateToStepMap: Record<FunnelState, FunnelStep> = {
  never: "company",
  tried: "tried",
  churned: "adopted",
  retained: "retained",
};

export const FunnelStepToStateMap: Record<FunnelStep, FunnelState | undefined> =
  {
    company: "never",
    segment: "never",
    tried: "tried",
    adopted: "churned",
    retained: "retained",
  };

export type FeatureGoal = {
  widgetId: string;
  featureId: string;
  metric: FeatureMetric;
  currentValue: number;
  threshold: number;
};

export type FeatureDetail = {
  id: string;
  key: string;
  parentFeatureId: string | null;
  flagId: string | null;
  name: string;
  description: string | null;
  integrations: {
    slack: SlackIntegrationType;
    linear: LinearIntegrationType | null;
  };
  segment?: SegmentDTO;
  featureViews: FeatureViewDTO[];
  configVersion: string | null;

  secret: boolean;

  source: FeatureSourceType;
  eventSelectors: EventSelectorType[];
  customEventSelectors: EventSelectorType[];
  adoptionEvaluationStrategy: AdoptionEvaluationStrategy;
  adoptionStrategyEventCountMinEventCount: number;
  adoptionStrategyFrequencyMinDaysCount: number;
  adoptionWindowSizeInDays: number;
  usingItAttributeFilter: AttributeFilter;
  autoFeedbackSurveysEnabled: boolean;
};

export type FeatureListItem = {
  id: string;
  parentFeatureId: string | null;
  name: string;
  key: string;
  sortKey: string | null;
  source: FeatureSourceType;
  stage: StageNameDTO | null;
  createdAt: string;
  autoFeedbackSurveysEnabled: boolean;
  goals: FeatureGoal[];
  productionRolloutTargetingRules: FlagRule[];
} & FeatureListItemMetricsPart &
  FeatureListItemRolloutStatusPart &
  FeatureListItemRemoteConfigsPart;

export type FeatureListItemMetricsPart = {
  processingStatus: ProcessingStatus;
  feedbackCountAll: number | null;
  feedbackCountScored: number | null;
  feedbackCountRetained: number | null;
  feedbackCountRetainedAndScored: number | null;
  eventCount: number | null;
  triedCompaniesFraction: number | null;
  adoptedCompaniesFraction: number | null;
  retainedCompaniesFraction: number | null;
  satisfiedCompaniesFraction: number | null;
  adoptionRate: number | null;
  retentionRate: number | null;
  satisfactionRate: number | null;
  averageFrequency: number | null;
  currentMetrics?: STARSMetrics & { averageFrequency: number };
  rates?: STARSRates;
};

export const NullFeatureListItemMetricsPart: FeatureListItemMetricsPart = {
  processingStatus: "no-data",
  feedbackCountAll: null,
  feedbackCountScored: null,
  feedbackCountRetained: null,
  feedbackCountRetainedAndScored: null,
  eventCount: null,
  triedCompaniesFraction: null,
  adoptedCompaniesFraction: null,
  retainedCompaniesFraction: null,
  satisfiedCompaniesFraction: null,
  adoptionRate: null,
  retentionRate: null,
  satisfactionRate: null,
  averageFrequency: null,
};

export type FeatureListItemRolloutStatusPart = {
  rolloutEnvironment: Pick<
    EnvironmentDTO,
    "id" | "name" | "isProduction" | "order"
  > | null;
  productionEstimatedTargetAudience: number | null;
  latestCheck: string | null;
  latestUsage: string | null;
};

export const NullFeatureListItemRolloutStatusPart: FeatureListItemRolloutStatusPart =
  {
    rolloutEnvironment: null,
    productionEstimatedTargetAudience: null,
    latestCheck: null,
    latestUsage: null,
  };

export type FeatureListItemRemoteConfigsPart = {
  remoteConfigs: FeatureConfigVersion[];
};

export const NullFeatureListItemRemoteConfigsPart: FeatureListItemRemoteConfigsPart =
  {
    remoteConfigs: [],
  };

export type FeatureList = Paginated<
  FeatureListItem,
  FeatureListSortBy,
  { sortType: SortType; viewId: string | undefined }
>;

export interface STARSRates {
  /** Fraction of tried it companies that have adopted the feature. [0;1] */
  adoptionRate: number;
  /** Fraction of adopted companies that are retained for the feature. [0;1] */
  retentionRate: number;
  /** Satisfaction rate expressed as a fraction of the retained companies. [0;1] */
  satisfactionRate: number;
}

/**
 * Count of companies that have completed specific STARS funnel steps.
 *
 * All counts are inclusive, meaning that a step count includes a company
 * despite the company possibly having completed more advanced funnel steps.
 */
export interface STARSMetrics {
  /** The total number of companies tracked for the current App */
  company: number;
  /** The number of companies in the target segment of a Feature */
  segment: number;
  /** The number of companies that have met the "Tried it" criteria, including companies that have later advanced in the funnel */
  tried: number;
  /** The number of companies that have met the "Adopted" criteria, including companies that have later advanced in the funnel */
  adopted: number;
  /** The number of companies that have met the "Retained" criteria, including companies that have later advanced in the funnel */
  retained: number;
  /** The number of companies that have met the "Satisfied" criteria */
  satisfied: number;
}

export type FeatureMetricsCurrent = {
  metrics: STARSMetrics & {
    unsatisfied: number;
    averageFrequency: number;
    frequencyHistogram: Record<number, number>;
  };
  rates: STARSRates;
  feedbackCountAll: number;
  feedbackCountScored: number;
  feedbackCountRetained: number;
  feedbackCountRetainedAndScored: number;
};

export const FeatureMetricList = [
  "tried",
  "adopted",
  "retained",
  "satisfied",
  "adoptionRate",
  "retentionRate",
  "satisfactionRate",
  "triedCount",
  "adoptedCount",
  "retainedCount",
  "satisfiedCount",
  "averageFrequency",
  "feedbackCount",
] as const;
export type FeatureMetric = (typeof FeatureMetricList)[number];
export const FeatureMetricSchema = z.enum(FeatureMetricList);

export interface FeatureMetricsHistoricalTimeseries {
  timeseries: {
    epoch: number;
    value: number | null;
  }[];
}

export const FrequencyMap = {
  0: "quarterly",
  1: "monthly",
  2: "weekly",
  3: "daily",
} as const;

export type FrequencyNumber = number | null;

export type FrequencyText =
  | (typeof FrequencyMap)[keyof typeof FrequencyMap]
  | "N/A";

export function frequencyRoundNumber(avgFrequencyNumber: number) {
  return Math.round(avgFrequencyNumber) as keyof typeof FrequencyMap;
}

export function frequencyNumberToText(
  avgFrequencyNumber: number | null,
): FrequencyText {
  if (avgFrequencyNumber === null) {
    return "N/A";
  }
  const intNum = frequencyRoundNumber(avgFrequencyNumber);
  return FrequencyMap[intNum];
}

export const SubsegmentQuerySchema = z.object({
  subsegment: z.string().length(14).optional(),
});

export const UseTargetingRulesSchema = z
  .object({
    useTargetingRules: booleanish.default(false),
  })
  .strict();

export type UseTargetingRulesType = z.input<typeof UseTargetingRulesSchema>;

export type SubsegmentQueryType = z.infer<typeof SubsegmentQuerySchema>;

export const CompanyCurrentMetricsQuerySchema =
  EnvironmentSelectionQuerySchema.merge(UseTargetingRulesSchema)
    .merge(SubsegmentQuerySchema)
    .strict();

export type CompanyCurrentMetricsQueryType = z.input<
  typeof CompanyCurrentMetricsQuerySchema
>;

export const FeatureMetricsHistoricalQuerySchema =
  EnvironmentSelectionQuerySchema.extend({
    startDate: z
      .string()
      .datetime()
      .default(dayjs.utc().startOf("day").subtract(30, "days").toISOString()),
    endDate: z
      .string()
      .datetime()
      .default(dayjs.utc().startOf("day").toISOString()),
    metric: FeatureMetricSchema,
  })
    .merge(UseTargetingRulesSchema)
    .merge(SubsegmentQuerySchema)
    .strict();
export type FeatureMetricsHistoricalQuery = z.input<
  typeof FeatureMetricsHistoricalQuerySchema
>;

export const FeatureMetricsRequiringColums = [
  "averageFrequency",
  "triedCompaniesFraction",
  "adoptedCompaniesFraction",
  "retainedCompaniesFraction",
  "satisfiedCompaniesFraction",
  "adoptionRate",
  "retentionRate",
  "satisfactionRate",
  "feedbackCountAll",
] as const;
export const RolloutStatusRequiringColumns = [
  "productionEstimatedTargetAudience",
  "rolloutEnvironment",
  "latestCheck",
  "latestUsage",
] as const;
export const GoalsRequiringColumns = ["goals"] as const;

export const TargetAudienceRequiringSortColumns = [
  "productionEstimatedTargetAudience",
  "rolloutEnvironment",
  "productionRolloutTargetingRules",
] as const;

export const FeatureListSortByColumns = [
  ...[
    "name",
    "key",
    "stage",
    "source",
    "autoFeedbackSurveysEnabled",
    "createdAt",
    "productionRolloutTargetingRules",
  ],
  ...FeatureMetricsRequiringColums,
  ...RolloutStatusRequiringColumns,
  ...GoalsRequiringColumns,
] as const;
export const FeatureListSortBySchema = z.enum(FeatureListSortByColumns);
export type FeatureListSortBy = z.infer<typeof FeatureListSortBySchema>;

export const FeatureListColumnSchema = z.enum(FeatureListSortByColumns);
export type FeatureListColumn = z.infer<typeof FeatureListColumnSchema>;

export const sortTypeSchema = z.enum(["flat", "hierarchical"]);
export type SortType = z.infer<typeof sortTypeSchema>;

export const FeatureListQuerySchema = z
  .object({
    view: z.string().optional(),
    sortBy: FeatureListSortBySchema.default("createdAt"),
    sortOrder: z.enum(["asc", "desc"]).default("desc"),
    sortType: sortTypeSchema.default("flat"),
    includeFeatureMetrics: booleanish.default(false),
    includeRolloutStatus: booleanish.default(false),
    includeGoals: booleanish.default(false),
    includeProductionEstimatedTargetAudience: booleanish.default(false),
    includeRemoteConfigs: booleanish.default(false),
  })
  .merge(SubsegmentQuerySchema)
  .merge(EnvironmentSelectionQuerySchema.partial())
  .merge(UseTargetingRulesSchema)
  .strict()
  .refine(
    (v) => {
      const envRequired =
        v.includeFeatureMetrics || v.includeRolloutStatus || v.includeGoals;
      return !envRequired || v.envId;
    },
    {
      message:
        "`envId` is required when including metrics, rollout status or goals",
      path: ["envId"],
    },
  );

export type FeatureListQuery = z.input<typeof FeatureListQuerySchema>;

export type FeatureName = {
  id: string;
  name: string;
  key: string;
  source: FeatureSourceType;
  parentFeatureId: string | null;
};

export const FeatureNamesQuerySchema = z
  .object({
    viewId: z.string().optional(),
    eventIdentifier: z.string().optional(),
  })
  .merge(SubsegmentQuerySchema);

export type FeatureNamesQueryType = z.infer<typeof FeatureNamesQuerySchema>;

export interface TrackingHealth {
  firstSeen: string | null;
  lastSeen: string | null;
  associatedCount1Week: number | null;
  notAssociatedCount1Week: number | null;
}

export type ProcessingStatus = "bootstrapping" | "no-data" | "has-data";

export type FeatureRolloutStatus = {
  environment: Pick<EnvironmentDTO, "id" | "name" | "isProduction" | "order">;
  targetingRules: FlagRule[];
  latestCheck: string | null;
  latestUsage: string | null;
  latestFeedback: string | null;
};

export type FeatureRolloutStatusResponse = {
  stage: StageNameDTO | null;
  statuses: FeatureRolloutStatus[];
};

export const DEFAULT_FEATURE_ADOPTION_SETTINGS = {
  adoptionEvaluationStrategy: "frequency",
  adoptionWindowSizeInDays: 28,
  adoptionStrategyEventCountMinEventCount: 5,
  adoptionStrategyFrequencyMinDaysCount: 5,
};

export interface FeatureAPI {
  "/apps/:appId/features": {
    GET: {
      response: APIResponse<FeatureList>;
      params: {
        appId: string;
      };
      query: FeatureListQuery;
    };
    POST: {
      body: CreateFeatureArgsType;
      response: APIResponse<{
        feature: FeatureDetail;
      }>;
      params: { appId: string };
      query: EnvironmentSelectionQueryType; // TODO ENV -- this is because we have env-specific settings in the body
    };
  };
  "/apps/:appId/features/names": {
    GET: {
      response: APIResponse<FeatureName[]>;
      params: {
        appId: string;
      };
      query: FeatureNamesQueryType;
    };
  };
  "/apps/:appId/features/keys": {
    GET: {
      response: APIResponse<string[]>;
      params: {
        appId: string;
      };
    };
  };
  "/apps/:appId/features/:featureId": {
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; featureId: string };
    };
    GET: {
      response: APIResponse<{
        feature: FeatureDetail;
      }>;
      params: { appId: string; featureId: string };
      query: EnvironmentSelectionQueryType; // TODO ENV -- this is because we have env-specific settings in the body
    };
    PATCH: {
      response: APIResponse<{
        feature: FeatureDetail;
      }>;
      params: { appId: string; featureId: string };
      body: PatchFeatureArgsType;
      query: EnvironmentSelectionQueryType; // TODO ENV -- this is because we have env-specific settings in the body
    };
  };
  "/apps/:appId/features/:featureId/metrics/current": {
    GET: {
      response: APIResponse<FeatureMetricsCurrent>;
      params: { appId: string; featureId: string };
      query: CompanyCurrentMetricsQueryType;
    };
  };
  "/apps/:appId/features/:featureId/metrics/historical": {
    GET: {
      response: APIResponse<FeatureMetricsHistoricalTimeseries>;
      params: { appId: string; featureId: string };
      query: FeatureMetricsHistoricalQuery;
    };
  };
  "/apps/:appId/features/:featureId/views": {
    GET: {
      response: string[];
      params: { appId: string; featureId: string };
    };
    PUT: {
      response: string[];
      params: { appId: string; featureId: string };
      body: string[];
    };
  };
  "/apps/:appId/features/:featureId/tracking-health": {
    GET: {
      response: APIResponse<{
        trackingHealth: TrackingHealth;
      }>;
      params: { appId: string; featureId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/features/:featureId/rollout-status": {
    GET: {
      response: APIResponse<FeatureRolloutStatusResponse>;
      params: { appId: string; featureId: string };
    };
  };
}
