import { FormProvider, useForm } from "react-hook-form";
import { RiMarkdownFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { PatchFeatureArgsSchema } from "@bucketco/shared/featureAPI";

import { FooterNote } from "@/common/components/FooterNote";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import FormSwitch from "@/common/components/Form/FormSwitch";
import FormTextArea from "@/common/components/Form/FormTextArea";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { SettingsDelete } from "@/feature/components/SettingsDelete";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";

export function SettingsGeneral() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  const form = useForm<z.input<typeof PatchFeatureArgsSchema>>({
    resolver: zodResolver(PatchFeatureArgsSchema),
    mode: "onChange",
    defaultValues: {
      name: feature?.name,
      description: feature?.description,
      secret: feature?.secret,
    },
  });

  const updateMutation = useFeatureUpdateMutation();
  const submitHandler = useFormMutationSubmitHandler(
    form,
    updateMutation,
    (result) => {
      form.reset({
        name: result.name,
        description: result.description,
        secret: result.secret,
      });
    },
    {
      prepareVariables: (values) => ({
        id: featureId!,
        ...values,
      }),
    },
  );

  const readonlyInputBg = useColorModeValue("gray.50", "gray.800");

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        direction={"column"}
        gap={5}
        maxW="compactForm"
        onSubmit={submitHandler}
      >
        <ManagedFormControl
          label="Name"
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              autoComplete="off"
              placeholder="e.g. Huddle calls"
              size="sm"
              autoFocus
              data-1p-ignore
            />
          )}
        />

        <FormTextArea
          description={
            <FooterNote
              icon={<RiMarkdownFill />}
              text="Supports markdown"
              tooltip={{
                text: "Common syntax is supported, including *italics*, **bold**, [link](https://example.com) and `code`.",
                docsURL: "https://www.markdownguide.org/cheat-sheet",
              }}
            />
          }
          label="Description"
          minH={"20em"}
          name="description"
          placeholder="Add a description..."
        />

        <FormSwitch
          label={
            <>
              Secret feature{" "}
              <InfoIconTooltip
                _content={{ lineHeight: "base", whiteSpace: "normal" }}
                text={
                  "Secret features can only be retrieved by a server-side SDK which uses a secret key as opposed to a publishable key. This is useful for features that should not be exposed to in browsers etc."
                }
              />
            </>
          }
          name="secret"
        />

        <Flex alignItems="flex-start" flexDirection="column" gap={2}>
          <FormLabel
            alignItems="center"
            display="flex"
            flex="0 0 auto"
            fontSize="md"
            gap={1}
            marginInlineEnd={0}
            pb={0}
          >
            Feature ID
          </FormLabel>
          <Input
            bg={readonlyInputBg}
            size="sm"
            value={feature?.id ?? ""}
            isReadOnly
          />
        </Flex>

        <FormRootError />

        <ButtonGroup>
          <FormSubmitLegacy />
          <FormReset />
        </ButtonGroup>

        <Divider />

        <Box>
          <SettingsDelete feature={feature!} />
        </Box>
      </Flex>
    </FormProvider>
  );
}
