import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { translateError } from "@/common/utils/errorHandling";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useLinearDeleteMutation() {
  const { currentOrg } = useAuthContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorToast = useErrorToast();

  return useMutation({
    mutationFn: () =>
      api.delete<"/orgs/:orgId/linear/connection">(
        `/orgs/${currentOrg?.id}/linear/connection`,
      ),
    retry: 1,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationQueryKeys.linearConnection(currentOrg?.id),
      });
      queryClient.invalidateQueries({
        queryKey: commonQueryKeys.bootstrap,
      });

      toast({
        title: "Linear integration removed",
        description: "Linear integration removed successfully",
        status: "success",
      });
    },
    onError: (error) => {
      const { title, description } = translateError(
        error,
        `Failed to remove Linear integration`,
      );

      errorToast({
        title,
        description,
        duration: 5000,
      });
    },
  });
}
