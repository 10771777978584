import { Box, HStack, StackProps, Text } from "@chakra-ui/react";

import { GithubRepo } from "@bucketco/shared/githubConnectionAPI";

import GithubLogo from "@/common/assets/github-logo.svg?react";
import { highlightStringMatch } from "@/common/components/Autocomplete/AutocompleteSelect";

type GithubRepositoryDisplayProps = {
  repository: GithubRepo;
  search?: string;
} & StackProps;

export function GithubRepositoryDisplay({
  repository,
  search,
  ...rest
}: GithubRepositoryDisplayProps) {
  return (
    <HStack spacing={3} {...rest}>
      <GithubLogo height="24px" width="24px" />
      <Box>
        <Text>
          {search
            ? highlightStringMatch(repository.fullName, search ?? "")
            : repository.fullName}
        </Text>
      </Box>
    </HStack>
  );
}
