import { Link } from "react-router-dom";
import {
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useFeature } from "@bucketco/react-sdk";
import {
  FeatureRolloutStatus,
  FeatureSourceType,
} from "@bucketco/shared/featureAPI";
import { DebuggerUrl } from "@bucketco/shared/urls";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { SectionHeading } from "@/common/components/SectionHeading";
import TimestampCell from "@/common/components/TimestampCell";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";
import { latestTimestampSingleStatus } from "@/feature/utils/latestRolloutStatusTimestamp";

function EnvironmentItem({
  status,
  featureSource,
}: {
  status: FeatureRolloutStatus;
  featureSource: FeatureSourceType;
}) {
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const latestTimestamp = latestTimestampSingleStatus(status);

  const statusTimestamps = (
    status: FeatureRolloutStatus,
    featureSource: FeatureSourceType,
  ) => [
    {
      label: "Latest check",
      tooltip:
        "Last time checking whether a company was matched by feature access rules.",
      timestamp: status.latestCheck,
    },
    {
      label: "Latest feedback",
      tooltip:
        "Feedback responses sbumitted through the widget, SDK or API. Does not include feedback entered manually.",
      timestamp: status.latestFeedback,
    },
    {
      label: "Latest track",
      tooltip:
        featureSource === "event"
          ? `Track event(s) matching the adoption criteria.`
          : `Company attribute(s) last changed to match the adoption criteria.`,
      timestamp: status.latestUsage,
    },
  ];

  return (
    <Popover
      key={status.environment.id}
      closeDelay={50}
      openDelay={50}
      placement="left-start"
      trigger="hover"
    >
      <PopoverTrigger>
        <Flex
          _hover={{ backgroundColor: hoverBg }}
          borderRadius="base"
          cursor="default"
          justify="space-between"
          mx={-2}
          px={2}
          py={1}
          transition="background-color 0.15s ease-in-out"
        >
          <EnvironmentDisplayName
            color={latestTimestamp ? undefined : "dimmed"}
            environment={status.environment}
            iconColor={latestTimestamp ? undefined : "dimmed"}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          />
          {latestTimestamp ? (
            <TimestampCell
              clamp="past"
              showTooltip={false}
              value={latestTimestamp.timestamp}
              autoUpdate
            />
          ) : (
            <Text color="dimmed">None</Text>
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody fontSize="sm" minW="3xs" p={4}>
          <Flex direction="column" fontSize="sm" gap={2}>
            {statusTimestamps(status, featureSource).map((i) => (
              <Flex key={i.label} gap={4} justify="space-between">
                <HStack>
                  <Text>{i.label}</Text>
                  <InfoIconTooltip text={i.tooltip} />
                </HStack>
                {i.timestamp ? (
                  <TimestampCell clamp="past" value={i.timestamp} autoUpdate />
                ) : (
                  <Text color="dimmed">None</Text>
                )}
              </Flex>
            ))}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default function EnvironmentActivity({
  featureId,
  featureSource = "event",
}: {
  featureId?: string;
  featureSource?: FeatureSourceType;
}) {
  const { data, isRefetching, isLoading } =
    useFeatureRolloutStatusData(featureId);

  const { isEnabled } = useFeature("revamped-debugger");

  return (
    <Flex direction="column" fontSize="sm" gap={2}>
      <Flex justify="space-between">
        <SectionHeading variant="subtle">Environment activity</SectionHeading>
        <AnimatedSpinner
          color="dimmed"
          show={isLoading || isRefetching}
          size="xs"
        />
      </Flex>
      <Flex direction="column" gap={0}>
        {data?.statuses.map((status) =>
          isEnabled ? (
            <Link
              key={status.environment.id}
              to={DebuggerUrl(status.environment, { feature: featureId })}
            >
              <EnvironmentItem
                key={status.environment.id}
                featureSource={featureSource}
                status={status}
              />
            </Link>
          ) : (
            <EnvironmentItem
              key={status.environment.id}
              featureSource={featureSource}
              status={status}
            />
          ),
        )}
      </Flex>
    </Flex>
  );
}
