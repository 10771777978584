import { RiFileCopy2Line, RiInstanceLine } from "react-icons/ri";
import { Flex, StackProps, Text } from "@chakra-ui/react";

import { LinearTrackingEntity } from "@bucketco/shared/linearConnectionAPI";

import { highlightStringMatch } from "@/common/components/Autocomplete/AutocompleteSelect";

type LinearTrackingEntityDisplayProps = StackProps & {
  entity: LinearTrackingEntity;
  search?: string;
};

export function LinearTrackingEntityDisplay({
  entity,
  search,
  ...rest
}: LinearTrackingEntityDisplayProps) {
  return (
    <Flex alignItems="center" gap={2} whiteSpace="nowrap" {...rest}>
      {entity.type === "issue" ? <RiFileCopy2Line /> : <RiInstanceLine />}
      {entity.identifier && (
        <Text color="dimmed">
          {search
            ? highlightStringMatch(entity.identifier, search)
            : entity.identifier}
        </Text>
      )}
      <Text>
        {search ? highlightStringMatch(entity.title, search) : entity.title}
      </Text>
    </Flex>
  );
}
