import { useMemo } from "react";
import {
  keepPreviousData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import pick from "lodash/pick";

import { ErrorResponse } from "@bucketco/shared/api";
import { UserFilter } from "@bucketco/shared/filter";
import { UserName, UserPage, UsersQuery } from "@bucketco/shared/userAPI";

import api from "@/common/utils/api";
import { useCurrentEnv } from "../hooks/useCurrentEnv";

import { commonQueryKeys } from "./commonQueryKeys";

export function useUsers<TData = UserPage>(
  query: Omit<UsersQuery, "envId">,
  options?: UseQueryOptions<UserPage, AxiosError<ErrorResponse>, TData>,
) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: commonQueryKeys.users(appId, envId, query),
    queryFn: () =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: { ...query, envId: envId! },
        })
        .then((res) => res.data),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    ...options,
    enabled: !!appId && !!envId && options?.enabled,
  });
}

export function useUsersSearch(
  search?: string,
  query: Omit<UsersQuery, "envId" | "filter"> = {},
  options?: UseInfiniteQueryOptions<
    UserPage,
    AxiosError<ErrorResponse>,
    UserName[],
    UserPage,
    QueryKey,
    number
  >,
) {
  const { appId, envId } = useCurrentEnv();

  const params = useMemo(() => {
    const params: Omit<UsersQuery, "envId"> = {
      pageSize: 50,
      ...query,
    };
    if (search) {
      params.filter = {
        type: "group",
        operator: "or",
        filters: ["id", "name", "email"].map((field) => ({
          type: "userAttribute",
          field,
          operator: "CONTAINS",
          values: [search],
        })),
      } satisfies UserFilter;
    }
    return params;
  }, [query, search]);

  return useInfiniteQuery({
    queryKey: commonQueryKeys.users(appId, envId, params),
    queryFn: ({ pageParam = 0 }) =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: {
            ...params,
            pageIndex: pageParam,
            envId: envId!,
          },
        })
        .then((res) => res.data),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageIndex + 1;
      const totalPages = Math.ceil(lastPage.totalCount / lastPage.pageSize);
      return nextPage < totalPages ? nextPage : undefined;
    },
    initialPageParam: 0,
    select: ({ pages }) =>
      pages.flatMap(({ data }) =>
        data.map((item) => pick(item, "id", "name", "email", "avatarUrl")),
      ) ?? [],
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    ...options,
  });
}

export function useUser(userId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: commonQueryKeys.user(appId, envId, userId),
    queryFn: () =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: {
            filter: {
              type: "userAttribute",
              field: "id",
              operator: "IS",
              values: [userId!],
            } satisfies UserFilter,
            envId: envId!,
          },
        })
        .then((res) => res.data.data[0]),
    enabled: !!appId && !!envId && !!userId,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
