import { Navigate, useMatch, useParams } from "react-router";
import { Center } from "@chakra-ui/react";

import { FeatureUrl } from "@bucketco/shared/urls";

import InvalidRoute from "@/app/pages/InvalidRoute";
import { useAuthContext } from "@/auth/contexts/authContext";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import { useFeaturesData } from "@/feature/data/useFeaturesData";

export default function GoToFeatureKey() {
  const { data: features, isLoading } = useFeaturesData();
  const { featureKey } = useParams();
  const { currentEnv } = useAuthContext();

  const foundFeature = features?.data.find((f) => f.key === featureKey);

  const match = useMatch("/envs/:envId/features/by-key/:featureKey/*");

  if (isLoading) {
    return (
      <Center flexGrow={1}>
        <LoadingSpinner />
      </Center>
    );
  }

  if (!featureKey || !match || !match.params || (!isLoading && !foundFeature)) {
    return <InvalidRoute />;
  }

  const wildcardPath = match.params?.["*"];
  const url = FeatureUrl(currentEnv!, foundFeature!);
  const urlWithPath = wildcardPath ? url + "/" + wildcardPath : url;

  return <Navigate to={urlWithPath} replace />;
}
