import { forwardRef, useMemo, useState } from "react";

import { GithubRepo } from "@bucketco/shared/githubConnectionAPI";

import AutocompleteSelect from "@/common/components/Autocomplete/AutocompleteSelect";
import {
  AutocompleteImplementationProps,
  AutocompleteValueProps,
} from "@/common/utils/autocomplete";
import { GithubRepositoryDisplay } from "@/integration/components/github/GithubRepositoryDisplay";
import { useGithubRepositories } from "@/integration/hooks/useGithubRepositories";

export type GithubRepositoryAutocompleteSelectProps =
  AutocompleteImplementationProps<GithubRepo> &
    AutocompleteValueProps<GithubRepo>;

export const GithubRepositoryAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  GithubRepositoryAutocompleteSelectProps
>(function GithubRepositoryAutocompleteSelect(
  {
    value,
    placeholder = "Select a GitHub repository",
    canClear,
    onChange,
    ...autocompleteProps
  }: GithubRepositoryAutocompleteSelectProps,
  ref,
) {
  const [searchValue, setSearchValue] = useState("");

  const { data = [], isLoading, isFetching } = useGithubRepositories();

  const suggestions = useMemo(
    () =>
      data?.filter(
        (repo) =>
          repo.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
          repo.description?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [data, searchValue],
  );

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      isLoading={isLoading}
      isLoadingSuggestions={isFetching}
      itemKeyProperty="id"
      itemRenderFn={(repo, search) => (
        <GithubRepositoryDisplay py={0.5} repository={repo} search={search} />
      )}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={suggestions}
      value={value ?? undefined}
      onChange={(newValue: GithubRepo | null) => {
        setSearchValue("");
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }

        onChange?.(newValue ?? null);
      }}
      onClear={() => {
        setSearchValue("");
      }}
      onSearchInput={setSearchValue}
      {...autocompleteProps}
    />
  );
});
