import "@/app/utils/datadog";
import "@fontsource-variable/geist";
import "@fontsource-variable/geist-mono";

import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import AppRoutes from "@/app/components/Routes";
import ErrorBoundary from "@/common/components/ErrorBoundary";
import { loadAnalytics } from "@/common/utils/segmentAnalytics";
import { DragPreviewLayer } from "@/feature/components/DragPreviewLayer";
import { theme } from "@/theme/theme";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const queryClient = new QueryClient({});

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet defaultTitle="Bucket"></Helmet>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <DndProvider backend={HTML5Backend}>
              <ErrorBoundary>
                <DragPreviewLayer />
                <AppRoutes />
              </ErrorBoundary>
            </DndProvider>
          </BrowserRouter>
          <ReactQueryDevtools
            buttonPosition="bottom-right"
            initialIsOpen={false}
            position="bottom"
          />
        </QueryClientProvider>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

loadAnalytics();
