import React, { ReactNode, useMemo, useState } from "react";
import {
  RiCloseLine,
  RiErrorWarningLine,
  RiFlashlightLine,
  RiMenuSearchLine,
  RiSearchLine,
} from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Button,
  Card,
  Code,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Link,
  List,
  ListItem,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  UnorderedList,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";

import { DebugEvent, DebugEventTypes } from "@bucketco/shared/eventAPI";
import { GlobalSettingsUrl, NewFeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { CompanyAutocompleteSelect } from "@/common/components/Autocomplete/CompanyAutocompleteSelect";
import { FeatureAutocompleteSelect } from "@/common/components/Autocomplete/FeatureAutocompleteSelect";
import { UserAutocompleteSelect } from "@/common/components/Autocomplete/UserAutocompleteSelect";
import { DocumentationButton } from "@/common/components/CommonIntegrationButtons";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import { DataTable } from "@/common/components/DataTable/DataTable";
import {
  DebugEventTypeDisplay,
  DebugEventTypeSelect,
} from "@/common/components/DebugEventTypeSelect";
import EmptyState from "@/common/components/EmptyState";
import InfiniteAnimation from "@/common/components/InfiniteAnimation";
import { WarningBadgeTooltip } from "@/common/components/InfoIconTooltip";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import SectionDescription from "@/common/components/SectionDescription";
import { SectionHeading } from "@/common/components/SectionHeading";
import SidebarLayout, {
  SidebarContainer,
  SidebarSection,
  SidebarTable,
} from "@/common/components/SidebarLayout";
import { SwitchButtonGroup } from "@/common/components/SwitchButtonGroup";
import TimestampCell from "@/common/components/TimestampCell";
import { UserDisplay } from "@/common/components/UserDisplay";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import dayjs from "@/common/utils/dayjs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { IntegrationKey } from "@/environment/components/IntegrationKey";
import { useEnvironments } from "@/environment/hooks/useEnvironments";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";

const MAX_EVENTS_COUNT = 50;
const DAYS_HISTORY = 3;

const satisfactionScoreToText = (score: number) => {
  if (score === 1) return "very unsatisfied";
  if (score === 2) return "unsatisfied";
  if (score === 3) return "neutral";
  if (score === 4) return "satisfied";
  if (score === 5) return "very satisfied";
  return "-";
};

export const cellVerticalAdjust: BoxProps = {
  display: "inline-flex",
  alignItems: "center",
  minH: 7,
};

function ellipsis(input: string, maxLength: number) {
  return input.length > maxLength
    ? input.substring(0, maxLength) + "..."
    : input;
}

function ShortContentCell({ event }: { event: DebugEvent }) {
  const mapping = {
    check: {
      primary: event.featureFlagEvent?.featureKey || "-",
      secondary: event.featureFlagEvent?.evalResult ? "enabled" : "disabled",
    },
    "check-config": {
      primary: event.featureFlagEvent?.featureKey || "-",
      secondary: event.featureFlagEvent?.evalConfig?.key ?? "-",
    },
    evaluate: {
      primary: event.featureFlagEvent?.featureKey || "-",
      secondary: event.featureFlagEvent?.evalResult ? "enabled" : "disabled",
    },
    "evaluate-config": {
      primary: event.featureFlagEvent?.featureKey || "-",
      secondary: event.featureFlagEvent?.evalConfig?.key ?? "-",
    },
    track: {
      primary: event?.name || "-",
      secondary:
        (event.attributes ? Object.keys(event?.attributes).length : 0) +
        " attributes",
    },
    user: {
      primary: event.user?.id || "-",
      secondary:
        (event.attributes ? Object.keys(event?.attributes).length : 0) +
        " attributes",
    },
    company: {
      primary: event.company?.id || "-",
      secondary:
        (event.attributes ? Object.keys(event?.attributes).length : 0) +
        " attributes",
    },
    feedback: {
      primary: `"${ellipsis(event.feedback?.comment || "-", 80)}"`,
      secondary: satisfactionScoreToText(event.feedback?.score || 0),
    },
  };

  const { primary, secondary } = mapping[event.debugEventType];
  return (
    <Flex alignItems={"center"} gap={2}>
      <Text fontSize="md" isTruncated>
        {primary}
      </Text>
      <Text color="dimmed" fontSize="sm" isTruncated>
        {secondary}
      </Text>
      {(event.debugEventType === "check" ||
        event.debugEventType === "evaluate") &&
        event.featureFlagEvent?.evalMissingFields &&
        event.featureFlagEvent?.evalMissingFields.length > 0 && (
          <WarningBadgeTooltip text="This event has missing fields in the evaluation context. The evaluation may not work as expected." />
        )}
    </Flex>
  );
}

function SidebarInstructions() {
  const { currentEnv } = useAuthContext();
  const envsQuery = useEnvironments();

  return (
    <SidebarContainer>
      <SidebarSection>
        <SectionHeading>Getting started guide</SectionHeading>
        <SectionDescription>
          Use Segment (several languages) or the Bucket SDK (JS) to send data to
          Bucket.
        </SectionDescription>
        <Box>
          <DocumentationButton
            onClick={() =>
              segmentAnalytics.track("Tracking Getting Started Link Clicked")
            }
          />
        </Box>
      </SidebarSection>
      <SidebarSection>
        <SectionHeading>Integration keys</SectionHeading>
        <SectionDescription>
          Provide these keys to Segment or the Bucket SDK to send data to the
          corresponding app environment.{" "}
          <Link
            href="https://docs.bucket.co/introduction/concepts/app/environment"
            target="_blank"
          >
            Learn more
          </Link>
        </SectionDescription>
        {envsQuery.isLoading ? (
          <AnimatedSpinner size="sm" show />
        ) : !envsQuery.isSuccess ? (
          <Alert status="warning">
            <AlertIcon />
            Unable to load environment keys
          </Alert>
        ) : (
          <Flex direction="column" gap={4} shrink={1}>
            {[envsQuery.data.production, ...envsQuery.data.nonProduction].map(
              (env) => (
                <VStack key={env.id} align="flex-start">
                  <EnvironmentDisplayName environment={env} />
                  <IntegrationKey value={env.publishableKey} w="100%" />
                  <IntegrationKey value={env.secretKey} w="100%" />
                </VStack>
              ),
            )}
            <Link
              as={RouterLink}
              to={GlobalSettingsUrl(currentEnv!, "app-environments")}
            >
              Manage environments
            </Link>
          </Flex>
        )}
      </SidebarSection>
    </SidebarContainer>
  );
}

function FeatureFlagEventSidebarSection({ event }: { event: DebugEvent }) {
  const warningColor = useColorModeValue("orange.800", "orange.200");
  const warningBgColor = useColorModeValue(
    "orange.100",
    "rgba(251, 211, 141, 0.16)",
  );

  const isConfig =
    event.debugEventType === "check-config" ||
    event.debugEventType === "evaluate-config";

  return (
    <>
      <SidebarTable layout="fixed">
        <colgroup>
          <col width="90"></col>
          <col></col>
        </colgroup>
        <Tbody>
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Key
            </Td>
            <Td textAlign="right">
              <CodeWithEllipsis>
                {event.featureFlagEvent?.featureKey}
              </CodeWithEllipsis>
            </Td>
          </Tr>
          {event.featureFlagEvent?.targetingVersion && (
            <Tr>
              <Td color="dimmed" fontWeight="medium">
                {isConfig ? "Config version" : "Flag version"}
              </Td>
              <Td textAlign="right">
                <CodeWithEllipsis>
                  {event.featureFlagEvent?.targetingVersion}
                </CodeWithEllipsis>
              </Td>
            </Tr>
          )}
          {isConfig ? (
            <>
              <Tr>
                <Td color="dimmed" fontWeight="medium">
                  Config key
                </Td>
                <Td textAlign="right">
                  <CodeWithEllipsis>
                    {event.featureFlagEvent?.evalConfig?.key}
                  </CodeWithEllipsis>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={2}>
                  <JsonFieldSidebarSection
                    content={event.featureFlagEvent?.evalConfig?.payload}
                    label="Config payload"
                  />
                </Td>
              </Tr>
            </>
          ) : (
            <Tr>
              <Td color="dimmed" fontWeight="medium">
                Value
              </Td>
              <Td textAlign="right">
                <Code>
                  {event.featureFlagEvent?.evalResult ? "enabled" : "disabled"}
                </Code>
              </Td>
            </Tr>
          )}
        </Tbody>
      </SidebarTable>

      <JsonFieldSidebarSection
        content={event.featureFlagEvent?.evalContext}
        label="Evaluation context"
      />

      {event.featureFlagEvent?.evalMissingFields &&
        event.featureFlagEvent.evalMissingFields.length > 0 && (
          <Card bg={warningBgColor} p={3} variant={"subtle"}>
            <Flex color={warningColor} direction="column" gap={2}>
              <Flex alignItems={"center"} direction={"row"} gap={1}>
                <RiErrorWarningLine size={16} />
                <Text fontSize="sm" fontWeight="medium">
                  Missing fields in evaluation context
                </Text>
              </Flex>
              <UnorderedList px={5}>
                {event.featureFlagEvent?.evalMissingFields.map((field) => (
                  <ListItem key={field}>{field}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </Card>
        )}
    </>
  );
}

function CodeWithEllipsis({ children }: { children: ReactNode }) {
  return (
    <Tooltip label={children} openDelay={1000}>
      <Box noOfLines={1} wordBreak="break-all">
        <Code as="span" display="inline" textOverflow="ellipsis">
          {children}
        </Code>
      </Box>
    </Tooltip>
  );
}

function SharedFieldsSidebarSection({ event }: { event: DebugEvent }) {
  return (
    <SidebarTable layout="fixed">
      <Tbody>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Type
          </Td>
          <Td alignContent="right" alignItems="right" alignSelf="right">
            <Flex align="right" justify="flex-end">
              <DebugEventTypeDisplay type={event.debugEventType} />
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            User
          </Td>
          <Td textAlign="right">
            <Flex justify="flex-end">
              {event.user ? (
                <UserDisplay fontSize="sm" user={event.user} />
              ) : (
                <NotAvailableCell />
              )}
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            User ID
          </Td>
          <Td textAlign="right">
            {event.user?.id ? (
              <CodeWithEllipsis>{event.user?.id || ""}</CodeWithEllipsis>
            ) : (
              <NotAvailableCell />
            )}
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Company
          </Td>
          <Td textAlign="right">
            <Flex justify="flex-end">
              {event.company ? (
                <CompanyDisplay company={event.company} link />
              ) : (
                <NotAvailableCell />
              )}
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            <Flex gap={1} justifyItems={"center"}>
              <Text noOfLines={1}>Company ID</Text>
              {event.company?.inferred && (
                <WarningBadgeTooltip text="This company ID was inferred from previous association of the user with this company ID" />
              )}
            </Flex>
          </Td>
          <Td maxW="0" textAlign="right">
            {event.company?.id ? (
              <CodeWithEllipsis>{event.company?.id || ""}</CodeWithEllipsis>
            ) : event.debugEventType == "user" ? (
              <NotAvailableCell />
            ) : (
              <WarningBadgeTooltip
                label="Missing"
                text="Company ID is missing from this event. Client is possibly set up incorrectly."
              />
            )}
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Timestamp
          </Td>
          <Td textAlign="right">
            <TimestampCell value={event.timestamp} autoUpdate />
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            SDK Version
          </Td>
          <Td textAlign="right">
            <CodeWithEllipsis>{event.sdkVersion}</CodeWithEllipsis>
          </Td>
        </Tr>
        {event.debugEventType === "track" && (
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Name
            </Td>
            <Td textAlign="right">
              <CodeWithEllipsis>{event.name || ""}</CodeWithEllipsis>
            </Td>
          </Tr>
        )}
        {event.debugEventType === "feedback" && (
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Score
            </Td>
            <Td textAlign="right">
              <Text fontSize="sm" isTruncated>
                {satisfactionScoreToText(event.feedback?.score || 0)}
              </Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </SidebarTable>
  );
}

function JsonFieldSidebarSection({
  label,
  content,
  fallback = "No data",
}: {
  label: string;
  content: any;
  fallback?: string;
}) {
  const codeBg = useColorModeValue("gray.50", "gray.750");
  return (
    <Flex direction="column" gap={2}>
      <Text color="dimmed" fontSize="sm" fontWeight="medium">
        {label}
      </Text>
      <Code
        bg={codeBg}
        borderRadius="base"
        overflowX="scroll"
        px={3}
        py={1.5}
        whiteSpace="pre"
      >
        {content ? JSON.stringify(content, null, 2) : fallback}
      </Code>
    </Flex>
  );
}

function newFeatureKeyFromEvent(event: DebugEvent): string {
  if (event.debugEventType === "track") {
    return event?.name || "";
  } else if (
    event.debugEventType === "check" ||
    event.debugEventType === "evaluate" ||
    event.debugEventType === "check-config" ||
    event.debugEventType === "evaluate-config"
  ) {
    return event.featureFlagEvent?.featureKey || "";
  }

  return "";
}

function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

function newFeatureNameFromKey(key: string): string {
  // replace all following characters with space: -, _, .
  return titleCase(key.replace(/[-_.]/g, " "));
}

export function SidebarEventDetails({
  event,
  onClose,
}: {
  event: DebugEvent;
  onClose: () => void;
}) {
  const { currentEnv } = useAuthContext();
  const codeBg = useColorModeValue("gray.50", "gray.750");

  return (
    <SidebarContainer position="sticky" top={0}>
      <SidebarSection>
        <Flex align="center" direction="row">
          <SectionHeading flexGrow={1}>Event details</SectionHeading>
          <IconButton
            aria-label="Close event details"
            icon={<RiCloseLine size={16} />}
            size="2xs"
            variant="ghost"
            isRound
            onClick={onClose}
          />
        </Flex>
        <SharedFieldsSidebarSection event={event} />
        {(event.debugEventType === "check" ||
          event.debugEventType === "evaluate" ||
          event.debugEventType === "check-config" ||
          event.debugEventType === "evaluate-config") && (
          <FeatureFlagEventSidebarSection event={event} />
        )}
        {(event.debugEventType === "user" ||
          event.debugEventType === "company") && (
          <JsonFieldSidebarSection
            content={event?.attributes}
            label="Attributes"
          />
        )}
        {event.debugEventType === "feedback" && (
          <Flex direction="column" gap={2}>
            <Text color="dimmed" fontSize="sm" fontWeight="medium">
              Comment
            </Text>
            <Code bg={codeBg} borderRadius="base" px={3} py={1.5}>
              {event.feedback?.comment || ""}
            </Code>
          </Flex>
        )}
      </SidebarSection>

      {event.debugEventType !== "company" &&
        event.debugEventType !== "user" && (
          <SidebarSection>
            <SectionHeading>Related features</SectionHeading>
            <Box minH={6}>
              {event.features.length === 0 && (
                <Text color="dimmed">No related features</Text>
              )}
              {event.features.length > 0 && (
                <Flex as={List} direction="column" gap={4}>
                  {event.features.map((f) => (
                    <ListItem key={f.id}>
                      <FeatureDisplay feature={f} includeParents={false} link />
                    </ListItem>
                  ))}
                </Flex>
              )}
            </Box>
            {event.features.length === 0 && (
              <Button
                as={RouterLink}
                size="sm"
                to={NewFeatureUrl(currentEnv!, {
                  key: newFeatureKeyFromEvent(event),
                  name: newFeatureNameFromKey(newFeatureKeyFromEvent(event)),
                })}
                variant="outline"
              >
                Track new feature
              </Button>
            )}
          </SidebarSection>
        )}
    </SidebarContainer>
  );
}

function EventNameFilter({
  value = "",
  onChange,
  ...rest
}: Omit<BoxProps, "children" | "onChange"> & {
  value?: string;
  onChange: (value: string) => void;
  size?: InputProps["size"];
}) {
  const searchIconColor = useColorModeValue("gray.400", "gray.600");
  return (
    <Flex {...rest}>
      <InputGroup>
        <InputLeftElement color={!value ? searchIconColor : undefined}>
          <RiSearchLine size={16} />
        </InputLeftElement>
        <Input
          autoComplete="off"
          placeholder="Search by event"
          type="search"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputGroup>
    </Flex>
  );
}

export default function DebuggerRevamped() {
  const { envId, appId } = useCurrentEnv();
  const [latestTS, setLatestTS] = useState<number>(
    dayjs().subtract(DAYS_HISTORY, "days").valueOf(),
  );
  const [cachedEvents, setCachedEvents] = useState<DebugEvent[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(true);
  const refetchInterval = () => (shouldRefetch ? 1000 : false);
  const { currentEnv } = useAuthContext();

  const [searchQuery, setSearchQuery] = useSearchParam("search");
  const [companyId, setCompanyId] = useSearchParam("company");
  const [userId, setUserId] = useSearchParam("user");
  const [featureId, setFeatureId] = useSearchParam("feature");
  const [eventTypes, setEventTypes] = useSearchArrayParam("eventTypes");

  const validEventTypes = useMemo(() => {
    return eventTypes.filter((type) =>
      (DebugEventTypes as unknown as string[]).includes(type),
    ) as DebugEvent["debugEventType"][];
  }, [eventTypes]);

  const params = useMemo(() => {
    setCachedEvents([]);
    setLatestTS(dayjs().subtract(DAYS_HISTORY, "days").valueOf());

    return {
      envId,
      searchQuery,
      companyId,
      userId,
      featureId,
      validEventTypes,
    };
  }, [searchQuery, companyId, userId, featureId, envId, validEventTypes]);

  const { isLoading } = useQuery({
    queryKey: [
      "environment",
      params.envId,
      "debugEvents",
      params.searchQuery,
      params.companyId,
      params.userId,
      params.featureId,
      params.validEventTypes,
    ],

    queryFn: () =>
      api
        .get<"/apps/:appId/debug-events/latest">(
          `/apps/${appId}/debug-events/latest`,
          {
            params: {
              envId: params.envId!,
              lastTS: String(latestTS),
              eventName: params.searchQuery,
              featureId: params.featureId,
              companyId: params.companyId,
              userId: params.userId,
              eventTypes: params.validEventTypes,
            },
          },
        )
        .then((res) => res.data.events)
        .then((events) => {
          if (events && events.length > 0) {
            // save new events
            setCachedEvents((cachedEvents) =>
              uniqWith([...events, ...cachedEvents], isEqual).slice(
                0,
                MAX_EVENTS_COUNT,
              ),
            );

            // Update timestamp
            const latestIncomingTS = Date.parse(events[0].timestamp);
            setLatestTS(latestIncomingTS);
          }

          return events;
        }),

    gcTime: 0,
    enabled: !!appId && !!params.envId,
    refetchInterval: refetchInterval,
  });

  const [activeEvent, setActiveEvent] = useState<DebugEvent | null>(null);

  const columnHelper = createColumnHelper<DebugEvent>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("timestamp", {
        header: "Received",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <TimestampCell value={value} autoUpdate leftAlign />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("debugEventType", {
        header: "Type",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <DebugEventTypeDisplay type={value} />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("id", {
        header: "Event",
        cell: (cell) => {
          return (
            <Box {...cellVerticalAdjust}>
              <ShortContentCell event={cell.row.original} />
            </Box>
          );
        },
      }),
      columnHelper.accessor("features", {
        header: "Feature",
        cell: (cell) => {
          return (
            <Box {...cellVerticalAdjust}>
              {cell.getValue().length === 0 && <NotAvailableCell />}
              {cell.getValue().length === 1 && (
                <FeatureDisplay
                  feature={cell.getValue()[0]}
                  includeParents={false}
                  link
                />
              )}
              {cell.getValue().length > 1 && (
                <Text color="dimmed" fontSize="sm">
                  {cell.getValue().length} features
                </Text>
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("company", {
        header: "Company",
        minSize: 150,
        cell: (cell) => {
          const company = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {company ? (
                <CompanyDisplay company={company} link={company.known} />
              ) : (
                <Flex alignItems="center" gap={1}>
                  {cell.row.original.debugEventType != "user" ? (
                    <WarningBadgeTooltip
                      label="Missing"
                      text="Company ID is missing from this event. Client is possibly set up incorrectly."
                    />
                  ) : (
                    <NotAvailableCell />
                  )}
                </Flex>
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: "User",
        minSize: 150,
        cell: (cell) => {
          const user = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {user ? <UserDisplay user={user} /> : <NotAvailableCell />}
            </Box>
          );
        },
      }),
    ],
    [columnHelper],
  );

  return (
    <SidebarLayout
      flexGrow={1}
      sidebarContent={
        activeEvent !== null ? (
          <SidebarEventDetails
            event={activeEvent}
            onClose={() => setActiveEvent(null)}
          />
        ) : (
          <SidebarInstructions />
        )
      }
      templateColumns="repeat(3, 1fr) clamp(230px, 20%, 400px)"
    >
      <DataTable
        _toolbarLeft={{ wrap: "wrap", alignSelf: "flex-start" }}
        _toolbarRight={{ wrap: "wrap", flex: "1 1 min-content" }}
        columns={columns}
        data={cachedEvents}
        emptyState={
          <EmptyState
            description={
              <>
                Events will show up here as soon as they come in. Note: tracking
                data is{" "}
                <Link
                  as={RouterLink}
                  to={GlobalSettingsUrl(currentEnv!, "app-environments")}
                >
                  environment
                </Link>{" "}
                specific!
              </>
            }
            h={"100%"}
            icon={<RiMenuSearchLine size={48} />}
            isLoading={isLoading}
            title={`No events past ${DAYS_HISTORY} days`}
          />
        }
        highlightRow={(row) => row.original.id === activeEvent?.id}
        isFetching={isLoading}
        sorting={[]}
        tableId="debug-events"
        toolbarLeftActions={
          <>
            <EventNameFilter value={searchQuery} onChange={setSearchQuery} />
            <DebugEventTypeSelect
              placeholder="Any event type"
              value={validEventTypes}
              onChange={setEventTypes}
            />
            <CompanyAutocompleteSelect
              placeholder="Any company"
              value={companyId}
              canClear
              valueAsId
              onChange={setCompanyId}
            />
            <UserAutocompleteSelect
              placeholder="Any user"
              value={userId}
              canClear
              valueAsId
              onChange={setUserId}
            />
            <FeatureAutocompleteSelect
              placeholder="Any feature"
              value={featureId}
              canClear
              valueAsId
              onChange={setFeatureId}
            />
          </>
        }
        toolbarRightActions={
          <SwitchButtonGroup
            buttons={[
              {
                id: "live",
                label: "Live",
                leftIcon: shouldRefetch ? (
                  <InfiniteAnimation>
                    <RiFlashlightLine size={15} />
                  </InfiniteAnimation>
                ) : (
                  <RiFlashlightLine size={15} />
                ),
              },
              {
                id: "paused",
                label: "Paused",
              },
            ]}
            value={shouldRefetch ? "live" : "paused"}
            onChange={(value) => setShouldRefetch(value === "live")}
          />
        }
        variant="clickable"
        onRowClick={(row) => setActiveEvent(row.original)}
      />
    </SidebarLayout>
  );
}
