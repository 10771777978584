import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useGithubRepositories() {
  const { currentOrg } = useAuthContext();
  return useQuery({
    queryKey: integrationQueryKeys.githubRepositories(currentOrg?.id),
    queryFn: async () => {
      const res = await api.get<`/orgs/:orgId/github/connection/repos`>(
        `/orgs/${currentOrg?.id}/github/connection/repos`,
      );
      return res.data;
    },
    retry: 2,
  });
}
