import { useState } from "react";
import { RiCheckLine, RiErrorWarningLine } from "react-icons/ri";
import {
  Badge,
  Button,
  FormLabel,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  GitHubConnection,
  GithubRepo,
} from "@bucketco/shared/githubConnectionAPI";

import GithubLogo from "@/common/assets/github-logo.svg?react";
import { compactFormattedDate } from "@/common/utils/datetime";
import { GithubRepositoryAutocompleteSelect } from "@/integration/components/github/GithubRepositoryAutocompleteSelect";
import { InitiateIntegrationButton } from "@/integration/components/InitiateIntegrationButton";
import { useGithubConnection } from "@/integration/data/useGithubConnection";
import { useGithubDeleteMutation } from "@/integration/data/useGithubDeleteMutation";
import { useGithubSaveRepoMutation } from "@/integration/data/useGithubSaveRepoMutation";

function SelectRepositoryForm() {
  const { mutate: saveRepoMutate, isPending: saveRepoIsLoading } =
    useGithubSaveRepoMutation();
  const [selectedRepo, setSelectedRepo] = useState<GithubRepo | null>(null);

  return (
    <>
      <Disconnect />
      <Text color="dimmed" fontSize="sm" maxWidth="lg">
        Select a GitHub repository to connect.
      </Text>
      <GithubRepositoryAutocompleteSelect
        value={selectedRepo}
        onChange={setSelectedRepo}
      />
      <Button
        isLoading={saveRepoIsLoading}
        size="sm"
        variant="outline"
        onClick={() => {
          if (selectedRepo) {
            saveRepoMutate({ repository: selectedRepo.fullName });
          }
        }}
      >
        Save Repository
      </Button>
    </>
  );
}

function Disconnect() {
  const { mutate: deleteMutate, isPending: mutationIsLoading } =
    useGithubDeleteMutation();

  return (
    <Button
      isLoading={mutationIsLoading}
      size="sm"
      variant="outline"
      onClick={() => deleteMutate()}
    >
      Disconnect
    </Button>
  );
}

function Connected({
  githubConnection,
}: {
  githubConnection: GitHubConnection;
}) {
  return (
    <VStack align="flex-start" spacing={3}>
      <HStack>
        <Badge colorScheme="green" size="md" variant="subtle">
          <HStack spacing={1}>
            <RiCheckLine size={16} />
            <Text fontSize="sm">Connected</Text>
          </HStack>
        </Badge>
        {githubConnection.repository && (
          <>
            <Text color="dimmed" fontSize="sm">
              to{" "}
            </Text>
            <GithubLogo height="24px" width="24px" />
            <Text
              as="a"
              color="dimmed"
              fontSize="sm"
              fontWeight={"bold"}
              href={`https://github.com/${githubConnection.repository}`}
            >
              {githubConnection.repository}
            </Text>
          </>
        )}
        <Text color="dimmed" fontSize="sm">
          since {compactFormattedDate(githubConnection.createdAt)}
        </Text>
      </HStack>
      <Disconnect />
    </VStack>
  );
}

function NotConnected() {
  return (
    <VStack align="flex-start" spacing={3}>
      <Badge colorScheme="orange" size="md" variant="subtle">
        <HStack spacing={1}>
          <RiErrorWarningLine size={16} />
          <Text fontSize="sm">Not connected</Text>
        </HStack>
      </Badge>
      <Text color="dimmed" fontSize="sm" maxWidth="lg">
        Connect to GitHub to start.
      </Text>
      <InitiateIntegrationButton integration="github" />
    </VStack>
  );
}

export default function GithubIntegrationForm() {
  const { data, isLoading } = useGithubConnection();

  return (
    <VStack align="flex-start">
      <FormLabel pb={1}>GitHub integration</FormLabel>
      {isLoading ? (
        <Spinner size="sm" />
      ) : data && data.repository ? (
        <Connected githubConnection={data} />
      ) : data ? (
        <SelectRepositoryForm />
      ) : (
        <NotConnected />
      )}
    </VStack>
  );
}
