import { z } from "zod";

import { PaginationQueryBaseSchema } from "./schemas/dataTableSchema";
import { SatisfactionScore } from "./schemas/satisfactionScore";
import { ColumnState } from "./types/columns";
import { Paginated } from "./types/Paginated";
import { APIResponse } from "./api";
import { AttributeField } from "./attributeFilter";
import { EnvironmentSelectionQuerySchema } from "./environmentAPI";
import {
  FrequencyNumber,
  FunnelStep,
  SortType,
  sortTypeSchema,
} from "./featureAPI";
import { UIFilterSchema } from "./filter";
import { Flag, FlagVersion } from "./flagAPI";
import { UserBaseDTO } from "./userAPI";

export type FeatureMetric = {
  funnelStep: FunnelStep | null;
  eventCount: number;
  firstUsed: string | null;
  lastUsed: string | null;
  frequency: FrequencyNumber;
  satisfaction: SatisfactionScore;
};

export type CompanyName = {
  id: string;
  name: string | null;
  avatarUrl: string | null;
};

export type CompanyListItem = CompanyName & {
  firstSeen: string | null;
  lastSeen: string | null;
  userCount: number;
  eventCount: number;
  feedbackCount: number;
  attributes: Record<string, string>;
  featureMetrics: Record<string, FeatureMetric>;
};

export type AttributeType = "string" | "number" | "boolean" | "date";
export type CompanyList = Paginated<
  CompanyListItem,
  CompaniesSortBy,
  { totalUserCount: number; attributeTypes: Record<string, AttributeType> }
>;

export type CompanyDetail = CompanyListItem; // TODO: support for attribute types

export const CompanyAttributesLiteral = z.custom<`attributes.${string}`>(
  (val) => {
    return (
      typeof val === "string" &&
      val.length <= 255 && // limit as a sanity check to prevent potential abuse
      val.startsWith("attributes.")
    );
  },
);

export const CompanyFeatureMetricLiteral = <K extends string>(key: K) =>
  z.custom<`featureMetrics.${string}.${K}`>((val) => {
    return (
      typeof val === "string" &&
      val.length <= 255 && // limit as a sanity check to prevent potential abuse
      RegExp(`^featureMetrics\\.\\w+\\.${key}$`).test(val)
    );
  });

export const CompaniesSortBySchema = z
  .enum(["name", "id", "firstSeen", "lastSeen", "feedbackCount", "userCount"])
  .or(CompanyAttributesLiteral)
  .or(CompanyFeatureMetricLiteral("funnelStep"))
  .or(CompanyFeatureMetricLiteral("satisfaction"))
  .or(CompanyFeatureMetricLiteral("firstUsed"))
  .or(CompanyFeatureMetricLiteral("lastUsed"))
  .or(CompanyFeatureMetricLiteral("frequency"))
  .or(CompanyFeatureMetricLiteral("eventCount"));

export type CompaniesSortBy = z.infer<typeof CompaniesSortBySchema>;

export const CompaniesColumnSchema = CompaniesSortBySchema; // All columns are sortable
export type CompaniesColumn = z.infer<typeof CompaniesColumnSchema>;

export type CompaniesColumnState = ColumnState<CompaniesColumn>;

export type EnvironmentSelectionQueryType = z.input<
  typeof EnvironmentSelectionQuerySchema
>;

export const CompaniesQuerySchema = z
  .object({
    sortBy: CompaniesSortBySchema.default("name"),
    companyFilter: UIFilterSchema.optional(),
    idNameFilter: z.string().optional(),
    includeDetailsForFeatureIds: z.array(z.string()).optional(),
  })
  .merge(PaginationQueryBaseSchema())
  .strict();

export type CompaniesSearchBody = z.input<typeof CompaniesQuerySchema>;

export const AttributeValuesQuerySchema = z.object({
  filter: z.string().default(""),
  limit: z.preprocess(
    (a) => parseInt(z.string().default("10").parse(a), 10),
    z.number().max(20),
  ),
});

/* Company Features */

export type CompanyFeatureDTO = {
  id: string;
  key: string;
  parentFeatureId: string | null;
  version: string;
  name: string;
  source: "event" | "attribute";
  createdAt: string;
  funnelStep: FunnelStep;
  firstUsed: string | null;
  lastUsed: string | null;
  satisfaction: SatisfactionScore;
  feedbackCount: number;
  frequency: FrequencyNumber;
  usage: { date: string; eventCount: number }[];
  eventCount: number;

  evaluationResult: boolean;
  firstPositiveEvalTime: string | null;
  lastPositiveEvalTime: string | null;
  firstNegativeEvalTime: string | null;
  lastNegativeEvalTime: string | null;
  firstPositiveCheckTime: string | null;
  lastCheckTime: string | null;
  lastCheckResult: boolean | null;

  flag: Flag | null;
};

export const CompanyFeaturesSortByColumns = [
  "name",
  "evaluationResult",
  "lastCheckTime",
  "createdAt",
  "satisfaction",
  "feedbackCount",
  "funnelStep",
  "firstUsed",
  "lastUsed",
  "frequency",
  "eventCount",
] as const;
export const CompanyFeaturesSortBySchema = z.enum(CompanyFeaturesSortByColumns);
export type CompanyFeaturesSortBy = z.infer<typeof CompanyFeaturesSortBySchema>;

export const CompanyFeaturesColumns = [
  ...CompanyFeaturesSortByColumns,
] as const;
export const CompanyFeaturesColumnSchema = z.enum(CompanyFeaturesColumns);
export type CompanyFeaturesColumn = z.infer<typeof CompanyFeaturesColumnSchema>;

export const CompanyFeaturesQuerySchema = z
  .object({
    viewId: z.string().optional(),
    sortOrder: z.enum(["asc", "desc"]).default("desc"),
    sortBy: CompanyFeaturesSortBySchema.default("createdAt"),
    sortType: sortTypeSchema.default("flat"),
  })
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type CompanyFeaturesQuery = z.input<typeof CompanyFeaturesQuerySchema>;

export type CompanyFeaturesList = Paginated<
  CompanyFeatureDTO,
  CompanyFeaturesSortBy,
  { sortType: SortType }
>;

/* Company Feature Users */

export type FeatureCompanyUserDTO = UserBaseDTO & {
  firstUsed: string | null;
  lastUsed: string | null;
};

export const CompanyFeatureUsersSortByColumns = [
  "name",
  "email",
  "eventCount",
  "firstUsed",
  "lastUsed",
] as const;

export const CompanyFeatureUsersSortBySchema = z.enum(
  CompanyFeatureUsersSortByColumns,
);
export type CompanyFeatureUsersSortBy = z.infer<
  typeof CompanyFeatureUsersSortBySchema
>;

export const CompanyFeatureUsersColumns = [
  ...CompanyFeatureUsersSortByColumns,
] as const;
export const CompanyFeatureUsersColumnSchema = z.enum(
  CompanyFeatureUsersColumns,
);
export type CompanyFeatureUsersColumn = z.infer<
  typeof CompanyFeatureUsersColumnSchema
>;

export const CompanyFeatureUsersQuerySchema =
  EnvironmentSelectionQuerySchema.extend({
    sortBy: CompanyFeatureUsersSortBySchema.default("lastUsed"),
  })
    .merge(PaginationQueryBaseSchema({ sortOrder: "desc" }))
    .strict();

export type CompanyFeatureUsersQuery = z.input<
  typeof CompanyFeatureUsersQuerySchema
>;

export type CompanyFeatureUsersList = Paginated<
  FeatureCompanyUserDTO,
  CompanyFeatureUsersSortBy
>;

/* Company Attributes */

export const CompanyAttributeValuesQuerySchema =
  EnvironmentSelectionQuerySchema.merge(AttributeValuesQuerySchema);

export type CompanyAttributeValuesQueryType = z.infer<
  typeof CompanyAttributeValuesQuerySchema
>;

export const BulkCompaniesBodySchema = z
  .object({
    ids: z.array(z.string()).nonempty(),
  })
  .strict();

export type BulkCompaniesBody = z.input<typeof BulkCompaniesBodySchema>;

// @deprecated -- remove after 6th of april
export const CompanyFeatureBodySchema = z
  .object({
    features: z
      .array(
        z
          .object({
            // Allow either featureKey or featureId, but require at least one
            featureKey: z.string().optional(),
            featureId: z.string().optional(),
            isEnabled: z.boolean(),
          })
          .refine(
            (data) =>
              data.featureKey !== undefined || data.featureId !== undefined,
            {
              message: "Either featureKey or featureId must be provided",
            },
          ),
      )
      .nonempty(),
    changeDescription: z.string().optional(),
  })
  .strict();

export type CompanyFeatureBody = z.input<typeof CompanyFeatureBodySchema>;

export interface CompanyAPI {
  "/apps/:appId/companies/search": {
    POST: {
      response: APIResponse<CompanyList>;
      params: { appId: string };
      body: CompaniesSearchBody;
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/company-names": {
    POST: {
      response: APIResponse<CompanyName[]>;
      params: { appId: string };
      body: BulkCompaniesBody;
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/companies/:companyId": {
    GET: {
      response: APIResponse<{
        company: CompanyDetail;
      }>;
      params: { appId: string; companyId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/companies/:companyId/features": {
    GET: {
      response: CompanyFeaturesList;
      params: { appId: string; companyId: string };
      query: CompanyFeaturesQuery;
    };
    // @deprecated -- remove after 6th of april
    PATCH: {
      response: APIResponse<{ flagVersions: FlagVersion[] }>;
      params: { appId: string; companyId: string };
      query: EnvironmentSelectionQueryType;
      body: CompanyFeatureBody;
    };
  };
  "/apps/:appId/companies/:companyId/users/by-feature/:featureId": {
    GET: {
      response: APIResponse<CompanyFeatureUsersList>;
      params: { appId: string; companyId: string; featureId: string };
      query: CompanyFeatureUsersQuery;
    };
  };
  "/apps/:appId/company-attributes": {
    GET: {
      response: APIResponse<{
        attributes: AttributeField[];
      }>;
      params: { appId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/company-attributes/:attribute/values": {
    GET: {
      response: APIResponse<{
        values: string[];
      }>;
      params: { appId: string; attribute: string };
      query: CompanyAttributeValuesQueryType;
    };
  };
}
