import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

const qk = {
  linearConnection: (orgId?: string) => ["linearConnection", orgId] as const,

  slackConnection: (orgId?: string) => ["slackConnection", orgId] as const,
  githubConnection: (orgId?: string) => ["githubConnection", orgId] as const,

  slackChannels: (orgId?: string) =>
    [...qk.slackConnection(orgId), "slackChannels"] as const,

  slackPostNotification: (
    orgId?: string,
    slackChannel?: SlackChannel | null,
    notification?: object,
  ) =>
    [
      ...qk.slackConnection(orgId),
      "slackPostNow",
      slackChannel,
      notification,
    ] as const,

  linearTrackingEntities: (orgId?: string, searchTerm?: string) =>
    [
      ...qk.linearConnection(orgId),
      "linearTrackingEntities",
      searchTerm,
    ] as const,

  githubRepositories: (orgId?: string) =>
    ["githubRepositories", orgId] as const,
};

export { qk as integrationQueryKeys };
