import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HStack, Spacer, Text, Textarea, VStack } from "@chakra-ui/react";

import { CompanyDetail } from "@bucketco/shared/companyAPI";
import { FeatureName } from "@bucketco/shared/featureAPI";
import { UpdateFeatureTargeting } from "@bucketco/shared/featureFlagAPI";
import { Flag } from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import { FormRootError } from "@/common/components/Form/FormRootError";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useUpdateCompanyFeatureAccessMutation } from "@/company/data/useUpdateCompanyFeatureAccessMutation";
import { SlackNotificationStatus } from "@/integration/components/slack/SlackNotificationStatus";

type Props = {
  company: Pick<CompanyDetail, "id" | "name">;
  feature: Pick<FeatureName, "id" | "name">;
  flag: Pick<
    Flag,
    | "id"
    | "stageId"
    | "currentVersions"
    | "slackChannel"
    | "slackNotificationsEnabled"
  >;
  isOpen?: boolean;
  onClose?: () => void;
};

export const CompanyFeatureToggleDialog = ({
  company,
  feature,
  flag,
  isOpen = true,
  onClose,
}: Props) => {
  const { currentEnv } = useAuthContext();
  const currentVersion = flag.currentVersions.find(
    ({ environment }) => environment.id === currentEnv?.id,
  );
  const hasAccess = currentVersion?.companyIds.includes(company.id) || false;

  const placeholder = useCallback(
    (tense: "past" | "future" = "past", lowerCase = false) => {
      let access = !hasAccess
        ? tense === "past"
          ? "Granted"
          : "Grant"
        : tense === "past"
        ? "Revoked"
        : "Revoke";
      access = lowerCase ? access.toLowerCase() : access;
      return `${access} company ${company.name} access to feature ${feature.name} in ${currentEnv?.name}.`;
    },
    [company.name, currentEnv?.name, feature.name, hasAccess],
  );

  const updateFeatureAccessMutation = useUpdateCompanyFeatureAccessMutation(
    company.id,
  );

  const form = useForm({
    defaultValues: {
      changeDescription: "",
    },
  });

  const onConfirmClick = useFormMutationSubmitHandler(
    form,
    updateFeatureAccessMutation,
    () => {
      form.resetField("changeDescription");
      onClose?.();
    },
    {
      prepareVariables({ changeDescription }) {
        return {
          targets: [
            {
              id: feature.id,
              companyId: company.id,
              enabled: !hasAccess,
            },
          ],
          changeDescription: changeDescription || placeholder("past"),
        } satisfies UpdateFeatureTargeting;
      },
      successToast: placeholder("past"),
      errorToast: `Failed to ${placeholder("future", true)}`,
    },
  );

  return (
    <FormProvider {...form}>
      <ConfirmationDialog
        _contentProps={{ maxW: "2xl" }}
        description={
          <VStack as="form" justify="flex-start" spacing={4} w="full">
            <ManagedFormControl
              name="changeDescription"
              render={({ field }) => (
                <Textarea placeholder={placeholder("past")} {...field} />
              )}
            />
            <FormRootError />
          </VStack>
        }
        isLoading={updateFeatureAccessMutation.isPending}
        isOpen={isOpen}
        title={
          <HStack mt={2} spacing={4}>
            <Text whiteSpace="nowrap">
              {!hasAccess ? "Grant" : "Revoke"} feature access
            </Text>
            <Spacer />
            <SlackNotificationStatus
              enabled={flag.slackNotificationsEnabled}
              size="sm"
              slackChannel={flag.slackChannel}
            />
          </HStack>
        }
        onCancel={() => {
          form.resetField("changeDescription");
          onClose?.();
        }}
        onConfirm={onConfirmClick}
      />
    </FormProvider>
  );
};
