import { useMemo } from "react";
import {
  RiArrowDownSLine,
  RiChat1Line,
  RiCloseLine,
  RiDoorClosedLine,
  RiHotelLine,
  RiRemoteControlLine,
  RiSurveyLine,
  RiUserLine,
} from "react-icons/ri";
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  LayoutProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
} from "@chakra-ui/react";

import { useFeature } from "@bucketco/react-sdk";
import { DebugEvent } from "@bucketco/shared/eventAPI";

import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import MenuDescription from "@/common/components/MenuDescription";

export type DebugEventTypeSelectOption = {
  id: DebugEvent["debugEventType"];
  label: string;
  description: string;
  visualization: JSX.Element;
};

const debugEventTypeOptions: DebugEventTypeSelectOption[] = [
  {
    id: "check",
    label: "Check access",
    description: "Client checked if user has access to feature",
    visualization: (
      <Box color="dimmed">
        <RiDoorClosedLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "check-config",
    label: "Check config",
    description: "Client checked the value of the feature remote config",
    visualization: (
      <Box color="dimmed">
        <RiRemoteControlLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "evaluate",
    label: "Evaluate access",
    description: "Feature access evaluated by client or Bucket",
    visualization: (
      <Box color="dimmed">
        <RiSurveyLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "evaluate-config",
    label: "Evaluate config",
    description: "Feature remote config selected by client or Bucket",
    visualization: (
      <Box color="dimmed">
        <RiSurveyLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "user",
    label: "User",
    description: "User identified or updated",
    visualization: (
      <Box color="dimmed">
        <RiUserLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "company",
    label: "Company",
    description: "Company identified or updated",
    visualization: (
      <Box color="dimmed">
        <RiHotelLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "feedback",
    label: "Feedback",
    description: "Feedback received",
    visualization: (
      <Box color="dimmed">
        <RiChat1Line height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "track",
    label: "Track",
    description: "Adoption/usage event tracked",
    visualization: (
      <Box color="dimmed">
        <PointerPressIcon height={13} width={13} />
      </Box>
    ),
  },
];

export function DebugEventTypeDisplay({
  type,
  showDescription = false,
  labelWidth,
}: {
  type: DebugEvent["debugEventType"];
  showDescription?: boolean;
  labelWidth?: LayoutProps["w"];
}) {
  const { label, description, visualization } = debugEventTypeOptions.find(
    (option) => option.id === type,
  )!;

  return (
    <Flex align="center" gap={2}>
      {visualization}
      <Text w={labelWidth} whiteSpace="nowrap">
        {label}
      </Text>
      {showDescription && (
        <Text variant="dimmed" whiteSpace="nowrap">
          {description}
        </Text>
      )}
    </Flex>
  );
}

type DebugEventTypeSelectProps = Omit<BoxProps, "children" | "onChange"> & {
  canClear?: boolean;
  placeholder?: string;
  value?: DebugEvent["debugEventType"][];
  onChange?: (types: DebugEvent["debugEventType"][]) => void;
  menuProps?: Omit<MenuProps, "children">;
};

export function DebugEventTypeSelect({
  value,
  placeholder = "Any event type",
  onChange,
  menuProps,
  ...rest
}: DebugEventTypeSelectProps) {
  const { isEnabled: showConfigEvents } = useFeature("debugger-config-events");

  const actualDebugEventTypeOptions = useMemo(() => {
    return showConfigEvents
      ? debugEventTypeOptions
      : debugEventTypeOptions.filter(
          ({ id }) => id !== "check-config" && id !== "evaluate-config",
        );
  }, [showConfigEvents]);

  return (
    <ButtonGroup {...rest} isAttached>
      <Menu closeOnSelect={false} {...menuProps}>
        <MenuButton
          as={Button}
          color={value ? undefined : "dimmed"}
          rightIcon={
            <Box fontSize="xl" mr={-2}>
              <RiArrowDownSLine />
            </Box>
          }
          variant="input"
        >
          <Flex gap={2}>
            {value && value?.length > 0 ? (
              <>
                {value.map((type) => (
                  <DebugEventTypeDisplay key={type} type={type} />
                ))}
              </>
            ) : (
              <Text color="dimmed">{placeholder}</Text>
            )}
          </Flex>
        </MenuButton>
        {value && value.length > 0 && (
          <IconButton
            aria-label="Clear selection"
            icon={<RiCloseLine />}
            marginInlineStart={"-1px"}
            variant="outline"
            onClick={() => onChange?.([])}
          />
        )}
        <MenuList>
          <MenuDescription>Filter events by type</MenuDescription>
          <MenuDivider my={0} />
          <MenuOptionGroup
            type="checkbox"
            value={value}
            onChange={(newValue) => {
              onChange?.(newValue as DebugEvent["debugEventType"][]);
            }}
          >
            {actualDebugEventTypeOptions.map(({ id }) => (
              <MenuItemOption key={id} value={id}>
                <DebugEventTypeDisplay
                  labelWidth={showConfigEvents ? 24 : 16}
                  type={id}
                  showDescription
                />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </ButtonGroup>
  );
}
