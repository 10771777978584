import { useQuery } from "@tanstack/react-query";

import { LinearEntitiesQuery } from "@bucketco/shared/linearConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import { integrationQueryKeys } from "@/integration/data/integrationQueryKeys";

export function useLinearTrackingEntitySearch(
  searchTerm?: string,
  params: Omit<LinearEntitiesQuery, "searchTerm"> = { maxResults: 10 },
) {
  const { currentOrg } = useAuthContext();

  return useQuery({
    queryKey: integrationQueryKeys.linearTrackingEntities(
      currentOrg?.id,
      searchTerm,
    ),
    queryFn: () =>
      api
        .get<"/orgs/:orgId/linear/connection/search-entities">(
          `/orgs/${currentOrg?.id}/linear/connection/search-entities`,
          {
            params: {
              searchTerm,
              ...params,
            },
          },
        )
        .then((res) => res.data),
    enabled: Boolean(currentOrg?.integrations.linear),
  });
}
